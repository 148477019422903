export class CookieHelper {
  static setCookie(cookieName, cookieValue, expiresDays = 900) {
    const d = new Date();
    d.setTime(d.getTime() + expiresDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cookieName}=${cookieValue};secure;${expires};path=/`;
  }

  static getCookie(cookieName, defaultValue = "") {
    const name = `${cookieName}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let c of ca) {
      while (c.startsWith(" ")) {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return defaultValue;
  }

  static CookieNameEnum = {
    PreferredLanguage: "PreferredLanguage",
    ColorInfoBoxPl: "colorinfoboxpl",
  };
}

export default CookieHelper;
