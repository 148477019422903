import ServiceHelper from "../Helpers/ServiceHelper.js";
import AuthService from "./AuthService.js";

export default class LocationService {
  static GetLocationList(
    orderBy,
    orderDir,
    pageNumber,
    numberOfElementsOnAPage,
    searchItem
  ) {
    const searchParamsString = ServiceHelper.GetSearchParamsString(
      orderBy,
      orderDir,
      pageNumber,
      numberOfElementsOnAPage,
      searchItem
    );

    return AuthService.CallBackendFunction(
      `/api/Location_GetList/${searchParamsString}`
    );
  }

  static GetById(id) {
    return AuthService.CallBackendFunction(`/api/Location_GetById/${id}`);
  }

  static ConfirmLocation(location) {
    const data = {
      LocationId: location.LocationId,
      CxClientId: location.CxClientId,
    };
    return AuthService.CallBackendFunctionPut(`/api/Location_Confirm/`, data);
  }

  static UpdateLocationManagerData(fieldName, value, editedItem) {
    const body = {
      CxClientId: editedItem.CxClientId,
      LocationId: editedItem.LocationId,
      FieldName: fieldName,
      NewValue: value,
    };

    return AuthService.CallBackendFunctionPut(
      "/api/Location_UpdateLocationManagerData/",
      body
    );
  }
}
