import React from "react";
import SelectWithFilter from "./SelectWithFilter";
import FaIcon from "../../FaIcon";

export default class SelectFieldCheckbox extends React.Component {
  static defaultProps = {
    value: null,
    className: "w-100 form-control form-control-sm",
    showSelectFilterAt: 10,
    filterCleared: false,
    selectValues: [],
    IsCheckBoxEnabled: false,
    onOffValue: false,
    editedItem: {},
    changeOfEditedMultipItem: () => {},
    fieldName: "defaultFieldname",
    onChange: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  renderSelectField() {
    return (
      <SelectWithFilter
        value={this.props.value}
        onChange={(e) => {
          this.props.onChange(this.props.fieldName, e.value);
        }}
        className={this.props.className}
        showSelectFilterAt={this.props.showSelectFilterAt}
        selectValues={this.props.selectValues}
        filterCleared={this.props.filterCleared}
      />
    );
  }

  render() {
    if (this.props.IsCheckBoxEnabled === false) return this.renderSelectField();

    if (this.props.editedItem[`${this.props.fieldName}Orgin`] === undefined) {
      this.props.editedItem[`${this.props.fieldName}Orgin`] = this.props.value;
      this.props.editedItem[`${this.props.fieldName}Status`] =
        this.props.onOffValue === "" ? false : this.props.onOffValue;
    }
    const onOffValue = this.props.editedItem[`${this.props.fieldName}Status`];
    return (
      <div className="inputCheckBoxCombo">
        <div className="figmaOnOffButtonBox">
          <div
            className={"figmaOnOffButton"}
            onClick={(e) => {
              const newValue = !onOffValue;
              if (newValue === false) {
                this.props.changeOfEditedMultipItem([
                  { fieldName: `${this.props.fieldName}Status`, newValue },
                  {
                    fieldName: this.props.fieldName,
                    newValue:
                      this.props.editedItem[`${this.props.fieldName}Orgin`],
                  },
                ]);
              } else {
                this.props.onChange(`${this.props.fieldName}Status`, newValue);
              }
            }}
          >
            {onOffValue ? (
              <span className="text-off">Off </span>
            ) : (
              <span className="redOrange">Off </span>
            )}
            {onOffValue ? (
              <FaIcon iconName="fas fa-toggle-on" className=" figmaGreen" />
            ) : (
              <FaIcon iconName="fas fa-toggle-off" className=" redOrange" />
            )}
            {onOffValue ? (
              <span className="figmaGreen"> On</span>
            ) : (
              <span className="text-off"> On</span>
            )}
          </div>
        </div>
        {onOffValue ? this.renderSelectField() : <div className="inputFigma" />}
      </div>
    );
  }
}
