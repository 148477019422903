import React from "react";
import "../css/TabbedPage.css";
import FaIcon from "./FaIcon";
import AuthService from "../Services/AuthService";
import TranslationComponent from "./TranslationComponent";

export default class TabbedPage extends React.Component {
  static defaultProps = {
    tabs: [],
    className: "",
    removeSearchParametersOnTabChange: false,
    openOnTab: 0,
    showTabs: true,
    OnTabChange: null,
  };

  constructor(props) {
    super(props);

    const loggedInManager = AuthService.getInstance().GetLoggedInManager();
    this.state = {
      selectedTab: props.openOnTab,
      loggedInManager,
    };

    this.tabRef = React.createRef();
  }

  componentDidMount() {}

  handleTabChange(newValue) {
    this.setState({ selectedTab: newValue });
    if (this.props.onTabChange) {
      this.props.onTabChange(newValue);
    }
  }

  renderTabLabels() {
    const tabLabels = [];
    this.props.tabs.forEach((tab, index) => {
      if (
        (typeof tab.hide === "function" &&
          tab.hide(this.state.loggedInManager)) ||
        tab.hide === true
      ) {
        return;
      }

      tabLabels.push(
        <div
          key={`tabkey${index}`}
          onClick={e => this.handleTabChange(index)}
          className={
            "grid-item tab-label" +
            (this.state.selectedTab === index ? " active-tab-label" : "")
          }
        >
          {tab.icon && <FaIcon iconName={tab.icon} />}{" "}
          <TranslationComponent phrase={tab.label} loadingEnabled={false} />
        </div>
      );
    });
    return tabLabels;
  }

  scrollFunction(forward) {
    this.tabRef.current.scrollLeft += forward
      ? this.tabRef.current.offsetWidth * 0.6
      : this.tabRef.current.offsetWidth * -0.6;
  }

  renderTabs() {
    return (
      <div className="tabbed-page-header header-shadow" ref={this.tabRef}>
        <div
          className="arrow-button tab-prev"
          onClick={(e) => this.scrollFunction(false)}
        >
          <i className="fas fa-angle-left" />
        </div>
        {this.renderTabLabels()}
        <div
          className="arrow-button tab-next"
          onClick={(e) => this.scrollFunction(true)}
        >
          <i className="fas fa-angle-right" />
        </div>
      </div>
    );
  }

  render() {
    if (
      !this.props.showTabs &&
      this.props.tabs &&
      this.props.tabs.length === 1
    ) {
      return this.props.tabs[this.state.selectedTab].component;
    }

    return (
      <div className={this.props.className}>
        {this.renderTabs()}
        {this.props.tabs[this.state.selectedTab]?.buttonDiv}
        {this.props.tabs[this.state.selectedTab]?.component}
      </div>
    );
  }
}
