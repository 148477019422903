export default class ServiceHelper {
  static GetSearchParamsString(
    orderBy,
    orderDir,
    pageNumber,
    numberOfElementsOnAPage,
    searchItem
  ) {
    const searchParams = {
      Command: "",
      OrderBy: orderBy,
      OrderDir: orderDir,
      PageNumber: pageNumber,
      NumberOfElementsOnAPage: numberOfElementsOnAPage,
      QuickSearch: searchItem.SingleSearchLineText,
      SearchItem: searchItem,
    };

    return JSON.stringify(searchParams);
  }
}
