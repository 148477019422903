import React from "react";
import "../css/GrayContainer.css";
import TranslationComponent from "./TranslationComponent";
import LongText from "./LongText";

export default class GrayContainer extends React.Component {
  static defaultProps = {
    title: "",
    className: "",
    displayedItem: null,
    type: null,
    fieldsToShow: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      displayedItem: this.props.type
        ? new this.props.type(this.props.displayedItem)
        : this.props.displayedItem,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.displayedItem !== this.props.displayedItem) {
      this.setState({
        displayedItem: this.props.type
          ? new this.props.type(this.props.displayedItem)
          : this.props.displayedItem,
      });
    }
  }

  render() {
    const fieldsToShow =
      this.props.type?.fieldsToShow ?? this.props.fieldsToShow;
    return (
      <div className={`gray-container-spacing ${this.props.className}`}>
        {this.state.displayedItem.Title || this.props.title ? (
          <span className="mt-3 gray-container-header">
            {this.state.displayedItem.FirstName}
            <TranslationComponent
              phrase={`${
                this.state.displayedItem.FirstName[-1] === "s" ? "'" : "'s"
              } Data`}
            />
          </span>
        ) : null}
        <div className="gray-container mt-3">
          {this.props.displayedItem
            ? fieldsToShow.flatMap((field, index) => [
                index === 0 ? null : (
                  <div key={`divider${index}`} className="gray-border" />
                ),
                <span key={field.label} className="gray-container-label">
                  <TranslationComponent phrase={field.label} />
                </span>,
                <span key={`${field.label}value`}>
                  {typeof field.data === "function" ? (
                    field.data(this.state.displayedItem)
                  ) : this.state.displayedItem[field.data]?.length > 35 ? (
                    <LongText
                      text={this.state.displayedItem[field.data]}
                      maxLength={35}
                    />
                  ) : (
                    this.state.displayedItem[field.data]
                  )}
                </span>,
              ])
            : null}
        </div>
      </div>
    );
  }
}
