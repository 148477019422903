import React, { Component } from "react";
import selectHelper from "../../Helpers/selectHelper";
import stringHelper from "../../Helpers/stringHelper";
import "../../css/Pagination.css";
import TranslationComponent from "../TranslationComponent";
import TranslateTool from "../../Helpers/TranslateTool";

export default class Pagination extends Component {
  static defaultProps = {
    pageNumber: 1,
    totalNumberOfElements: 1,
    numberOfElementsOnAPage: 20,
    onClick: () => {},
    onChangeOfRangeSelector: null, //if it is not null RangeSelector will be shown. (It has to be a function.)
    rangeMax: 5000,
    isSelectMode: false,
    selectedItemIdsCount: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      goToPageValue: "",
      elementsSelectedText: "ELEMENTS SELECTED",
      pageText: stringHelper.pluralWithoutValue(
        Math.ceil(
          this.props.totalNumberOfElements /
            parseFloat(this.props.numberOfElementsOnAPage)
        ),
        "page"
      ),
      elementsText: stringHelper.pluralWithoutValue(
        this.props.totalNumberOfElements,
        "element"
      ),
    };
  }

  componentDidMount() {
    this.translateTexts();
  }

  translateTexts() {
    TranslateTool.TranslateText(
      this.state.elementsSelectedText,
      (translated) => {
        this.setState({ elementsSelectedText: translated });
      }
    );

    TranslateTool.TranslateText(this.state.pageText, (translated) => {
      this.setState({ pageText: translated });
    });

    TranslateTool.TranslateText(this.state.elementsText, (translated) => {
      this.setState({ elementsText: translated });
    });
  }

  renderLink(text, i, actualPage, key, id) {
    const classname = " ";
    if (i === actualPage) {
      return (
        <div
          className={`${classname} figmabutton secondary smallicononly`}
          key={key + id}
        >
          <h6 className="paginationnumbers">{text}</h6>
        </div>
      );
    }
    return (
      <div
        className={`${classname} figmabuttoncommon common smallicononly`}
        key={key + id}
        onClick={() => {
          this.setState({
            pageNumber: i,
          });
          this.props.onClick(i);
        }}
      >
        <h6 className="paginationnumbers">{text}</h6>
      </div>
    );
  }

  renderLinkLeftArrow(text, i, actualPage, key) {
    const classname = " ";
    return (
      <div
        className={`${classname} figmabuttoncommon common smallicononly`}
        key={key}
        onClick={() => {
          this.setState({
            pageNumber: i,
          });
          this.props.onClick(i);
        }}
      >
        <h6 className="paginationnumbers">
          <i className="fa-solid fa-arrow-left" />
        </h6>
      </div>
    );
  }

  renderLinkRightArrow(text, i, actualPage, key) {
    const classname = " ";
    return (
      <div
        className={`${classname} figmabuttoncommon common smallicononly`}
        key={key}
        onClick={() => {
          this.setState({
            pageNumber: i,
          });
          this.props.onClick(i);
        }}
      >
        <h6 className="paginationnumbers">
          <i className="fa-solid fa-arrow-right" />
        </h6>
      </div>
    );
  }

  renderPageLinks(actualPage, lastPage) {
    const links = [];
    if (actualPage > 1) {
      links.push(
        this.renderLinkLeftArrow(
          "<<",
          actualPage - 1,
          actualPage,
          "previouspage"
        )
      );
    }

    const start = actualPage > 2 ? actualPage - 2 : 1;
    const end = actualPage < lastPage - 2 ? actualPage + 2 : lastPage;

    if (actualPage > 3) {
      links.push(this.renderLink("1", 1, actualPage, "pagef"));
      links.push(
        <div
          key="subaligned2"
          className="subaligned oswaldtext mr-2 ml-2 paginationdots"
        >
          ...
        </div>
      );
    }

    for (let i = start; i <= end; i++) {
      links.push(this.renderLink(i, i, actualPage, `page${i}`));
    }

    if (actualPage < lastPage - 2) {
      links.push(
        <div
          key="subaligned"
          className="subaligned oswaldtext mr-2 ml-2 paginationdots"
        >
          ...
        </div>
      );
      links.push(
        this.renderLink(lastPage, lastPage, actualPage, "pagel", "subaligned")
      );
    }

    if (actualPage !== lastPage) {
      links.push(
        this.renderLinkRightArrow("→", actualPage + 1, actualPage, "nextpage")
      );
    }
    return links;
  }

  getRangeOpts() {
    const fullList = [20, 50, 100, 200, 500, 1000, 5000];
    if (this.props.rangeMax >= 5000) return fullList;
    const filteredList = [];
    fullList.forEach((x) => {
      if (x <= this.props.rangeMax) filteredList.push(x);
    });
    return filteredList;
  }

  renderRangeSelector() {
    if (this.props.onChangeOfRangeSelector === null) return null;

    const rangeOpts = this.getRangeOpts();

    return (
      <span className="larger10 rounded floatleft oswaldtext paginatonrange">
        <TranslationComponent phrase="Range" loadingEnabled={false} />{" "}
        <select
          className="paginationInput text-center paginationselect"
          onChange={e => {
            const newRange = e.target.value;
            this.setState({
              loading: true,
            });
            this.props.onChangeOfRangeSelector(newRange);
          }}
          value={this.props.numberOfElementsOnAPage}
        >
          {selectHelper.generateOptions(
            selectHelper.generateSelectValues(rangeOpts)
          )}
          ; Pages
        </select>
      </span>
    );
  }

  render() {
    if (
      this.props.totalNumberOfElements === 0 ||
      this.props.totalNumberOfElements === undefined ||
      this.props.totalNumberOfElements === null
    ) {
      return null;
    }
    const lastPage = Math.ceil(
      this.props.totalNumberOfElements /
        parseFloat(this.props.numberOfElementsOnAPage)
    );

    return (
      <form
        className="pagination-container"
        onSubmit={() => {
          this.setState({
            pageNumber: this.setState.goToPageValue,
          });
          this.props.onClick(this.state.goToPageValue);
        }}
      >
        <div>{this.renderRangeSelector()}</div>
        <div>
          <span className="larger10 oswaldtextpagination">
            {this.props.isSelectMode
              ? `${this.props.totalNumberOfElements} / ${this.props.selectedItemIdsCount} ${this.state.elementsSelectedText}`
              : `${lastPage} ${this.state.pageText}, ${this.props.totalNumberOfElements} ${this.state.elementsText}.`}
          </span>
        </div>
        <div className="pagination-page-links-container">
          {this.renderPageLinks(this.props.pageNumber, lastPage)}
        </div>
      </form>
    );
  }
}
