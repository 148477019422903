export default class ColorHelper {
  // prettier-ignore
  static SetRootColors(manager) {
    this.SetColor("--body-background-color", !manager.bodyBackgroundColor ? "#fff" : manager.bodyBackgroundColor);
    this.SetColor("--body-color", !manager.bodyForeColor ? "#000" : manager.bodyForeColor);
    this.SetColor("--menu-background-color", !manager.menuBackgroundColor ? "#1e4164" : manager.menuBackgroundColor);
    this.SetColor("--menu-color", !manager.menuForeColor ? "#fff" : manager.menuForeColor);
    this.SetColor("--header-background-color", !manager.headerBackgroundColor ? "#282c34" : manager.headerBackgroundColor);
    this.SetColor("--header-color", !manager.headerForeColor ? "fff" : manager.headerForeColor);
    this.SetColor("--footer-background-color", !manager.footerBackgroundColor ? "#282c34" : manager.footerBackgroundColor);
    this.SetColor("--footer-color", !manager.footerForeColor ? "#fff" : manager.footerForeColor);
  }

  static SetColor(variableName, color) {
    if (CSS.supports("color", color)) {
      document.documentElement.style.setProperty(variableName, color);
    }
  }
}
