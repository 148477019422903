import LocationInvoicingDataDetailItem from "../../Domain/LocationInvoicingDataDetailItem";

export default class UpdateMultipleLocationInvoicingDataItem extends LocationInvoicingDataDetailItem {
  constructor(searchItem, selectedItems, allSelected) {
    super();
    this.SelectedItems = selectedItems;
    this.SearchItem = searchItem;
    this.AllSelected = allSelected;
  }
}
