import React from "react";
import "../css/MainPage.css";
import HomePage from "./HomePage";
import ErrorService from "../Services/ErrorService";
import menuitems from "../menuitems";
import FaIcon from "../Elements/FaIcon";
import urlHelper from "../Helpers/urlHelper";
import AuthService from "../Services/AuthService";
import JsxHelper from "../Helpers/JsxHelper";
import MessageComponent from "../Elements/MessageComponent";
import ColorHelper from "../Helpers/ColorHelper";
import TranslationComponent from "../Elements/TranslationComponent";
import CountryService from "../Services/CountryService";
import OfficeService from "../Services/OfficeService";

export default class MainPage extends React.Component {
  static defaultProps = {};

  constructor(props) {
    super(props);
    const loggedInManager = AuthService.getInstance().GetLoggedInManager();
    this.state = {
      menuId: 0,
      menuTitle: "Home",
      menuHeaderText: null,
      menuLink: <HomePage />,
      menuIcon: "fa fa-house",
      menuUrl: "",
      loggedInManager,
    };

    ColorHelper.SetRootColors(loggedInManager);
    window.onpopstate = this.clickOnBack.bind(this);
  }

  static getDerivedStateFromError(error) {
    ErrorService.LogUIError(error);
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error(error);
    this.setState({
      hasError: true,
      error: error.message,
      info: info.componentStack,
    });
  }

  clickOnBack(name, skipPushState) {
    window.location.reload(true);
  }

  componentDidMount() {
    this.load(window.location.pathname);
    this.preloadData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ hasError: false });
    }
  }

  //Put all preload functions here to load data before the inner page is first rendered
  preloadData() {
    CountryService.GetCountryList();
    OfficeService.GetOfficeList();
  }

  load(path) {
    path = `/${path.split("/")[1].toLocaleLowerCase()}`;
    if (path !== "/") {
      for (let i = 0; i < menuitems.length; i++) {
        const menuitemUrl = `/${menuitems[i].url.toLocaleLowerCase()}`;
        if (menuitemUrl === path) {
          const Comp = React.lazy(() => import(`./${menuitems[i].file}`));

          this.setState({
            menuId: i,
            menuLink: (
              <React.Suspense fallback={<HomePage />}>
                <Comp />
              </React.Suspense>
            ),
            menuTitle: menuitems[i].text,
            menuHeaderText: menuitems[i].headerText,
            menuIcon: menuitems[i].icon,
            menuUrl: menuitems[i].url,
          });
          return;
        }
      }
    }
  }

  renderError() {
    return (
      <div className="App-main" key="kerr1">
        <div className="pageheader">
          <h1>
            <span className="HeaderText">
              <FaIcon iconName="fa fa-bug" /> Something went wrong...
            </span>
          </h1>
        </div>
        <div key="kerr2">
          <div className="Fixedcontentwrapper">
            <div className="m-3 p-3 border rounded bg-danger text-white lead">
              <div>Error descripion:</div>
              <pre className="text-white">{this.state.error}</pre>
              <pre className="text-white">{this.state.info}</pre>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.hasError) return this.renderError();

    const path = window.location.pathname.split("?")[0];
    if (this.state.menuUrl && this.state.menuUrl !== path) {
      window.history.pushState(
        { title: this.state.menuTitle },
        this.state.menuTitle,
        urlHelper.getProperUrl() + this.state.menuUrl
      );
    }

    const environment = (
      <p className="HeaderTextParagraph">
        {MessageComponent.IsStage() ? "(STAGE - Just for testing!)" : ""}
      </p>
    );

    const title = this.state.menuTitle ? (
      <span className="HeaderText">
        <FaIcon iconName={this.state.menuIcon} />
        {this.state.menuIcon && JsxHelper.NonBreakableSpace()}
        {/* {this.state.menuTitle} -{" "} */}
        <TranslationComponent
          phrase={this.state.menuTitle}
          loadingEnabled={false}
        />
        {environment}
      </span>
    ) : null;

    const headerText = this.state.menuHeaderText ? (
      <div className="absolute-headertext">
        {typeof this.state.menuHeaderText === "function"
          ? this.state.menuHeaderText()
          : this.state.menuHeaderText}
      </div>
    ) : null;

    return (
      <div className="App-main">
        {headerText}
        <div className="pageheader">{title}</div>
        {this.state.menuLink}
      </div>
    );
  }
}
