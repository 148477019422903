import React from "react";

export default class LoadingFullScreen extends React.Component {
  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="fullscreenloader">
        <p className="text-center">
          <img src={"/img/loading/loading.gif"} alt="Loading..." height={400} />
        </p>
      </div>
    );
  }
}
