export default class BareManagerSearchParams {
  constructor() {
    this.initialize();
  }

  initialize() {
    this.CxClientId = "";
  }

  ClearAll() {
    this.initialize();
  }

  static fieldsInSearchBox = [];

  static OrderByFields = [];
}
