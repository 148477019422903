import AuthService from "./AuthService";

export default class LanguageService {
  static GetLanguages() {
    return AuthService.CallBackendFunction(
      "/api/Language/GetLanguagesAsync",
      false
    );
  }
}
