import React from "react";
import { MainRefContext } from "../../MainRefContext";
import TranslationComponent from "../TranslationComponent";

export default class NavButton extends React.Component {
  static defaultProps = {
    link: "/",
    text: "Home",
    icon: "fas fa-home",
    url: "",
    active: false,
    id: 0,
    hideNavBar: () => {},
  };

  static contextType = MainRefContext;

  onClick = () => {
    this.props.hideNavBar();
    if (this.context.current) {
      this.context.current.setState({
        menuId: this.props.id,
        menuLink: this.props.link,
        menuTitle: this.props.text,
        menuHeaderText: this.props.headerText,
        menuIcon: this.props.icon,
        menuUrl: this.props.url,
      });
    }
  };

  render() {
    return (
      <div
        key={`navitem${this.props.id}`}
        className={"pt-3 nav-item" + (this.props.active ? " active-nav" : "")}
        onClick={this.onClick}
      >
        <i className={`${this.props.icon} navitem-icon`}></i>
        <div className="navitem-text">
          <TranslationComponent
            phrase={this.props.text}
            loadingEnabled={false}
          />
        </div>
      </div>
    );
  }
}
