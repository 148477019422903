import React from "react";
import "../../css/CardImage.css";

export default class CardImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      catUrl: "",
      loading: true,
    };
  }

  componentDidMount() {}

  render() {
    if (!this.props.item.constructor.ShowImage) return null;
    if (
      typeof this.props.item.constructor.ShowImage_GetInitialsFunction ===
      "function"
    ) {
      return (
        <div className="col-3 card-image-container">
          <div className="card-image ">
            {this.props.item.constructor.ShowImage_GetInitialsFunction(
              this.props.item
            )}
          </div>
        </div>
      );
    }
    return (
      <div className="col-3 m-auto text-center">
        {!this.state.loading && (
          <img
            src={this.state.catUrl}
            style={{
              borderRadius: "50%",
              width: "120px",
              height: "120px",
              border: "2px solid gray",
            }}
            alt="..."
          />
        )}
      </div>
    );
  }
}
