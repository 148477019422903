import React from "react";

export default class FaIcon extends React.Component {
  static defaultProps = {
    className: "icon",
    innerClassName: "",
    iconName: "fas fa-circle",
    onClick: null,
    tooltipText: "",
    disabled: false,
    roleKey: "",
    text: "",
  };

  render() {
    const cn =
      this.props.className === "icon"
        ? this.props.onClick !== null && !this.props.disabled
          ? "icon iconlink"
          : "icon"
        : this.props.className;

    return (
      <span
        className={cn}
        onClick={!this.props.disabled ? this.props.onClick : null}
        disabled={this.props.disabled}
        data-tip={"tooltip"}
        data-placement="top"
        title={this.props.tooltipText}
      >
        <i
          className={`${this.props.iconName} ${this.props.innerClassName}${
            !this.props.disabled ? "" : " disabledicon "
          }`}
          disabled={this.props.disabled}
        >
          {this.props.text && (
            <span className="pl-2 icontext">{this.props.text}</span>
          )}
        </i>
      </span>
    );
  }
}
