import React from "react";
import stringHelper from "../../../Helpers/stringHelper";

export default class TextField extends React.Component {
  static defaultProps = {
    className: "w-100 form-control form-control-sm",
    fieldName: "defaultFieldname",
    onChange: () => {},
    placeholder: "Default Placeholder",
    value: null,
    fieldType: "text",
    readonly: false,
    editedItem: {},
    textBeforeInput: null,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const ph =
      typeof this.props.placeholder === "function"
        ? this.props.placeholder(this.props.editedItem)
        : this.props.placeholder;
    return (
      <div className="w-100">
        {this.props.textBeforeInput ? (
          <span className="fs-6">{this.props.textBeforeInput}</span>
        ) : null}
        <input
          type={this.props.fieldType}
          className={
            this.props.textBeforeInput
              ? "w-50 d-inline-block form-control form-control-sm"
              : this.props.className
          }
          placeholder={ph}
          value={
            this.props.value === null
              ? ""
              : this.props.fieldType === "number"
                ? stringHelper.removeTrailingZeros(this.props.value)
                : this.props.value
          }
          onChange={(e) => {
            let val = e.target.value;
            if (this.props.fieldType === "number") {
              val = parseInt(val);
              if (Number.isNaN(val) || val === "") val = "";
            }
            this.props.onChange(this.props.fieldName, val);
          }}
          id={`htmlidfor${this.props.fieldName}`}
          readOnly={this.props.readonly}
          autoComplete="off"
        />
      </div>
    );
  }
}
