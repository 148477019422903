import React from "react";
import LanguageService from "../../Services/LanguageService";
import MessageComponent from "../MessageComponent";
import cookieHelper from "../../Helpers/cookieHelper";
import TranslationComponent from "../TranslationComponent";

export default class LanguageDropdown extends React.Component {
  static defaultProps = {
    className: "",
  };

  constructor(props) {
    super(props);
    const languageId = JSON.parse(cookieHelper.getCookie("language", 1));
    this.state = {
      languages: [],
      languageId,
    };
  }

  componentDidMount() {
    LanguageService.GetLanguages()
      .then((languages) => {
        this.setState({
          languages,
        });
      })
      .catch((error) => MessageComponent.SetErrorMessage("LD25"));
  }

  onLanguageSelect(languageId) {
    cookieHelper.setCookie("language", languageId);
    this.setState({
      languageId: languageId,
    });
    MessageComponent.SetApprove(
      "For language change to take effect the page must be reloaded, any data not saved will be lost.",
      () => window.location.reload(),
      "Would you like to reload the page now or later?",
      "Later",
      "Reload Now",
      false
    );
  }

  renderDropdownItem(language) {
    return (
      <div
        className="dropdown-item cursor-pointer"
        onClick={() => this.onLanguageSelect(language.languageId)}
        key={`lst_${language.languageCode}`}
      >
        {language.languageCode
          ? language.languageCode.toUpperCase()
          : "LANGUAGE CODE MISSING"}
        {language.languageId === this.state.languageId ? (
          <i className="fa-solid fa-check ps-3"></i>
        ) : null}
      </div>
    );
  }

  getCurrentLanguage() {
    if (
      this.state.languageId &&
      this.state.languages &&
      this.state.languages.length > 0
    ) {
      const filtered = this.state.languages.filter(
        (x) => x.languageId === this.state.languageId
      );
      return filtered.length > 0 ? filtered[0] : null;
    }
    return null;
  }

  render() {
    const language = this.getCurrentLanguage();
    return (
      <div className={"dropdown " + this.props.className}>
        <div className="dropdown-toggle" data-bs-toggle="dropdown">
          {language ? (
            <span className="text-uppercase">{language.languageCode}</span>
          ) : (
            <TranslationComponent phrase="Language" authenticate={false} />
          )}
        </div>
        <div className="dropdown-menu text-uppercase">
          {this.state.languages.map((language) =>
            this.renderDropdownItem(language)
          )}
        </div>
      </div>
    );
  }
}
