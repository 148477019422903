import React from "react";
import "../../css/OuterPage.css";
import AuthService from "../../Services/AuthService";
import OuterPageCard from "../../Elements/OuterPageCard";
import TranslationComponent from "../../Elements/TranslationComponent";

export default class ClientSelectorPage extends React.Component {
  static defaultProps = {
    handleChoosing: () => {
      alert("handleChoosing not implemented");
    },
  };

  constructor(props) {
    super(props);
    const userManagers = AuthService.getInstance().GetUserManagers();
    this.state = {
      userManagers,
    };
  }

  //#region Render Functions
  renderAccountsCard() {
    return (
      <OuterPageCard>
        <p>
          <TranslationComponent
            phrase="Welcome back! Please select the account you want to work with."
          />
        </p>
        <div className="accounts-list">{this.renderAccounts()}</div>
      </OuterPageCard>
    );
  }

  renderAccounts() {
    return this.state.userManagers.map((userManager, index) => {
      return (
        <button
          className="outer-button account-button"
          type="button"
          key={index}
          onClick={() => {
            AuthService.getInstance().SetLoggedInManager(userManager);
            this.props.handleChoosing();
          }}
        >
          {userManager.cxClientDisplayName}
        </button>
      );
    });
  }
  //#endregion

  render() {
    return this.renderAccountsCard();
  }
}
