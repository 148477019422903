import React from "react";
import FaIcon from "./FaIcon";

export default class OuterPageCard extends React.Component {
  static defaultProps = {
    children: null,
    className: "credential-card",
    showBackArrow: false,
  };

  render() {
    return (
      <div className={"text-center position-relative " + this.props.className}>
        {this.props.showBackArrow ? (
          <span onClick={() => window.location.reload()}>
            <FaIcon iconName="fa-solid fa-arrow-left" className="back-arrow" />
          </span>
        ) : null}
        <div className="p-4">
          <h3>
            {process.env.REACT_APP_WEBSITE_NAME ?? "Website name is missing"}
          </h3>
          <h3>{process.env.REACT_APP_VERSION ?? ""}</h3>
          <h3>{process.env.REACT_APP_CURRENT_DATE ?? ""}</h3>

          {this.props.children}
        </div>
      </div>
    );
  }
}
