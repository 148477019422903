import ServiceHelper from "../Helpers/ServiceHelper";
import AuthService from "./AuthService";

export default class PurchaseOrderLineService {
  static GetList(
    orderBy,
    orderDir,
    pageNumber,
    numberOfElementsOnAPage,
    searchItem
  ) {
    const searchParamsString = ServiceHelper.GetSearchParamsString(
      orderBy,
      orderDir,
      pageNumber,
      numberOfElementsOnAPage,
      searchItem
    );

    return AuthService.CallBackendFunction(
      `/api/Service_GetPurchaseOrderLineList/${searchParamsString}`
    );
  }

  static CreatePurchaseOrderLine(editItem) {
    return AuthService.CallBackendFunctionPost(
      "/api/Service_CreatePurchaseOrderLine/",
      editItem
    );
  }

  static RemoveMultiple(editItem) {
    const body = {
      PurchaseOrderLineIds: editItem,
    };

    return AuthService.CallBackendFunctionPost(
      "/api/Service_RemoveMultiplePurchaseOrderLine/",
      body
    );
  }
}
