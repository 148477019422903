import React from "react";
import ButtonColorsEnum from "../../Enums/ButtonColorsEnum";
import ButtonTypeEnum from "../../Enums/ButtonTypeEnum";
import IconPositionEnum from "../../Enums/IconPositionEnum";

class FigmaButton extends React.Component {
  static defaulProps = {
    className: "",
    iconName: "fa fa-circle",
    disabled: false,
    label: "Default buttonlabel",
    type: ButtonTypeEnum.NormalNormal,
    color: ButtonColorsEnum.Primary,
    iconPosition: IconPositionEnum.None,
    tooltip: "",
    submit: false,
    onClick: () => alert("Empty function"),
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const size = this.props.type
      ? this.props.type.toLowerCase()
      : this.props.label
        ? "normalnormal"
        : "normalicononly";

    const className = `figmabutton ${
      this.props.disabled ? "figmabutton_disabled" : ""
    } ${this.props.color} ${size} ${
      this.props.className ? this.props.className : ""
    } `;
    return (
      <button
        className={className}
        onClick={this.props.disabled ? () => {} : this.props.onClick}
        multiline="true"
        type={this.props.submit ? "submit" : "button"}
        data-tip={"tooltip"}
        data-placement="top"
        title={this.props.tooltip}
      >
        {(this.props.iconPosition === IconPositionEnum.Left ||
          !this.props.iconPosition) && (
          <i className={`${this.props.iconName} mr-1`} />
        )}
        {this.props.label}
        {this.props.iconPosition === IconPositionEnum.Right && (
          <i className={`${this.props.iconName} ml-1`} />
        )}
        {this.props.iconPosition === IconPositionEnum.Center && (
          <i className={this.props.iconName} />
        )}
      </button>
    );
  }
}

export default FigmaButton;
