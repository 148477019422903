import React from "react";
import GeneralItemList from "../Elements/CardList/GeneralItemList";
import BareManagerSearchParams from "../Contents/SearchParams/BareManagerSearchParams";
import BareManagerListItem from "../Domain/BareManagerListItem";

export default class BareManagerAdmin extends React.Component {
  static defaultProps = {};

  constructor(props) {
    super(props);
    this.SearchParamsType = BareManagerSearchParams;
    this.state = {
      searchItem: new this.SearchParamsType(),
    };
  }

  componentDidMount() {}

  render() {
    return (
      <GeneralItemList
        itemType={BareManagerListItem}
        detailsItemType={BareManagerListItem}
        searchItem={this.state.searchItem}
        searchParamsType={this.SearchParamsType}
        numberOfElementsOnAPage={9999}
        searchStripVisible={false}
        paginationVisible={false}
      />
    );
  }
}
