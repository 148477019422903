import InputTypesEnum from "../../Enums/InputTypesEnum";
import PurchaseOrderLineData from "../Communication/PurchaseOrderLineData";

export default class PricingSearchParams {
  constructor(item) {
    this.initialize();

    if (item && item instanceof PurchaseOrderLineData) {
      this.LocationId = item.LocationId;
    }
  }

  initialize() {
    this.PricingId = "";
    this.ServiceName = "";
    this.Status = true;
    this.CountryId = "";
    this.LocationId = "";
  }

  ClearAll() {
    this.initialize();
  }

  static fieldsInSearchBox = [
    {
      fieldName: "PricingId",
      label: "Pricing ID",
      className: "col-4",
      type: InputTypesEnum.Number,
    },
    {
      fieldName: "ServiceName",
      label: "Service Name",
      className: "col-4",
      type: InputTypesEnum.Text,
    },
    {
      fieldName: "CountryId",
      label: "Country",
      className: "col-4",
      type: InputTypesEnum.CountryMultiChoice,
    },
    {
      fieldName: "Status",
      label: "Approved?",
      className: "col-4",
      type: InputTypesEnum.EmptyYesNoSelect,
    },
  ];

  static OrderByFields = [
    {
      label: "Pricing Id Asc.",
      data: "PricingId_asc",
    },
    {
      label: "Pricing Id Desc.",
      data: "PricingId_desc",
    },
    {
      label: "Service Name Asc.",
      data: "Service.DisplayServiceName_asc",
    },
    {
      label: "Service Name Desc.",
      data: "Service.DisplayServiceName_desc",
    },
    {
      label: "Country Asc.",
      data: "Country.Name_asc",
    },
    {
      label: "Country Desc.",
      data: "Country.Name_desc",
    },
    {
      label: "Is Active Asc.",
      data: "StatusName_asc",
    },
    {
      label: "Is Active Desc.",
      data: "StatusName_desc",
    },
  ];
}
