import PricingForSelectionItem from "../../Domain/PricingForSelectionItem";
import SelectionWizzardPage from "../../Elements/Wizzards/SelectionWizzardPage";
import moneyHelper from "../../Helpers/moneyHelper";
import PurchaseOrderLineService from "../../Services/PurchaseOrderLineService";
import PricingSearchParams from "../SearchParams/PricingSearchParams";

export default class CreatePurchaseOrderLineWindowDescription {
  static titleForCreate = "Create Purchase Order Line";
  static itemType = PricingForSelectionItem;
  static serviceFunction = PurchaseOrderLineService.CreatePurchaseOrderLine;
  static searchParamsType = PricingSearchParams;
  static wizzardPageType = SelectionWizzardPage;

  static pagesInGeneralEditWizzard = [
    {
      label: "Services",
      selectField: "SelectedItems",
      fields: PricingForSelectionItem.FieldsToShowInList,
    },
    {
      label: "Order Summary",
      selectField: "SelectedItems",
      isSelection: false,
      fields: [
        {
          label: "Service",
          data: "ServiceDisplayServiceName",
          minWidth: 100,
        },
        {
          label: "Fieldwork Periods",
          data: "NumberOfFieldworkPeriods",
          validityChecker: PricingForSelectionItem.validateFieldWorkPeriods,
          minWidth: 50,
        },
        {
          label: "Price per Fieldwork Period",
          data: CreatePurchaseOrderLineWindowDescription.renderClientFee,
          minWidth: 50,
        },
        {
          label: "Total Fee",
          data: CreatePurchaseOrderLineWindowDescription.renderTotalFee,
          minWidth: 50,
        },
      ],
    },
  ];

  static renderClientFee(item) {
    return moneyHelper.toMoneyFormat(
      item.ClientFee,
      item.ClientFeeCurrencyCode
    );
  }

  static renderTotalFee(item) {
    return moneyHelper.toMoneyFormat(
      item.NumberOfFieldworkPeriods * item.ClientFee,
      item.ClientFeeCurrencyCode
    );
  }
}
