import FieldTypesEnum from "../Enums/FieldTypesEnum";
import InputTypesEnum from "../Enums/InputTypesEnum";
import ValidityHelper from "../Helpers/ValidityHelper";
import LocationInvoicingDataService from "../Services/LocationInvoicingDataService";

export default class LocationInvoicingDataDetailItem {
  constructor(item) {
    if (!item) {
      this.LocationInvoicingDataId = 0;
      this.LocationId = 0;
      this.CxClientId = 0;
      this.FirstName = "";
      this.LastName = "";
      this.CompanyName = "";
      this.Street = "";
      this.City = "";
      this.ZipCode = "";
      this.State = "";
      this.CountryName = "";
      this.CountryId = "";
      this.VatNumber = "";
      this.InvoicingEmail = "";
      this.InternalReference = "";
      this.Ponumber = "";
      this.PocontactName = "";
      this.PocontactEmail = "";
      return;
    }

    this.LocationInvoicingDataId = item.locationInvoicingDataId;
    this.CxClientId = item.cxClientId;
    this.LocationId = item.locationId;
    this.LocationInvoicingDataId = item.locationInvoicingDataId;
    this.FirstName = item.firstName;
    this.LastName = item.lastName;
    this.CompanyName = item.companyName;
    this.Street = item.street;
    this.City = item.city;
    this.ZipCode = item.zipCode;
    this.State = item.state;
    this.CountryName = item.countryName;
    this.CountryId = item.countryId;
    this.VatNumber = item.vatNumber;
    this.InvoicingEmail = item.invoicingEmail;
    this.InternalReference = item.internalReference;
    this.Ponumber = item.ponumber;
    this.PocontactName = item.pocontactName;
    this.PocontactEmail = item.pocontactEmail;
  }

  static DetailsServiceFunction = LocationInvoicingDataService.GetById;

  static fieldsToShow = [
    {
      tabName: "Invoicing",
      type: "details",
      updateFunction: LocationInvoicingDataService.Update,
      data: [
        {
          title: "Invoice Details",
          data: [
            { label: "Invoicing Data ID", value: "LocationInvoicingDataId" },
            {
              label: "First Name",
              value: "FirstName",
              type: FieldTypesEnum.DataWithInPlaceEdit,
              validityChecker: ValidityHelper.forRequiredTextField,
            },
            {
              label: "Last Name",
              value: "LastName",
              type: FieldTypesEnum.DataWithInPlaceEdit,
              validityChecker: ValidityHelper.forRequiredTextField,
            },
            {
              label: "Company Name",
              value: "CompanyName",
              type: FieldTypesEnum.DataWithInPlaceEdit,
              validityChecker: ValidityHelper.forRequiredTextField,
            },
            {
              label: "Street",
              value: "Street",
              type: FieldTypesEnum.DataWithInPlaceEdit,
              validityChecker: ValidityHelper.forRequiredTextField,
            },
            {
              label: "City",
              value: "City",
              type: FieldTypesEnum.DataWithInPlaceEdit,
              validityChecker: ValidityHelper.forRequiredTextField,
            },
            {
              label: "Zip",
              value: "ZipCode",
              type: FieldTypesEnum.DataWithInPlaceEdit,
              validityChecker: ValidityHelper.forRequiredTextField,
            },
            {
              label: "State",
              value: "State",
              type: FieldTypesEnum.DataWithInPlaceEdit,
            },
            {
              label: "Country",
              value: "CountryId",
              type: FieldTypesEnum.DataWithInPlaceEdit,
              inputType: InputTypesEnum.CountrySelect,
              validityChecker: ValidityHelper.forRequiredSelectField,
              dataToShow: "CountryName",
            },
            {
              label: "VAT Number",
              value: "VatNumber",
              type: FieldTypesEnum.DataWithInPlaceEdit,
              validityChecker: ValidityHelper.validityCheckerforVatNumber,
            },
            {
              label: "Invoicing Email",
              value: "InvoicingEmail",
              type: FieldTypesEnum.DataWithInPlaceEdit,
              validityChecker: (value, editedItem, manager) =>
                ValidityHelper.forEmailClientRequired(
                  value,
                  manager.isInvoicingEmailMandatory
                ),
            },
            {
              label: "Internal Reference",
              value: "InternalReference",
              type: FieldTypesEnum.DataWithInPlaceEdit,
              validityChecker: (value, editedItem, manager) =>
                ValidityHelper.forTextClientRequired(
                  value,
                  manager.isInvoicingInternalReferenceMandatory
                ),
            },
            {
              label: "PO Number",
              value: "Ponumber",
              type: FieldTypesEnum.DataWithInPlaceEdit,
              validityChecker: (value, editedItem, manager) =>
                ValidityHelper.forTextClientRequired(
                  value,
                  manager.isInvoicingPonumberMandatory
                ),
            },
            {
              label: "PO Contact Name",
              value: "PocontactName",
              type: FieldTypesEnum.DataWithInPlaceEdit,
              validityChecker: (value, editedItem, manager) =>
                ValidityHelper.forTextClientRequired(
                  value,
                  manager.isInvoicingPocontactNameMandatory
                ),
            },
            {
              label: "PO Contact Email",
              value: "PocontactEmail",
              type: FieldTypesEnum.DataWithInPlaceEdit,
              validityChecker: (value, editedItem, manager) =>
                ValidityHelper.forEmailClientRequired(
                  value,
                  manager.isInvoicingPocontactEmailMandatory
                ),
            },
          ],
        },
      ],
    },
  ];
}
