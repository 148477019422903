import React from "react";
import CountryService from "../../../Services/CountryService";
import MessageComponent from "../../MessageComponent";
import MultiChoice from "./MultiChoice";
import LoadingIcon from "../../Loading/LoadingIcon";

export default class CountryMultiChoice extends React.Component {
  static defaultProps = {
    onChange: () => alert("CountryMultiChoice Onchange"),
    fieldName: "",
    selected: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      itemList: [],
    };
  }

  componentDidMount() {
    this.populateListData();
  }

  populateListData() {
    CountryService.GetCountryList()
      .then((result) => {
        const optValues = [];
        result.forEach((country) => {
          if (!country.name.includes("DO NOT USE")) {
            optValues.push({
              label: country.name,
              value: country.countryId,
            });
          }
        });
        this.setState({ itemList: optValues, loading: false });
      })
      .catch(() => {
        this.setState({ itemList: [], loading: false });
        MessageComponent.SetErrorMessage("CMC42");
      });
  }

  renderList() {
    return (
      <MultiChoice
        label=""
        p-multiselect-panel={true}
        className="rmsc"
        options={this.state.itemList}
        selected={this.props.selected}
        onChange={(selected) =>
          this.props.onChange(this.props.fieldName, selected)
        }
        labelledBy="Select"
      />
    );
  }

  render() {
    return (
      <div className="w-100 text-center hover rounded arial">
        {this.state.loading ? (
          <LoadingIcon loading={true} className="h6" />
        ) : (
          this.renderList()
        )}
      </div>
    );
  }
}
