import React from "react";
import InputWithClear from "../Input/InputWithClear";
import JsxHelper from "../../Helpers/JsxHelper";
import GeneralSearchPanel from "./GeneralSearchPanelFigma";
import debounce from "lodash.debounce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ShownPanelsEnum from "../../Enums/ShownPanelsEnum";
import InputTypesEnum from "../../Enums/InputTypesEnum";
import "../../css/SearchPanel.css";
import "../../css/OverWindow.css";
import TranslationComponent from "../TranslationComponent";
import TranslateTool from "../../Helpers/TranslateTool";

class GeneralSearchStrip extends React.Component {
  static defaultProps = {
    searchItem: null,
    clickOnOk: (searchItem) => alert("Please, create an Ok function!"),
    clickOnRefresh: () => alert("Please, create a Refresh function!"),
    clickOnCancel: (searchItem) => alert("Please, create a Cancel function!"),
    clickOnSelectMode: () => alert("Please, create a Select Mode function!"),
    clickOnSelectAll: () => alert("Please, create a Select All function!"),
    clickOnOrderOption: () => alert("Please, create an Order Option function!"),
    searchParamsType: null, //this have to be a type of searchParams, for example FreelancerPaymentExportSearchParams
    hideAdvancedButton: false,
    exportFunction: null,
    isRefreshDisabled: true,
    isFilterDisabled: true,
    enableUrlPush: true,
    singleSearchLine: false,
    singleSearchLinePlaceholder: "Search...",
    isSelectButtonActive: false,
    selectAllLevel: 0,
    isSelectDisabled: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      searchItem: { ...props.searchItem },
      showFilter: false,
      selectedTab: 0,
      originalUrl: window.location.href,
      singleSearchLineText: props.searchItem?.SingleSearchLineText
        ? props.searchItem.SingleSearchLineText
        : "",
      singleSearchLinePlaceholder: props.singleSearchLine
        ? props.singleSearchLinePlaceholder
        : "Search...",
      currentText: "Current",
      clickToText: "Click to",
      selectOneOrMore: "Select one or more elements",
      selectAllDisplayed: "Select all displayed elements",
      selectSearchSet: "Select all from current search set",
    };
    this.singleSearchLineOnChange = this.singleSearchLineOnChange.bind(this);
    this.debounceSingleSearchLineOnChange =
      this.debounceSingleSearchLineOnChange.bind(this);
    this.renderSelectAllButton = this.renderSelectAllButton.bind(this);
  }

  componentDidMount() {
    this.translateTexts();
  }

  translateTexts() {
    const placeholder = this.props.singleSearchLine
      ? this.props.singleSearchLinePlaceholder
      : "Search...";
    TranslateTool.TranslateText(placeholder, (translated) => {
      this.setState({ singleSearchLinePlaceholder: translated });
    });

    TranslateTool.TranslateText(this.state.currentText, (translated) => {
      this.setState({ currentText: translated });
    });
    TranslateTool.TranslateText(this.state.clickToText, (translated) => {
      this.setState({ clickToText: translated });
    });
    TranslateTool.TranslateText(this.state.selectOneOrMore, (translated) => {
      this.setState({ selectOneOrMore: translated });
    });
    TranslateTool.TranslateText(this.state.selectAllDisplayed, (translated) => {
      this.setState({ selectAllDisplayed: translated });
    });
    TranslateTool.TranslateText(this.state.selectSearchSet, (translated) => {
      this.setState({ selectSearchSet: translated });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({ searchItem: { ...this.props.searchItem } });
    }
  }

  changeOfSearchItem(fieldName, newValue) {
    let newSearchItem = {
      ...this.state.searchItem,
      [fieldName]: newValue,
    };

    if (
      this.props.searchParamsType.valuesToEraseOnChange?.values.some(
        (s) => s.triggerField === fieldName
      )
    ) {
      const changeItem =
        this.props.searchParamsType.valuesToEraseOnChange.values.find(
          (s) => s.triggerField === fieldName
        );
      newSearchItem = {
        ...this.state.searchItem,
        [fieldName]: newValue,
        [changeItem.toErase]: changeItem.value,
      };
    }

    this.setState({ searchItem: newSearchItem });
  }

  renderInputField(f) {
    let type = f.type;
    let labelText = f.label;
    if (f.showCondition && !f.showCondition(this.state.searchItem)) {
      type = InputTypesEnum.Placeholder;
      labelText = "";
    }

    return (
      <div className={f.className} key={`keydiv${f.fieldName}`}>
        <InputWithClear
          fieldName={f.fieldName}
          labelText={labelText}
          type={type}
          value={this.state.searchItem[f.fieldName]}
          selectValues={f.options}
          onChange={(fieldName, newValue) => {
            this.changeOfSearchItem(fieldName, newValue);
          }}
          key={`key${f.fieldName}`}
          roleKey={f.roleKey}
        />
      </div>
    );
  }

  renderSearchPanel() {
    if (this.state.showFilter) {
      return (
        <GeneralSearchPanel
          searchItem={this.state.searchItem}
          searchParamsType={this.props.searchParamsType}
          clickOnCancel={() => {
            this.setState({ showFilter: false });
            this.props.clickOnCancel();
          }}
          clickOnOk={(key, searchItem) => {
            this.setState({ showFilter: false });
            this.props.clickOnOk(key, searchItem);
          }}
          hideFilter={() => {
            this.setState({ showFilter: false });
          }}
          hideAdvancedButton={this.props.hideAdvancedButton}
          originalUrl={this.state.originalUrl}
          enableUrlPush={this.props.enableUrlPush}
        />
      );
    }
    return null;
  }

  debounceSingleSearchLineOnChange() {
    const searchItem = {
      ...this.state.searchItem,
      SingleSearchLineText: this.state.singleSearchLineText,
    };
    this.props.clickOnOk(ShownPanelsEnum.Search, searchItem);
  }

  debouncedSingleSearchLineOnChange = debounce(
    this.debounceSingleSearchLineOnChange,
    1500
  );

  singleSearchLineOnChange(e) {
    this.setState({ singleSearchLineText: e.target.value });
    this.debouncedSingleSearchLineOnChange();
  }

  renderSingleSearchLine() {
    const style = this.state.selectAllDisplayed
      ? { width: "calc(100% - 480px)" }
      : { width: "calc(100% - 200px)" };
    return this.props.singleSearchLine ? (
      <>
        <FontAwesomeIcon
          icon={faSearch}
          onClick={() => this.setState({ showFilter: !this.state.showFilter })}
        />
        <input
          className="oswaldtext searchinput lightborder "
          style={style}
          placeholder={this.state.singleSearchLinePlaceholder}
          value={this.state.singleSearchLineText}
          onChange={this.singleSearchLineOnChange}
          onFocus={e => e.target.select()}
        />
      </>
    ) : (
      <input
        className="oswaldtext searchinputdisabled lightborder "
        disabled={true}
      />
    );
  }

  renderFilterButton() {
    return (
      <button
        className="oswaldtext searchbutton lightborder"
        disabled={this.props.isFilterDisabled}
        onClick={() => this.setState({ showFilter: !this.state.showFilter })}
      >
        <i className="fa-solid fa-sliders" />
        {JsxHelper.NonBreakableSpace()}
        <TranslationComponent phrase="Filters" />
      </button>
    );
  }

  renderSelectAllButton() {
    return (
      <button
        className={`oswaldtext searchbutton lightborder ${
          this.props.selectAllLevel === 2 && "bg-gray text-white"
        }
        ${this.props.selectAllLevel === 1 && "bg-light"}`}
        disabled={this.props.isSelectModeDisabled}
        onClick={() => {
          const newSelectAllLevel =
            this.props.selectAllLevel === 2 ? 0 : this.props.selectAllLevel + 1;
          this.props.clickOnSelectAll(newSelectAllLevel);
        }}
      >
        {this.props.selectAllLevel === 0 ? (
          <div
            title={`${this.state.currentText}: ${this.state.selectOneOrMore}\n${this.state.clickToText}: ${this.state.selectAllDisplayed}`}
          >
            <i className="fa-regular fa-check fa-xl" />
          </div>
        ) : this.props.selectAllLevel === 1 ? (
          <div
            title={`${this.state.currentText}: ${this.state.selectAllDisplayed}\n${this.state.clickToText}: ${this.state.selectSearchSet}`}
          >
            <i className="fa-regular fa-square-check fa-xl" />
          </div>
        ) : (
          <div
            title={`${this.state.currentText}: ${this.state.selectSearchSet}\n${this.state.clickToText}: ${this.state.selectOneOrMore}`}
          >
            <i className="fa-solid fa-square-check fa-xl" />
          </div>
        )}
      </button>
    );
  }

  renderSelectModeButton() {
    return (
      <button
        className={`oswaldtext searchbutton lightborder ${
          this.state.isSelectButtonActive && "bg-gray text-white"
        }`}
        disabled={this.props.isSelectModeDisabled}
        onClick={() => {
          this.props.clickOnSelectMode();
          this.setState({
            isSelectButtonActive: !this.state.isSelectButtonActive,
          });
          this.props.clickOnSelectAll(0);
        }}
      >
        <i
          className={`fa-regular ${
            this.state.isSelectButtonActive ? "fa-square-check" : "fa-square"
          }`}
        />
        {JsxHelper.NonBreakableSpace()}
        <TranslationComponent phrase="Select" />
      </button>
    );
  }

  renderOrderDropdown() {
    return (
      <select
        value={this.state.selectedOrderBy}
        onChange={e => {
          this.props.clickOnOrderOption(e.target.value);
          this.setState({ selectedOrderBy: e.target.value });
        }}
        className={`oswaldtext lightborder searchbutton`}
        defaultValue=""
      >
        <option value="" disabled hidden>
          <TranslationComponent phrase="Order by" loadingEnabled={false} />
        </option>
        {this.props.searchParamsType.OrderByFields.map(f => (
          <option key={`key${f.data}`} value={f.data}>
            <TranslationComponent phrase={f.label} loadingEnabled={false} />
          </option>
        ))}
      </select>
    );
  }

  render() {
    return (
      <div className="stripback ">
        {this.renderSingleSearchLine()}
        {this.renderOrderDropdown()}
        {this.props.isSelectDisabled ? null : this.renderSelectModeButton()}
        {this.state.isSelectButtonActive && this.renderSelectAllButton()}
        {this.renderFilterButton()}
        {this.renderSearchPanel()}
      </div>
    );
  }
}

export default GeneralSearchStrip;
