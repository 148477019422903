import React from "react";
import InputWithClear from "../Input/InputWithClear";
import ShownPanelsEnum from "../../Enums/ShownPanelsEnum";
import InputTypesEnum from "../../Enums/InputTypesEnum";
import urlHelper from "../../Helpers/urlHelper";
import FaIcon from "../FaIcon";
import LanguageHelper from "../../Helpers/LanguageHelper";
import OverWindow from "../OverWindow";
import FigmaButton from "../Button/FigmaButton";
import ButtonTypeEnum from "../../Enums/ButtonTypeEnum";
import ButtonColorsEnum from "../../Enums/ButtonColorsEnum";
import IconPositionEnum from "../../Enums/IconPositionEnum";
import { Tab, Tabs } from "@mui/material";
import TranslationComponent from "../TranslationComponent";

class GeneralSearchPanel extends React.Component {
  static defaultProps = {
    searchItem: null,
    clickOnOk: (searchItem) => alert("Please, create an Ok function!"),
    clickOnCancel: (searchItem) => alert("Please, create a Cancel function!"),
    hideFilter: (searchItem) => alert("Please, create a Hide function!"),
    searchParamsType: null, //this has to be a type of searcParams, for example LocationSearchParams
    enableUrlPush: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      searchItem: { ...props.searchItem },
      filterCleared: false,
      selectedTab: 0,
    };

    this.validityCheckerForTheEntireForm =
      this.validityCheckerForTheEntireForm.bind(this);
    this.renderButtons = this.renderButtons.bind(this);
  }

  componentDidMount() {}

  async changeOfEditedMultipItem(inputData) {
    const tempSearchItem = { ...this.state.searchItem };
    inputData.map((c) => (tempSearchItem[c.fieldName] = c.newValue));
    this.setState({ searchItem: tempSearchItem });
  }

  changeOfSearchItem(fieldName, newValue) {
    let newSearchItem = {
      ...this.state.searchItem,
      [fieldName]: newValue,
    };

    if (
      this.props.searchParamsType.valuesToEraseOnChange?.values.some(
        (s) => s.triggerField === fieldName
      )
    ) {
      const changeItem =
        this.props.searchParamsType.valuesToEraseOnChange.values.find(
          (s) => s.triggerField === fieldName
        );
      newSearchItem = {
        ...this.state.searchItem,
        [fieldName]: newValue,
        [changeItem.toErase]: changeItem.value,
      };
    }

    this.setState({ searchItem: newSearchItem });
  }

  renderInputField(f) {
    let type = f.type;
    let labelText = f.label;
    if (
      f.hide &&
      (f.hide === true ||
        (typeof f.hide === "function" && f.hide(this.state.searchItem)))
    ) {
      return null;
    }
    if (f.showCondition && !f.showCondition(this.state.searchItem)) {
      type = InputTypesEnum.Placeholder;
      labelText = "";
    }

    return (
      <div className={f.className} key={`keydiv${f.fieldName}`}>
        <InputWithClear
          fieldName={f.fieldName}
          labelText={<TranslationComponent phrase={labelText} />}
          type={type}
          value={this.state.searchItem[f.fieldName]}
          selectValues={f.options}
          onChange={(fieldName, newValue) => {
            this.changeOfSearchItem(fieldName, newValue);
            this.setState({ filterCleared: false });
          }}
          key={`key${f.fieldName}`}
          roleKey={f.roleKey}
          tooltipText={f.tooltipText}
          disabled={f.disabled}
          editedItem={this.state.searchItem}
          idFilter={f.idFilter && f.idFilter(this.state.searchItem)}
          filterCleared={this.state.filterCleared}
          IsCheckBoxEnabled={f.IsCheckBoxEnabled}
          changeOfEditedMultipItem={(data) =>
            this.changeOfEditedMultipItem(data)
          }
          validityChecker={f.validityChecker}
        />
      </div>
    );
  }

  validityCheckerForTheEntireForm() {
    for (const field of this.props.searchParamsType.fieldsInSearchBox) {
      const validation = field.validityChecker?.(
        this.state.searchItem[field.fieldName],
        this.state.searchItem
      );
      if (validation !== "" && validation !== undefined) return false;
    }

    return true;
  }

  renderButtons() {
    return (
      <div className="EditBoxFooter">
        <div className="EditBoxFooterRight">
          <FigmaButton
            label={<TranslationComponent phrase="Clear All" />}
            type={ButtonTypeEnum.NormalIconLeft}
            color={ButtonColorsEnum.Common}
            iconPosition={IconPositionEnum.Left}
            icon="fas fa-eraser"
            onClick={(e) => {
              e.preventDefault();
              urlHelper.removeSearchParamsFromUrl();
              const newSearchItem = new this.props.searchParamsType();
              this.setState({ searchItem: newSearchItem, filterCleared: true });
            }}
          />
          <FigmaButton
            label={
              this.props.searchParamsType.startSearchButtonLabel ? (
                <TranslationComponent
                  phrase={this.props.searchParamsType.startSearchButtonLabel}
                />
              ) : (
                <TranslationComponent phrase="Search" />
              )
            }
            type={ButtonTypeEnum.NormalIconLeft}
            color={ButtonColorsEnum.Primary}
            iconPosition={IconPositionEnum.Left}
            icon={
              this.props.searchParamsType.startSearchButtonIconName
                ? this.props.searchParamsType.startSearchButtonIconName
                : "fas fa-search"
            }
            submit={this.validityCheckerForTheEntireForm()}
            disabled={!this.validityCheckerForTheEntireForm()}
          />
        </div>
      </div>
    );
  }

  renderTabLabels() {
    const tabLabels = [];
    this.props.searchParamsType.tabsOnTheSearchPanel.forEach((tab, index) => {
      tabLabels.push(
        <Tab
          value={index}
          label={
            <>
              {tab.icon && <FaIcon iconName={tab.icon} />}{" "}
              {LanguageHelper.translate(tab.label)}
            </>
          }
          key={`tabkey${index}`}
        />
      );
    });
    return tabLabels;
  }

  handleTabChange(event, newValue) {
    this.setState({ selectedTab: newValue });
  }

  renderTabs() {
    if (!this.props.searchParamsType.tabsOnTheSearchPanel) return null;
    return (
      <Tabs
        value={this.state.selectedTab}
        onChange={this.handleTabChange.bind(this)}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        {this.renderTabLabels()}
      </Tabs>
    );
  }

  render() {
    return (
      <div>
        <OverWindow
          title={<TranslationComponent phrase="Filters" />}
          clickOnCancel={this.props.clickOnCancel}
          iconName={
            this.props.searchParamsType.iconName
              ? this.props.searchParamsType.iconName
              : "fas fa-search"
          }
          itemToSave={this.state.searchItem}
          saveType={this.props.searchParamsType.saveType}
          onSavedItemChanged={(item) => {
            this.setState({ searchItem: item });
            this.props.clickOnOk(ShownPanelsEnum.Search, item);
            this.props.hideFilter();
          }}
          saveWindowText="Save Search Parameters"
          openWindowText="Open Search Parameters"
          maxWidth={
            this.props.searchParamsType.maxWidth
              ? this.props.searchParamsType.maxWidth
              : 0
          }
          verticalScroll={false}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const tempSearchItem = { ...this.state.searchItem };
              tempSearchItem.SingleSearchLineText = "";
              if (this.props.enableUrlPush) {
                urlHelper.addSearchParamsToUrl(
                  tempSearchItem,
                  new this.props.searchParamsType(true)
                );
              }
              this.props.clickOnOk(ShownPanelsEnum.Search, tempSearchItem);
              this.props.hideFilter();
            }}
          >
            {this.renderTabs()}
            <div className="container-fluid verticalScrollForSearch">
              <div className="row ">
                {this.props.searchParamsType.fieldsInSearchBox.map(
                  (f, index) => {
                    if (this.props.searchParamsType.tabsOnTheSearchPanel) {
                      return !f.tab ||
                        f.tab ===
                          this.props.searchParamsType.tabsOnTheSearchPanel[
                            this.state.selectedTab
                          ].id
                        ? this.renderInputField(f)
                        : null;
                    } else {
                      return this.renderInputField(f);
                    }
                  }
                )}
              </div>
            </div>
            {this.renderButtons()}
          </form>
        </OverWindow>
      </div>
    );
  }
}

export default GeneralSearchPanel;
