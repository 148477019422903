import React from "react";
import SelectFieldCheckbox from "./SelectFIeldCheckbox";
import TranslateTool from "../../../Helpers/TranslateTool";

export default class EmptyYesNoSelect extends React.Component {
  static defaultProps = {
    value: null,
    className: "w-100 form-control form-control-sm",
    fieldName: "defaultFieldname",
    onChange: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      yesLabel: "Yes",
      noLabel: "No",
      selectValues: [],
    };
  }

  componentDidMount() {
    this.translateLabels(() => this.populateListData());
  }

  translateLabels(callback) {
    TranslateTool.TranslateText(this.state.yesLabel, (translated) => {
      this.setState({ yesLabel: translated }, () => {
        TranslateTool.TranslateText(this.state.noLabel, (translated) => {
          this.setState({ noLabel: translated }, callback);
        });
      });
    });
  }

  populateListData() {
    this.setState({
      selectValues: [
        { label: "", value: "", disabled: false },
        { label: this.state.yesLabel, value: true, disabled: false },
        { label: this.state.noLabel, value: false, disabled: false },
      ],
    });
  }

  render() {
    return (
      <SelectFieldCheckbox
        value={this.props.value}
        className={this.props.className}
        selectValues={this.state.selectValues}
        fieldName={this.props.fieldName}
        onChange={this.props.onChange}
      />
    );
  }
}
