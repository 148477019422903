import React from "react";
import "../../css/OuterPage.css";
import "../../css/MfaPage.css";
import AuthService from "../../Services/AuthService";
import LoadingFullScreen from "../../Elements/Loading/LoadingFullScreen";
import OuterPageCard from "../../Elements/OuterPageCard";
import TranslationComponent from "../../Elements/TranslationComponent";

export default class MfaPage extends React.Component {
  static defaultProps = {
    handleEnteringPin: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      pin: "",
      rememberMe: true,
      loading: false,
    };
  }

  componentDidMount() {}

  async handleCheckPin() {
    try {
      await AuthService.getInstance().CheckPin(
        this.state.pin,
        this.state.rememberMe
      );
      this.props.handleEnteringPin();
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    if (this.state.loading) return <LoadingFullScreen />;
    return (
      <OuterPageCard showBackArrow={true}>
        <span>
          <TranslationComponent
            phrase="Don’t close this window! We have sent you an email containing a PIN code. Please enter the code below."
            authenticate={false}
          />
        </span>
        <div className="input-container">
          <input
            placeholder="PIN"
            value={this.state.pin}
            type="text"
            onChange={(e) => {
              this.setState({ pin: e.target.value });
            }}
          />
        </div>
        <div className="">
          <input
            className="rememberMeCheckBox"
            checked={this.state.rememberMe}
            type="checkbox"
            onChange={(e) => {
              this.setState({ rememberMe: e.target.checked });
            }}
          />
          <TranslationComponent
            phrase="Remember me on this device for 30 days"
            authenticate={false}
          />
        </div>

        <button
          className="outer-button"
          onClick={() => {
            this.setState({ loading: true });
            this.handleCheckPin();
          }}
          disabled={this.state.pin.length < 6}
        >
          <TranslationComponent phrase="Submit" authenticate={false} />
        </button>
      </OuterPageCard>
    );
  }
}
