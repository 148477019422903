import React from "react";
import GeneralItemDetails from "../Elements/CardList/GeneralItemDetails";
import LocationInvoicingDataDetailItem from "../Domain/LocationInvoicingDataDetailItem";
import AuthService from "../Services/AuthService";
import LocationInvoicingDataSearchParams from "../Contents/SearchParams/LocationInvoicingDataSearchParams";

export default class InvoicingDataPage extends React.Component {
  static defaultProps = {
    detailsItem: null,
  };

  constructor(props) {
    super(props);

    const user = AuthService.getInstance().GetLoggedInManager();
    this.state = {
      loggedInManager: user,
      searchParams: new LocationInvoicingDataSearchParams(
        this.props.detailsItem?.LocationId,
        user.cxClientId
      ),
    };
  }

  render() {
    return (
      <GeneralItemDetails
        itemType={LocationInvoicingDataDetailItem}
        selectedId={this.state.searchParams}
        isOverWindow={false}
        showTabs={false}
      />
    );
  }
}
