import React from "react";
import FigmaButton from "./FigmaButton";
import ButtonTypeEnum from "../../Enums/ButtonTypeEnum";
import ButtonColorsEnum from "../../Enums/ButtonColorsEnum";
import IconPositionEnum from "../../Enums/IconPositionEnum";

FigmaButtonRow.defaultProps = {
  className: "",
  isOverWindow: false,
  buttons: [
    {
      className: "",
      iconName: "fa fa-circle",
      disabled: false,
      label: "Default buttonlabel",
      type: ButtonTypeEnum.NormalNormal,
      color: ButtonColorsEnum.Primary,
      iconPosition: IconPositionEnum.None,
      tooltip: "",
      roleKey: "",
      submit: false,
      onClick: () => alert("Empty function"),
      hide: false,

      href: "/",
      target: "_blank",
      rel: "noopener noreferrer",
    },
  ],
};

export default function FigmaButtonRow({ buttons, className, isOverWindow }) {
  const renderButton = (button, index) => {
    if (!button || button.hide) return null;

    if (button.href) {
      return (
        <a
          href={button.href}
          target={button.target}
          rel={button.rel}
          style={{ textDecoration: "none" }}
          key={`fbakey${index}`}
        >
          <FigmaButton
            className={button.className}
            iconName={button.iconName}
            disabled={button.disabled}
            label={button.label}
            type={button.type}
            color={button.color}
            iconPosition={button.iconPosition}
            tooltip={button.tooltip}
            roleKey={button.roleKey}
            submit={button.submit}
            onClick={button.onClick}
            key={`fbkey${index}`}
          />
        </a>
      );
    }

    return (
      <FigmaButton
        className={button.className}
        iconName={button.iconName}
        disabled={button.disabled}
        label={button.label}
        type={button.type}
        color={button.color}
        iconPosition={button.iconPosition}
        tooltip={button.tooltip}
        roleKey={button.roleKey}
        submit={button.submit}
        onClick={button.onClick}
        key={`fbkey${index}`}
      />
    );
  };

  return (
    <div
      className={
        `${isOverWindow ? "OverWindowFigmaButtonRow" : "FigmaButtonRow"} ` +
        className
      }
    >
      <div className="m-1 btn-group">
        {buttons.map((button, index) => renderButton(button, index))}
      </div>
    </div>
  );
}
