export default class moneyHelper {
  static toMoneyFormat(moneysum, currencycode) {
    try {
      if (!currencycode || typeof currencycode !== "string") {
        return `${this.valueWithTwoDecimalPlaces(parseFloat(moneysum))}`;
      }
    } catch (err) {
      return `${moneysum} (err)`;
    }
    try {
      return moneyHelper.toUsMoneyFormat(moneysum ?? 0, currencycode);
    } catch (err) {
      return `${moneysum} ${currencycode}`;
    }
  }

  static toUsMoneyFormat(moneysum, currencycode) {
    if (
      currencycode === "" ||
      currencycode === undefined ||
      currencycode === null
    ) {
      return `${moneysum} err-tumf`;
    }
    try {
      return `${currencycode} ${parseFloat(moneysum)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    } catch (err) {
      return ` ${currencycode} ${moneysum}`;
    }
  }

  static valueWithTwoDecimalPlaces(value) {
    if (!value) return "0.00";
    return value.toFixed(2);
  }
}
