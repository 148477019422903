import React from "react";
import MessageComponent from "../../MessageComponent";
import SelectFieldCheckbox from "./SelectFIeldCheckbox";
import CountryService from "../../../Services/CountryService";

export default class CountrySelect extends React.Component {
  static defaultProps = {
    value: null,
    className: "w-100 form-control form-control-sm",
    filterCleared: false,
    fieldName: "defaultFieldname",
    onChange: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      selectValues: [],
    };
  }

  componentDidMount() {
    this.populateListData();
  }

  populateListData() {
    CountryService.GetCountryList()
      .then((result) => {
        const optValues = [
          { label: "Choose one...", value: "", disabled: false },
        ];
        result.forEach((country) => {
          if (!country.name.includes("DO NOT USE")) {
            optValues.push({
              label: country.name,
              value: country.countryId,
              disabled: false,
            });
          }
        });
        this.setState({ selectValues: optValues });
      })
      .catch(() => {
        this.setState({ selectValues: [] });
        MessageComponent.SetErrorMessage("COS45");
      });
  }

  render() {
    return (
      <SelectFieldCheckbox
        value={this.props.value}
        className={this.props.className}
        fieldName={this.props.fieldName}
        onChange={this.props.onChange}
        filterCleared={this.props.filterCleared}
        selectValues={this.state.selectValues}
      />
    );
  }
}
