import React from "react";
import Select from "react-select";

export default class MultiChoice extends React.Component {
  static defaultProps = {
    label: "",
    tooltipText: null,
    onChange: () => alert("MultiChoice Onchange"),
    options: [],
    selected: [],
    selectAll: true,
  };

  constructor(props) {
    super(props);

    this.multiSelectOnChange = this.multiSelectOnChange.bind(this);
  }

  multiSelectOnChange(selected) {
    let selectedValues = null;
    if (selected) {
      if (selected.find((option) => option.value === "all")) {
        selectedValues = this.props.options.map((i) => i.value);
      } else {
        selectedValues = selected.map((i) => i.value);
      }
    } else {
      selectedValues = [];
    }
    this.props.onChange(selectedValues);
  }

  customStyles = {
    menuList: (provided, state) => ({
      ...provided,
      textAlign: "left",
      maxHeight: "130px",
    }),
  };

  renderList() {
    const options = this.props.selectAll
      ? [{ label: "Select All", value: "all" }, ...this.props.options]
      : this.props.options;
    let selectedValues = this.getSelectedValues();
    return (
      <Select
        className="multichoicedropdownparent"
        options={options}
        isMulti={true}
        onChange={this.multiSelectOnChange}
        value={selectedValues}
        styles={this.customStyles}
      />
    );
  }

  getSelectedValues() {
    let result = this.props.options.filter(
      (o) => this.props.selected && this.props.selected.includes(o.value)
    );
    return result;
  }

  render() {
    return (
      <div className="text-center arial w-100" key="mkkey">
        {this.renderList()}
      </div>
    );
  }
}
