const PopupTypesEnum=
{
    Approve:'Approve',
    Attention:'Attention',
    Success:'Success',
    Warning:'Warning',
    Save:'Save',
    Select:'Select',
    Info: 'Info'
};
export default PopupTypesEnum;