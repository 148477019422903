import React from "react";
import PopupTypesEnum from "../Enums/PopupTypesEnum";
import WarningTypesEnum from "../Enums/WarningTypesEnum";
import FigmaPopup from "./figmaPopup";
import LoadingFullScreen from "./Loading/LoadingFullScreen";
import "../css/Messages.css";

export default class MessageComponent extends React.Component {
  static defaultProps = {};

  constructor(props) {
    super(props);
    MessageComponent.SetErrorMessage = this.setErrorMessage.bind(this);
    MessageComponent.SetNotification = this.setNotification.bind(this);
    MessageComponent.SetWarning = this.setWarning.bind(this);
    MessageComponent.SetSuccess = this.setSuccess.bind(this);
    MessageComponent.SetSuccessWithWait = this.setSuccessWithWait.bind(this);
    MessageComponent.SetApprove = this.setApprove.bind(this);
    MessageComponent.SetInfo = this.setInfo.bind(this);
    MessageComponent.SetLoading = this.setLoading.bind(this);
    MessageComponent.SetIcon = this.setIcon.bind(this);
    MessageComponent.SetWarningWithComment =
      this.setWarningWithComment.bind(this);

    this.state = {
      messages: [],
      popup: null,
    };
  }

  //#region Instanced Setter Methods
  setErrorMessage = (message, error) => {
    this.setMessage(message, "error");
    if (error !== undefined) console.error(error);
  };

  setNotification = (message) => {
    this.setMessage(message, "notification");
  };

  setWarning = (message) => {
    this.setState({
      popup: (
        <FigmaPopup
          type={PopupTypesEnum.Warning}
          warningType={WarningTypesEnum.WithOneButton}
          text={message}
          okPopupFunction={() => this.setState({ popup: null })}
          closePopupFunction={() => this.setState({ popup: null })}
        />
      ),
    });
  };

  setWarningWithComment = (
    message,
    label,
    callbackFunction,
    okButtonText = "Send",
    cancelButtonText = "Cancel"
  ) => {
    this.setState({
      popup: (
        <FigmaPopup
          type={PopupTypesEnum.Warning}
          warningType={WarningTypesEnum.WithComment}
          text={message}
          textForReason={label}
          okPopupFunction={(reason) => {
            callbackFunction(reason);
            this.setState({ popup: null });
          }}
          closePopupFunction={() => this.setState({ popup: null })}
          okButtonText={okButtonText}
          cancelButtonText={cancelButtonText}
        />
      ),
    });
  };

  setInfo = (message, textIsHtml = false) => {
    this.setState({
      popup: (
        <FigmaPopup
          type={PopupTypesEnum.Info}
          text={message}
          okPopupFunction={() => this.setState({ popup: null })}
          closePopupFunction={() => this.setState({ popup: null })}
          textIsHtml={textIsHtml}
        />
      ),
    });
  };

  setSuccess = (message, okFunction) => {
    this.setState({
      popup: (
        <FigmaPopup
          type={PopupTypesEnum.Success}
          text={message}
          okPopupFunction={() => {
            this.setState({ popup: null });
            if (okFunction) okFunction();
          }}
          closePopupFunction={() => {
            this.setState({ popup: null });
            if (okFunction) okFunction();
          }}
        />
      ),
    });
  };

  setSuccessWithWait = (message) => {
    window.setTimeout(
      () =>
        this.setState({
          popup: (
            <FigmaPopup
              type={PopupTypesEnum.Success}
              text={message}
              okPopupFunction={() => this.setState({ popup: null })}
              closePopupFunction={() => this.setState({ popup: null })}
            />
          ),
        }),
      1000
    );
  };

  setApprove = (
    message,
    okFunction,
    overButtonText = "DO YOU WANT TO CONTINUE?",
    cancelButtonText = "NO",
    okButtonText = "YES",
    authenticate = true,
    cancelFunction = null
  ) => {
    this.setState({
      popup: (
        <FigmaPopup
          type={PopupTypesEnum.Attention}
          text={message}
          okPopupFunction={() => {
            this.setState({ popup: null });
            okFunction();
          }}
          closePopupFunction={() => {
            this.setState({ popup: null });
            if (cancelFunction) cancelFunction();
          }}
          overButtonText={overButtonText}
          cancelButtonText={cancelButtonText}
          okButtonText={okButtonText}
          authenticate={authenticate}
        />
      ),
    });
  };

  setLoading = (loading) => {
    this.setState({
      popup: loading ? <LoadingFullScreen /> : null,
    });
  };

  setIcon = (message) => {
    this.setMessage(message, "icon");
  };
  //#endregion

  setMessage(message, type = "error") {
    message =
      typeof message === "string" && message.length < 10 && type === "error"
        ? `Error! Errorcode: ${message}`
        : message;
    const tempMessages = [...this.state.messages];
    try {
      if (tempMessages.find((m) => m.message === message) !== undefined) {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    tempMessages.push({ message, type });
    this.setState({ messages: tempMessages });
    window.setTimeout(() => {
      const tempMessages = [...this.state.messages];
      tempMessages.shift();
      this.setState({ messages: tempMessages });
    }, 10000);
  }

  clickOnMessage(m) {
    const tempMessages = this.state.messages;
    const i = tempMessages.indexOf(m);
    delete tempMessages[i];
    this.setState({ mess: tempMessages });
  }

  getClassName(m) {
    if (m.type === "icon") return " divslide h1 text-danger text-center";
    return `divslide errorMessage ${
      m.type === "error" ? "bg-danger" : "bg-info"
    }`;
  }

  render() {
    if (this.state.messages.length < 1 && this.state.popup === null)
      return null;

    return this.state.messages.length > 0 ? (
      <div className="position-absolute top-0 topmost absolute-centered">
        {this.state.messages.map((m, index) => {
          if (!m) return null;
          const className = this.getClassName(m);
          return (
            <div
              className={className}
              key={`erkey${index}`}
              onClick={() => this.clickOnMessage(m)}
            >
              {m.message}
            </div>
          );
        })}
      </div>
    ) : (
      this.state.popup
    );
  }

  //#region Static Methods
  static SetErrorMessage = (message, error) => null;
  static SetNotification = (message) => null;
  static SetWarning = (message) => null;
  static SetSuccess = (message, okFunction) => null;
  static SetSuccessWithWait = (message) => null;
  static SetInfo = (message, textIsHtml = false) => null;
  static SetApprove = (
    message,
    okFunction,
    overButtonText,
    cancelButtonText = "NO",
    okButtonText = "YES",
    authenticate = true,
    cancelFunction = null
  ) => null;
  static SetLoading = (loading) => {};
  static SetIcon = (message) => null;
  static SetWarningWithComment = (
    message,
    label,
    callbackFunction,
    okButtonText,
    cancelButtonText
  ) => null;

  static LogDevInfo = (
    message,
    loc = "",
    envFunc = MessageComponent.NotProd
  ) => {
    if (!envFunc(message)) {
      return;
    }
    if (message == null) {
      console.log(message + (loc ? ` at: ${loc}` : ""));
      return;
    }
    if (typeof message === "string") {
      console.log(message.toString() + (loc ? ` at: ${loc}` : ""));
      return;
    }

    console.log(`Object${loc ? ` at: ${loc}` : ""}`);
    console.log(message);
  };

  static IsDev = (message) =>
    process.env.REACT_APP_HOST_ENV === "development" &&
    MessageComponent.NotFileForDownloadOrNotification(message);
  static IsStage = (message) =>
    process.env.REACT_APP_HOST_ENV === "staging" &&
    MessageComponent.NotFileForDownloadOrNotification(message);
  static IsProd = (message) =>
    process.env.REACT_APP_HOST_ENV === "production" &&
    MessageComponent.NotFileForDownloadOrNotification(message);
  static NotDev = (message) =>
    process.env.REACT_APP_HOST_ENV !== "development" &&
    MessageComponent.NotFileForDownloadOrNotification(message);
  static NotStage = (message) =>
    process.env.REACT_APP_HOST_ENV !== "staging" &&
    MessageComponent.NotFileForDownloadOrNotification(message);
  static NotProd = (message) =>
    process.env.REACT_APP_HOST_ENV !== "production" &&
    MessageComponent.NotFileForDownloadOrNotification(message);
  static EveryWhere = (message) =>
    MessageComponent.NotFileForDownloadOrNotification(message);

  static NotFileForDownloadOrNotification = (message) =>
    typeof message !== "string" ||
    (typeof message === "string" &&
      !message.includes("FileForDownload/GetNumberOfUndownloadedFiles") &&
      !message.includes("Notification/GetNumberOfUnreadMessages"));
  //#endregion
}
