import React from "react";
import FaIcon from "../Elements/FaIcon";

export class JsxHelper {
  static CenterDiv(textContent) {
    return <div className="text-center">{textContent}</div>;
  }
  static LeftDiv(textContent) {
    return <div className="text-left">{textContent}</div>;
  }
  static MailTo(email) {
    return <a href={"mailto:" + email}>{email}</a>;
  }

  static YellowSpan(textContent) {
    return <span className="yellow">{textContent}</span>;
  }

  static Yes(iconclass = "fas fa-check-circle") {
    return <FaIcon iconName={iconclass} innerClassName="text-green" />;
  }

  static No(iconclass = "fas fa-times-circle") {
    return <FaIcon iconName={iconclass} innerClassName="text-red" />;
  }

  static YesNo(value, iconclass) {
    return value === true ||
      (value !== null &&
        typeof value !== "undefined" &&
        value.toString().toLowerCase() === "true")
      ? JsxHelper.Yes(iconclass)
      : JsxHelper.No(iconclass);
  }

  static OnlyYes(value, iconclass) {
    return value === true ||
      (value !== null &&
        typeof value !== "undefined" &&
        value.toString().toLowerCase() === "true")
      ? JsxHelper.Yes(iconclass)
      : null;
  }

  static OnlyNo(value, iconclass) {
    return value === true ||
      (value !== null &&
        typeof value !== "undefined" &&
        value.toString().toLowerCase() === "true")
      ? null
      : JsxHelper.No(iconclass);
  }

  static YesNoReverse(value, iconclass) {
    return value === true ||
      (value !== null &&
        typeof value !== "undefined" &&
        value.toString().toLowerCase() === "true")
      ? JsxHelper.No(iconclass)
      : JsxHelper.Yes(iconclass);
  }

  static YesNoEmpty(value, yesValue, noValue, iconclass) {
    return value === yesValue
      ? JsxHelper.Yes(iconclass)
      : value === noValue
        ? JsxHelper.No(iconclass)
        : null;
  }

  static Chevron() { //">"
    return (
      <FaIcon iconName="fas fa-chevron-right" innerClassName="text-green" />
    );
  }

  static NonBreakableSpace(count = 1) {
    return "\u00a0".repeat(count);
  }

  static ToRight(content) {
    return <div className="text-right">{content}</div>;
  }
}

export default JsxHelper;
