import dateTimeHelper from "../Helpers/dateTimeHelper";
import moneyHelper from "../Helpers/moneyHelper";
import PurchaseOrderService from "../Services/PurchaseOrderService";

export default class PurchaseOrderListItem {
  constructor(item) {
    if (!item) {
      this.PurchaseOrderId = 0;
      this.ClientPoNumber = "";
      this.QuantityOfShops = 0;
      this.TotalFee = 0;
      this.CurrencyCode = "";
      this.CreatedOn = "";
      this.DueDate = "";
      this.EarlyPaymentDiscountPercentage = 0;
      this.EarlyPaymentDiscountDueDate = "";
      this.CreatedByName = "";
      this.PurchaseOrderName = "Purchase Order";
      return;
    }

    this.PurchaseOrderId = item.purchaseOrderId;
    this.ClientPoNumber = item.clientPoNumber;
    this.QuantityOfShops = item.quantityOfShops;
    this.TotalFee = item.totalFee;
    this.CurrencyCode = item.currencyCode;
    this.CreatedOn = new Date(item.createdOn);
    this.DueDate = new Date(item.dueDate);
    this.EarlyPaymentDiscountPercentage = item.earlyPaymentDiscountPercentage;
    this.EarlyPaymentDiscountDueDate = new Date(
      item.earlyPaymentDiscountDueDate
    );
    this.CreatedByName = item.createdByName;
    this.PurchaseOrderName = "Purchase Order";
  }

  static IdField = "PurchaseOrderId";
  static TitleField = "PurchaseOrderName";
  static ShowIdInTitle = true;
  static ShowImage = false;
  static ListServiceFunction = PurchaseOrderService.GetPurchaseOrderList;
  static DetailsEnabled = true;
  static SingleSearchLine = true;

  static FieldsToShowInList = [
    {
      label: "Client PO Number",
      data: "ClientPoNumber",
    },
    {
      label: "Quantity Of Shops",
      data: "QuantityOfShops",
    },
    {
      label: "Total Fee",
      data: (item) =>
        moneyHelper.toMoneyFormat(item.TotalFee, item.CurrencyCode),
    },
    {
      label: "Order Date",
      data: (item) => dateTimeHelper.getFormattedDate(item.CreatedOn, "/"),
    },
    {
      label: "Due Date",
      data: (item) => dateTimeHelper.getFormattedDate(item.DueDate, "/"),
    },
    {
      label: "Early Payment Discount Percentage",
      data: (item) => `${item.EarlyPaymentDiscountPercentage}%`,
    },
    {
      label: "Early Payment Due Date",
      data: (item) =>
        dateTimeHelper.getFormattedDate(item.EarlyPaymentDiscountDueDate, "/"),
    },
    {
      label: "Created By",
      data: "CreatedByName",
    },
  ];
}
