import AuthService from "./AuthService.js";

export default class LocationInvoicingDataService {
  static GetById(searchItem) {
    return AuthService.CallBackendFunction(
      `/api/Location_GetLocationInvoicingDataById/${JSON.stringify(searchItem)}`
    );
  }

  static Update(fieldName, value, editedItem) {
    const body = {
      CxClientId: editedItem.CxClientId,
      LocationId: editedItem.LocationId,
      LocationInvoicingDataId: editedItem.LocationInvoicingDataId,
      FieldName: fieldName,
      NewValue: value,
    };

    return AuthService.CallBackendFunctionPut(
      "/api/Location_UpdateLocationInvoicingData/",
      body
    );
  }

  static SetInvoicingDataForMultipleLocations(editeditem) {
    return AuthService.CallBackendFunctionPut(
      "/api/Location_UpdateMultipleLocationInvoicingData/",
      editeditem
    );
  }
}
