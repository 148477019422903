import React from "react";
import dateTimeHelper from "../../Helpers/dateTimeHelper";
import InputTypesEnum from "../../Enums/InputTypesEnum";
import FaIcon from "../FaIcon";
import urlHelper from "../../Helpers/urlHelper";
import PlaceholderField from "./Base/PlaceholderField";

export default class InputWithClearBase extends React.Component {
  static defaultProps = {
    className: "w-100 form-control form-control-sm",
    disableClearButton: false,
    disabled: false,
    displayHyperlink: false,
    errorMessage: "",
    fieldName: "defaultFieldname",
    info: "",
    isInOneRow: false,
    labelIsDisabled: false,
    labelText: "Default Label Text",
    margin: "m-1",
    onChange: () => {},
    renderedInputField: null,
    tooltipText: null,
    type: InputTypesEnum.Text,
    value: null,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  defaultValue() {
    switch (this.props.type) {
      case InputTypesEnum.Date:
      case InputTypesEnum.DateWithIndefinite:
        return dateTimeHelper.toYYYYsMMsDD(new Date(), "-");
      case InputTypesEnum.PhoneNumber:
      case InputTypesEnum.BusinessPhone:
        return "+1-";
      default:
        return "";
    }
  }

  renderPlaceholderField() {
    return (
      <PlaceholderField
        className={this.props.className}
        fieldName={this.props.fieldName}
      />
    );
  }

  renderInfoTooltip() {
    return this.props.tooltipText ? (
      <>
        &nbsp;
        <FaIcon
          iconName="fa fa-info-circle"
          tooltipText={this.props.tooltipText}
        />
      </>
    ) : null;
  }

  renderEraser() {
    const disabledTypes = [
      InputTypesEnum.OnOff,
      InputTypesEnum.SingleCheckBox,
      InputTypesEnum.PlaceHolder,
      InputTypesEnum.MultiChoice,
      InputTypesEnum.Checkbox,
      InputTypesEnum.EmptySpace,
      InputTypesEnum.ClientMultiChoice,
      InputTypesEnum.CountryMultiChoice,
      InputTypesEnum.CountryCodeMultiChoice,
      InputTypesEnum.Notes,
      InputTypesEnum.TextReadOnly,
      InputTypesEnum.FileUploadField,
    ];

    const disabled =
      this.props.disabled ||
      this.props.disableClearButton ||
      this.props.value === this.defaultValue() ||
      disabledTypes.includes(this.props.type);

    return !disabled ? (
      <span
        className="btn btn-info btn-sm form-control-orange "
        onClick={() => {
          this.props.onChange(this.props.fieldName, this.defaultValue());
        }}
      >
        <i className="fas fa-eraser" />
      </span>
    ) : null;
  }

  renderRedirectLinkTag(errorMessage) {
    const disabled = !this.props.displayHyperlink || errorMessage;
    const url = urlHelper.generateRedirectUrlWithMultipleParameters("sales", [
      { key: "CompanyName", value: this.props.value },
      { key: "UserId", value: "" },
      { key: "IsActive", value: "" },
    ]);

    return !disabled ? (
      <div className={"text-left p-1 text-danger"}>
        <a
          style={{ color: "yellow" }}
          href={url}
          target={"_blank"}
          rel="noreferrer"
        >
          <i className="fas fa-magnifying-glass-arrow-right" /> Open new tab to
          display matching leads
        </a>
      </div>
    ) : null;
  }

  renderErrorMessage(errorMessage) {
    return errorMessage || this.props.info ? (
      <div
        className={`text-left p-1 ${
          errorMessage ? "text-danger" : "text-light"
        }`}
      >
        {errorMessage ? errorMessage : this.props.info}
      </div>
    ) : null;
  }

  renderLabel(errorMessage) {
    const disabledTypes = [
      InputTypesEnum.EmptySpace,
      InputTypesEnum.MultiChoice,
      InputTypesEnum.ClientMultiChoice,
      InputTypesEnum.Text2,
      InputTypesEnum.SingleCheckBox,
    ];
    const disabled =
      this.props.labelIsDisabled || disabledTypes.includes(this.props.type);

    return disabled ? null : (
      <label
        className={`m-0 p-0 defaultfontsize ${
          errorMessage === "" && this.props.info !== "" ? "text-light" : ""
        }`}
        htmlFor={`htmlidfor${this.props.fieldName}`}
      >
        {this.props.labelText}
        {this.renderInfoTooltip()}
      </label>
    );
  }

  render() {
    const disabledTypes = [
      InputTypesEnum.Text2,
      InputTypesEnum.SingleCheckBox,
      InputTypesEnum.TextBox2,
      InputTypesEnum.NumberWithTwoDecimalPlaces2,
      InputTypesEnum.Number2,
      InputTypesEnum.EmptyYesNoSelect2,
      InputTypesEnum.Select2,
    ];
    if (disabledTypes.includes(this.props.type)) {
      return this.props.renderedInputField;
    }

    return (
      <div
        className={
          "col text-center rounded arial m-1 p-1" +
          (this.props.labelText && this.props.type !== InputTypesEnum.EmptySpace
            ? " hover"
            : "") +
          (this.props.errorMessage
            ? " border rounded border-danger bg-warning m-1 "
            : this.props.info !== "" || this.props.errorMessage !== ""
              ? "border bg-secondary "
              : "")
        }
        key={`k${this.props.fieldName}`}
      >
        {this.props.isInOneRow ? (
          <div className={`container-fluid ${this.props.margin}`}>
            <div className="row">
              {!this.props.labelIsDisabled && (
                <div className="col-3 wizzarddiv">
                  {this.props.labelText}
                  {this.renderInfoTooltip()}
                </div>
              )}
              <div className="col-9">
                <div className={"d-flex "}>
                  {!this.props.disabled ? (
                    <>
                      {this.props.renderedInputField}
                      {this.renderEraser()}
                    </>
                  ) : (
                    this.renderPlaceholderField()
                  )}
                </div>
              </div>
            </div>
            {this.renderErrorMessage(this.props.errorMessage)}
            {this.renderRedirectLinkTag(this.props.errorMessage)}
          </div>
        ) : (
          <div>
            {this.renderLabel()}
            <div className={`d-flex ${this.props.margin}`}>
              {!this.props.disabled ? (
                <>
                  {this.props.renderedInputField}
                  {this.renderEraser()}
                </>
              ) : (
                this.renderPlaceholderField()
              )}
            </div>
            {this.renderErrorMessage(this.props.errorMessage)}
          </div>
        )}
      </div>
    );
  }
}
