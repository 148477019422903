import BareManagerService from "../Services/BareManagerService";

export default class BareManagerListItem {
  constructor(item) {
    if (!item) {
      this.BareManagerId = 0;
      this.CxClientId = 0;
      this.Name = "";
      this.TimeZone = "";
      this.JobTitle = "";
      this.Email = "";
      this.PhoneNumber = "";
      return;
    }

    this.BareManagerId = item.bareManagerId;
    this.CxClientId = item.cxClientId;
    this.Name = item.name;
    this.TimeZone = item.timeZone;
    this.JobTitle = item.jobTitle;
    this.Email = item.email;
    this.PhoneNumber = item.phoneNumber;
  }

  static IdField = "BareManagerId";
  static TitleField = "Name";
  static ShowIdInTitle = false;
  static ShowImage = true;
  static ShowImage_GetInitialsFunction = item =>
    item.Name.split(" ")
      .map((n, index) => (index <= 1 ? n[0] : ""))
      .join("")
      .toUpperCase();
  static ListServiceFunction = BareManagerService.GetList;
  static DetailsEnabled = false;

  static FieldsToShowInList = [
    {
      label: "Jobtitle",
      data: "JobTitle",
    },
    {
      label: "Email",
      data: "Email",
    },
    {
      label: "Phone",
      data: "PhoneNumber",
    },
    {
      label: "Timezone",
      data: "TimeZone",
    },
  ];
}
