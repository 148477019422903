import stringHelper from "./stringHelper";

export default class ValidityHelper {
  static forRequiredTextField(e) {
    if (!e || e.toString().trim() === "") return "This field can't be empty.";
    return "";
  }

  static forMaximizedLengthField(e, maxLength) {
    if (e && e.length > maxLength)
      return `This field can't store more than ${stringHelper.numberWithLetter(
        maxLength,
        false
      )} characters.`;
    return "";
  }

  static forRequiredAndMaxLengthField(e, maxLength) {
    if (!e || e.toString().trim() === "") return "This field can't be empty.";
    if (e && e.length > maxLength)
      return `This field can't store more than ${stringHelper.numberWithLetter(
        maxLength,
        false
      )} characters.`;
    return "";
  }

  static forRequiredPhoneNumber(e) {
    if (!e || e.toString().trim() === "") return "This field ca1n't be empty.";
    if (!e.startsWith("+")) {
      return "Phone number isn't correct. Correct format:+12-34-567890";
    }
    const parts = e.split("-");

    if (parts.length < 3) {
      return "Phone number isn't correct. Correct format: +12-34-567890";
    }
    if (e.toString().length < 10) {
      return "Phone number is too short. Correct format: +12-34-567890";
    }
    if (parts.some(part => part.trim().length === 0)) {
      return "All parts of the phone number have to be filled.";
    }
    if (parts.some(part => isNaN(part))) {
      return "All parts of the phone number have to be numbers.";
    }
    return "";
  }

  static forRequiredBoolField(e) {
    if (e !== "true" && e !== "false" && e !== true && e !== false)
      return "This field can't be empty.";
    return "";
  }

  static forRequiredNumberFieldGreaterThanZero(e) {
    if (e <= 0) return "This value has to be more than zero. ";
    return "";
  }

  static forRequiredIntegerGreaterThanZero(e) {
    e = parseInt(e);
    if (!e || e <= 0) return "This value has to be more than zero. ";
    return "";
  }

  static forRequiredNumberField(e) {
    if (e < -1000 || e > 1000) return "This value has to be a number. ";
    return "";
  }

  static forRequiredNumberFieldAtLeastZero(e) {
    if (e < 0) return "This value has to be at least zero. ";
    return "";
  }

  static forRequiredNumberFieldMinZeroMaxOneHundred(e) {
    if (e < 0 || e > 100)
      return "This value has to be between 0 and 100. (0 <= X <= 100) ";
    return "";
  }

  static forRequiredSelectField(e) {
    if (!e || parseInt(e) <= 0) return "This field can't be empty. ";
    return "";
  }

  static forRequiredMultiSelectField(e) {
    if ((Array.isArray(e) && e.length === 0) || !e || parseInt(e) <= 0)
      return "This field can't be empty. ";
    return "";
  }

  static forEmailNotRequired(e) {
    if (!e) return "";

    return ValidityHelper.forEmailRequired(e);
  }

  static forEmailRequired(e) {
    const res = ValidityHelper.forRequiredTextField(e);
    if (res !== "") {
      return res;
    }
    const addresses = e.split(";");
    const mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+[.]+[a-zA-Z0-9-]/;
    for (const email of addresses) {
      if (!mailformat.test(email)) return "The email address isn't correct.";
    }

    return "";
  }

  static AlwaysFlawless(e) {
    return "";
  }

  static forRequiredDateLatterThanToday(e) {
    const today = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    );
    if (e < today || e === "NaN-NaN-NaN") {
      return "The date can't be before today.";
    }

    return "";
  }

  static forTextLenghtCheck(text, value) {
    if (text.length !== value || !text.match(/^[A-Za-z]+$/))
      return `Must be ${value} letters`;
    return "";
  }

  static validityCheckerforVatNumber(e, editedItem) {
    if (editedItem === undefined) return "";
    if (!e || !stringHelper.onlyLettersAndNumbers(e))
      return "This field can't contain anything else except numbers and letters.";
    return "";
  }

  static forOptionalColorField(e, editedItem) {
    if (e) {
      if (CSS.supports("color", e)) {
        return "";
      }
      return "The color is not valid.";
    }
    return "";
  }

  static forTextClientRequired(e, isRequired) {
    if (isRequired && (!e || e.toString().trim() === ""))
      return "This field can't be empty.";
    return "";
  }

  static forEmailClientRequired(e, isRequired) {
    if (isRequired || e) return ValidityHelper.forEmailRequired(e);

    return "";
  }
}
