import React from "react";
import GeneralItemList from "../Elements/CardList/GeneralItemList";
import PricingListItem from "../Domain/PricingListItem";
import PricingSearchParams from "../Contents/SearchParams/PricingSearchParams";
import FigmaButtonRow from "../Elements/Button/FigmaButtonRow";
import ButtonColorsEnum from "../Enums/ButtonColorsEnum";
import IconPositionEnum from "../Enums/IconPositionEnum";
import ShownPanelsEnum from "../Enums/ShownPanelsEnum";
import GeneralEditWizzardBase from "../Elements/Wizzards/GeneralEditWizzardBaseFigma";
import WizzardTypeEnum from "../Enums/WizzardTypeEnum";
import CreatePurchaseOrderLineWindowDescription from "../Contents/WindowDescriptions/CreatePurchaseOrderLineWindowDescription";
import PurchaseOrderLineData from "../Contents/Communication/PurchaseOrderLineData";
import CreatePurchaseOrderWindowDescription from "../Contents/WindowDescriptions/CreatePurchaseOrderWindowDescription";
import PurchaseOrderData from "../Contents/Communication/PurchaseOrderData";
import MessageComponent from "../Elements/MessageComponent";

export default class PricingsAdmin extends React.Component {
  static defaultProps = {
    detailsItem: null,
  };

  constructor(props) {
    super(props);
    this.SearchParamsType = PricingSearchParams;
    this.state = {
      searchItem: new this.SearchParamsType(),
      shownPanel: ShownPanelsEnum.None,
      editedItem: new PurchaseOrderData(),
      remainingItems: null,
    };

    if (this.props.detailsItem?.LocationId) {
      this.state.searchItem.LocationId = this.props.detailsItem.LocationId;
    }

    this.refresh = this.refresh.bind(this);
    this.showPanel = this.showPanel.bind(this);
    this.onPurchaseOrderCreateBase = this.onPurchaseOrderCreateBase.bind(this);
  }

  //#region Event Handlers
  onPurchaseOrderCreateBase(currentItems, showMessage = true) {
    const orderLines = currentItems.filter(
      (x) =>
        x.pricingServiceOwningOfficeId ===
        currentItems[0].pricingServiceOwningOfficeId
    );

    const remainingItems = currentItems.filter(
      (x) =>
        x.pricingServiceOwningOfficeId !==
        currentItems[0].pricingServiceOwningOfficeId
    );

    const uniqueLocations = orderLines
      .filter(
        (value, index, array) =>
          array.map((x) => x.locationId).indexOf(value.locationId) === index
      )
      .map((x) => {
        return { value: x.locationId, label: x.locationClientLocationName };
      });

    const purchaseOrder = new PurchaseOrderData(orderLines, uniqueLocations);

    if (showMessage && remainingItems.length > 0) {
      MessageComponent.SetWarning(
        "The selected services belong to multiple BARE offices, so we need to create multiple purchase orders."
      );
    }

    this.setState({
      editedItem: purchaseOrder,
      remainingItems: remainingItems.length > 0 ? remainingItems : null,
      shownPanel: ShownPanelsEnum.CreatePurchaseOrder,
    });
  }
  //#endregion

  refresh(refresh) {
    if (refresh) {
      this.setState({
        shownPanel: ShownPanelsEnum.None,
        searchItem: { ...this.state.searchItem },
      });
    } else this.setState({ shownPanel: ShownPanelsEnum.None });
  }

  renderOnePanel = (panelId, content) =>
    this.state.shownPanel === panelId ? content : null;

  showPanel(panelId) {
    panelId =
      panelId === this.state.shownPanel ? ShownPanelsEnum.None : panelId;
    this.setState({ shownPanel: panelId });
  }

  //#region Render
  renderPanels() {
    return (
      <>
        {this.renderOnePanel(
          ShownPanelsEnum.CreatePurchaseOrderLine,
          <GeneralEditWizzardBase
            crudType={WizzardTypeEnum.Create}
            editedItem={new PurchaseOrderLineData(this.props.detailsItem)}
            clickOnCancel={() => this.refresh(false)}
            clickOnSave={(resultItem) => {
              this.onPurchaseOrderCreateBase(resultItem);
            }}
            windowDescription={CreatePurchaseOrderLineWindowDescription}
            errorCode="PA106"
            followUpMessage="Would you like to create a Purchase Order for the created Purchase Order Lines?"
          />
        )}
        {
          //This wizzard also exists in PurchaseOrderLinesAdmin,
          //if changes occur here, they most likely need to be changed there too
          this.renderOnePanel(
            ShownPanelsEnum.CreatePurchaseOrder,
            <GeneralEditWizzardBase
              crudType={WizzardTypeEnum.Create}
              editedItem={this.state.editedItem}
              clickOnCancel={() =>
                this.refresh(this.state.remainingItems?.length > 0)
              }
              clickOnSave={() =>
                this.state.remainingItems?.length > 0
                  ? this.onPurchaseOrderCreateBase(
                      this.state.remainingItems,
                      false
                    )
                  : this.refresh(true)
              }
              windowDescription={CreatePurchaseOrderWindowDescription}
              errorCode="PA155"
              followUpMessage={
                this.state.remainingItems?.length > 0
                  ? "Would you like to create the Purchase Order for the next office?"
                  : null
              }
            />
          )
        }
      </>
    );
  }

  renderButtonsAndPanels() {
    if (!this.props.detailsItem?.LocationId) return null;

    return (
      <>
        <FigmaButtonRow
          isOverWindow={true}
          buttons={[
            {
              iconName: "fas fa-file-lines",
              tooltip: "Add Service to Location",
              color: ButtonColorsEnum.Primary,
              iconPosition: IconPositionEnum.Center,
              onClick: () =>
                this.showPanel(ShownPanelsEnum.CreatePurchaseOrderLine),
            },
          ]}
        />
        {this.renderPanels()}
      </>
    );
  }

  render() {
    return (
      <>
        {this.renderButtonsAndPanels()}
        <GeneralItemList
          itemType={PricingListItem}
          searchItem={this.state.searchItem}
          searchParamsType={this.SearchParamsType}
          clickOnStartSearch={(searchItem) =>
            this.setState({
              searchItem: {
                ...searchItem,
                LocationId: this.state.searchItem.LocationId,
              },
            })
          }
          selectButtonDisabled={true}
        />
      </>
    );
  }
  //#endregion
}
