import React from "react";
import OverWindow from "../OverWindow";
import DetailsContainer from "../DetailsContainer";
import LoadingFullScreen from "../Loading/LoadingFullScreen";
import TabbedPage from "../TabbedPage";
import MessageComponent from "../MessageComponent";
import TranslationComponent from "../TranslationComponent";

export default class GeneralItemDetails extends React.Component {
  static defaultProps = {
    itemType: null,
    selectedId: null,
    clickOnCancel: null,
    isOverWindow: true,
    showTabs: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      itemDetails: null,
      loading: true,
      tabs: [],
      selectedTab: 0,
    };

    this.clickOnSave = this.clickOnSave.bind(this);
    if (this.props.itemType.reloadFunctionDetailsAfterSave) {
      this.props.itemType.reloadFunctionDetailsAfterSave =
        this.reloadFunctionDetailsAfterSave.bind(this);
    }
  }

  reloadFunctionDetailsAfterSave() {
    this.getDetails();
  }

  getDetails() {
    this.props.itemType
      .DetailsServiceFunction(this.props.selectedId)
      .then((response) => {
        const convertedDetails = new this.props.itemType(response);
        const tabs = this.getTabsFromDescription(convertedDetails);
        this.setState({
          itemDetails: convertedDetails,
          loading: false,
          tabs,
        });
      })
      .catch((error) => {
        MessageComponent.SetErrorMessage("Couldn't get item details.", error);
        this.setState({ loading: false });
      });
  }

  componentDidMount() {
    this.getDetails();
  }

  clickOnSave(fieldName, value, separateFieldForShow) {
    if (separateFieldForShow) {
      this.getDetails();
      return;
    }

    const itemDetails = this.state.itemDetails;
    itemDetails[fieldName] = value;

    const tabs = this.getTabsFromDescription(itemDetails);

    this.setState({ itemDetails, tabs });
  }

  getTabsFromDescription(itemDetails) {
    const tabs = [];
    this.props.itemType.fieldsToShow.forEach((tabData) => {
      const tab = {};
      tab.label = tabData.tabName;
      tab.hide = tabData.hide;
      if (tabData.type === "details") {
        tab.component = (
          <DetailsContainer
            displayedItem={itemDetails}
            fieldsToShow={tabData.data}
            buttons={tabData.buttons}
            updateFunction={tabData.updateFunction}
            clickOnSave={this.clickOnSave}
          />
        );
      } else if (tabData.type === "component") {
        tab.component = (
          <tabData.data detailsItem={itemDetails} itemType={tabData.itemType} />
        );
      } else {
        tab.component = <>{tabData.type} type not implemented.</>;
      }
      tabs.push(tab);
    });
    return tabs;
  }

  renderAsOverWindow(tabbedPage) {
    if (this.props.itemType.DetailsTitle) {
      return (
        <OverWindow
          title={
            <TranslationComponent
              phrase={this.props.itemType.DetailsTitle}
              loadingEnabled={false}
            />
          }
          clickOnCancel={this.props.clickOnCancel}
        >
          {tabbedPage}
        </OverWindow>
      );
    }
    return (
      <OverWindow
        title={`${this.state.itemDetails[this.props.itemType.TitleField]} (${
          this.state.itemDetails[this.props.itemType.IdField]
        })`}
        clickOnCancel={this.props.clickOnCancel}
      >
        {tabbedPage}
      </OverWindow>
    );
  }

  render() {
    if (this.state.loading) {
      return <LoadingFullScreen />;
    }

    const tabbedPage = (
      <TabbedPage
        tabs={this.state.tabs}
        openOnTab={this.state.selectedTab}
        onTabChange={(newValue) => this.setState({ selectedTab: newValue })}
        showTabs={this.props.showTabs}
      />
    );

    return this.props.isOverWindow
      ? this.renderAsOverWindow(tabbedPage)
      : tabbedPage;
  }
}
