const InputTypesEnum = {
  Placeholder: "Placeholder",
  Text: "Text",
  Number: "Number",
  EmptyYesNoSelect: "EmptyYesNoSelect",
  CountrySelect: "CountrySelect",
  CountryMultiChoice: "CountryMultiChoice",
  OfficeMultiChoice: "OfficeMultiChoice",
  Select: "Select",
};

export default InputTypesEnum;
