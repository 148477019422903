import React from "react";
import "../../css/ProgressBarDots.css";

export default class ProgressBarDotsFigma extends React.Component {
  static defaultProps = {
    actual: 1,
    data: {
      pagesInGeneralEditWizzard: [
        {
          label: "Page 1",
        },
        {
          label: "Page 2",
        },
      ],
    },
  };

  render() {
    const LabelListLength =
      this.props.data.pagesInGeneralEditWizzard.length - 1;
    return (
      <div className="ProgressBarContent">
        <div className="ProgressBarBody">
          {this.props.data.pagesInGeneralEditWizzard.map((item, index) => {
            return (
              <div
                style={{ display: "contents" }}
                key={index}
                onClick={
                  this.props.clickOnDot
                    ? () => this.props.clickOnDot(index)
                    : null
                }
              >
                {this.props.actual - 1 === index ? (
                  <div>
                    <p className="ProgressBarBodyText ProgressBarBodyActiveText">
                      {" "}
                      {item.label}
                    </p>
                  </div>
                ) : (
                  <p
                    className={`ProgressBarBodyText ${
                      this.props.actual - 1 >= index
                        ? "ProgressBarBodyInactivePass"
                        : "ProgressBarBodyInactiveText"
                    }`}
                  >
                    {" "}
                    {item.label}
                  </p>
                )}
                {index === LabelListLength ? (
                  ""
                ) : (
                  <p
                    className={`ProgressBarArrow ${
                      this.props.actual - 2 >= index
                        ? "ProgressBarArrowPass"
                        : "right"
                    }`}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
