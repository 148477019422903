import moneyHelper from "../Helpers/moneyHelper";
import PurchaseOrderLineService from "../Services/PurchaseOrderLineService";

export default class PurchaseOrderLineForPOListItem {
  constructor(item) {
    if (!item) {
      this.PurchaseOrderLineId = 0;
      this.PricingServiceDisplayServiceName = "";
      this.NumberOfFieldworkPeriod = 0;
      this.PricingClientFee = 0;
      this.PricingClientFeeCurrencyCode = "";
      this.LocationClientLocationName = "";
      this.LocationCountryName = "";
      return;
    }

    this.PurchaseOrderLineId = item.purchaseOrderLineId;
    this.PricingServiceDisplayServiceName =
      item.pricingServiceDisplayServiceName;
    this.NumberOfFieldworkPeriod = item.numberOfFieldworkPeriod;
    this.PricingClientFee = item.pricingClientFee;
    this.PricingClientFeeCurrencyCode = item.pricingClientFeeCurrencyCode;
    this.LocationClientLocationName = item.locationClientLocationName;
    this.LocationCountryName = item.locationCountryName;
  }

  static IdField = "PurchaseOrderLineId";
  static TitleField = "PricingServiceDisplayServiceName";
  static ShowIdInTitle = true;
  static ListServiceFunction = PurchaseOrderLineService.GetList;
  static ShowImage = false;
  static DetailsEnabled = false;

  static FieldsToShowInList = [
    {
      label: "Country",
      data: "LocationCountryName",
    },
    {
      label: "Location",
      data: "LocationClientLocationName",
    },
    {
      label: "Fieldwork Periods",
      data: "NumberOfFieldworkPeriod",
    },
    {
      label: "Fee Per Fieldwork Period",
      data: (item) =>
        moneyHelper.toMoneyFormat(
          item.PricingClientFee,
          item.PricingClientFeeCurrencyCode
        ),
    },
    {
      label: "Total Fee",
      data: (item) =>
        moneyHelper.toMoneyFormat(
          item.NumberOfFieldworkPeriod * item.PricingClientFee,
          item.PricingClientFeeCurrencyCode
        ),
    },
  ];
}
