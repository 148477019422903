import React from "react";
import AuthService from "../Services/AuthService";
import MessageComponent from "../Elements/MessageComponent";
import TranslationComponent from "../Elements/TranslationComponent";
import "../css/DeleteProfile.css";

export default class DeleteProfile extends React.Component {
  onDeleteClick() {
    AuthService.getInstance()
      .SendDeleteEmail()
      .then(() => {
        MessageComponent.SetWarning(
          "Delete request has been issued to the appropriate party at BARE, for further info contact dataprivacy@bareinternational.com."
        );
      })
      .catch((error) => MessageComponent.SetErrorMessage("DP14"));
  }

  render() {
    return (
      <div>
        <h4 className="m-4">
          <TranslationComponent phrase="Delete Profile" />
        </h4>
        <p>
          <TranslationComponent phrase="Are you sure you want to delete your profile?" />
        </p>
        <button
          type="button"
          className="figmabuttondestroy destroy normalnormal delte-profile__button"
          onClick={this.onDeleteClick}
        >
          <TranslationComponent phrase="Delete" />
        </button>
      </div>
    );
  }
}
