import InputTypesEnum from "../../Enums/InputTypesEnum";

export default class LocationSearchParams {
  constructor() {
    this.initialize();
  }

  initialize() {
    this.LocationId = "";
    this.LocationName = "";
    this.CountryCode = "";
    this.City = "";
    this.IsLinkedToService = "";
    this.IsActive = "";
    this.isMissingData = "";
  }

  ClearAll() {
    this.initialize();
  }

  static fieldsInSearchBox = [
    {
      fieldName: "LocationId",
      label: "Location ID",
      className: "col-4",
      type: InputTypesEnum.Text,
    },
    {
      fieldName: "ClientLocationName",
      label: "Location Name",
      className: "col-4",
      type: InputTypesEnum.Text,
    },
    {
      fieldName: "CountryCode",
      label: "Country Code",
      className: "col-4",
      type: InputTypesEnum.Text,
    },
    {
      fieldName: "City",
      label: "City",
      className: "col-4",
      type: InputTypesEnum.Text,
    },
    // {
    //   fieldName: "IsLinkedToService",
    //   label: "Linked to Service?",
    //   className: "col-4",
    //   type: InputTypesEnum.EmptyYesNoSelect,
    // },
    {
      fieldName: "IsActive",
      label: "Active?",
      className: "col-4",
      type: InputTypesEnum.EmptyYesNoSelect,
    },
    {
      fieldName: "IsMissingData",
      label: "Invoicing Data Missing?",
      className: "col-4",
      type: InputTypesEnum.EmptyYesNoSelect,
    },
  ];

  static OrderByFields = [
    {
      label: "Location Id Asc.",
      data: "LocationId_asc",
    },
    {
      label: "Location Id Desc.",
      data: "LocationId_desc",
    },
    {
      label: "Location Name Asc.",
      data: "ClientLocationName_asc",
    },
    {
      label: "Location Name Desc.",
      data: "ClientLocationName_desc",
    },
    {
      label: "Country Asc.",
      data: "CountryCode_asc",
    },
    {
      label: "Country Desc.",
      data: "CountryCode_desc",
    },
    {
      label: "City Asc.",
      data: "City_asc",
    },
    {
      label: "City Desc.",
      data: "City_desc",
    },
    {
      label: "Is Active Asc.",
      data: "IsActive_asc",
    },
    {
      label: "Is Active Desc.",
      data: "IsActive_desc",
    },
  ];
}
