import React from "react";

export default class PlaceholderField extends React.Component {
  static defaultProps = {
    className: "w-100 form-control form-control-sm",
    fieldName: "defaultFieldname",
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <input
        type="text"
        className={this.props.className}
        placeholder="true"
        value=" "
        id={`htmlidfor${this.props.fieldName}`}
        disabled={true}
      />
    );
  }
}
