import axios from "axios";
import AuthService from "./AuthService.js";

export default class LoginService {
  static async SubmitEmailForNewPassword(email, language) {
    const url = "/api/Account_SubmitEmailForNewPassword/";
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        "Content-Type": "application/json",
      },
    });
    const response = await axiosInstance.post(url, { email, language });
    return response;
  }

  static async SubmitNewPassword(password) {
    const url = "/api/Account_SubmitNewPassword/";
    const response = await AuthService.CallBackendFunctionPost(url, {
      password,
      uniqueDeviceId: AuthService.getInstance().GetUniqueDeviceId(),
    });
    AuthService.getInstance().deleteSecret();
    return response;
  }

  static GetNewPassPhrase(password) {
    const url = "/api/Account_GetNewPassPhrase/";
    return AuthService.CallBackendFunctionPost(url, { password });
  }

  static GetNewPronounceablePassword(password) {
    const url = "/api/Account_GetNewPronounceablePassword/";
    return AuthService.CallBackendFunctionPost(url, { password });
  }
}
