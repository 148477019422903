import AuthService from "./AuthService";

export default class OfficeService {
  static offices = [{ officeId: 1, name: "BARE US" }];

  static async GetOfficeList() {
    if (!this.offices || this.offices.length === 1) {
      OfficeService.offices = await AuthService.CallBackendFunction(
        "/api/Office/GetOfficesAsync"
      );
    }
    return OfficeService.offices;
  }
}
