import React from "react";
import "../css/OuterPage.css";
import "../css/ChangePassword.css";
import LoginService from "../Services/LoginService";
import TranslationComponent from "../Elements/TranslationComponent";
import LoadingFullScreen from "../Elements/Loading/LoadingFullScreen";
import AuthService from "../Services/AuthService";
import FaIcon from "../Elements/FaIcon";
import MessageComponent from "../Elements/MessageComponent";

export default class ChangePassword extends React.Component {
  static defaultProps = {
    handleSubmit: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      password: "",
      loading: false,
    };
  }

  componentDidMount() {}

  async handleSubmit() {
    try {
      this.setState({ loading: true });
      await LoginService.SubmitNewPassword(this.state.password);
      this.setState({ loading: false });
      this.props.handleSubmit();
      AuthService.getInstance().Logout();
      window.history.pushState({}, "", "/");
      window.location.reload();
    } catch (e) {
      MessageComponent.SetErrorMessage("Couldn't save new password.", e);
      this.setState({ loading: false });
    }
  }

  getPasswordDisplay() {
    return (
      <div className="input-container">
        <div
          className={
            "m-2 border rounded" + (this.state.password ? "" : " text-black-50")
          }
        >
          {this.state.password ? (
            this.state.password
          ) : (
            <TranslationComponent phrase="Your new password" />
          )}
          {this.state.password && (
            <FaIcon
              iconName="fa-solid fa-copy"
              className="copyicon"
              tooltipText="Copy to clipboard"
              onClick={() => {
                navigator.clipboard.writeText(this.state.password);
              }}
            />
          )}
        </div>
      </div>
    );
  }

  getPassButtons() {
    return (
      <div className="input-container">
        {this.getButton("Passcode", () => {
          this.getNewPassword();
        })}
        {this.getButton("Passphrase", () => {
          this.getNewPassPhrase();
        })}
        {this.getButton("Password", () => {
          this.getNewPronounceablePassword();
        })}
      </div>
    );
  }

  getButton(text, onclickFunction, disabled) {
    return (
      <button
        className="m-2"
        onClick={() => {
          this.setState({ loading: true });
          onclickFunction();
        }}
        disabled={disabled}
      >
        <TranslationComponent phrase={text} />
      </button>
    );
  }

  getInfo() {
    return (
      <div>
        <div className={"m-4 h4"}>
          <TranslationComponent phrase="Change Password" />
        </div>
        <div>
          <TranslationComponent phrase="You can generate a new password for yourself. Please be aware, that we don't store your password in our database. If you forget your password, you will have to generate a new one." />
        </div>
      </div>
    );
  }

  getNewPassword() {
    const letters =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890§$%&()=?*+#-_.:,;<>@€";
    let password = "";
    for (let i = 0; i < 10; i++) {
      password += letters[Math.floor(Math.random() * letters.length)];
    }
    this.setState({ password, loading: false });
  }

  getNewPassPhrase() {
    this.setState({ loading: true });
    LoginService.GetNewPassPhrase()
      .then(response => {
        this.setState({
          password: response.content,
        });
      })
      .catch(error => {
        MessageComponent.SetErrorMessage("Couldn't get new passphrase.", error);
      })
      .finally(() => this.setState({ loading: false }));
  }

  getNewPronounceablePassword() {
    this.setState({ loading: true });
    LoginService.GetNewPronounceablePassword()
      .then(response => {
        this.setState({
          password: response.content,
        });
      })
      .catch(error => {
        MessageComponent.SetErrorMessage(
          "Couldn't get new pronounceable password.",
          error
        );
      })
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    if (this.state.loading) return <LoadingFullScreen />;
    return (
      <div className="credential-container">
        {this.getInfo()}
        {this.getPassButtons()}
        {this.getPasswordDisplay()}
        <div className="input-container">
          {this.getButton(
            "Save new password",
            () => this.handleSubmit(),
            !this.state.password
          )}
        </div>
      </div>
    );
  }
}
