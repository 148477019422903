import React from "react";
import GeneralEditWizzardPageBase from "./GeneralEditWizzardPageBaseFigma";
import "../../css/SimpleWizzardPage.css";
import InputWithClear from "../Input/InputWithClear";

export default class SimpleWizzardPage extends GeneralEditWizzardPageBase {
  //common defaultProps are defined in GeneralEditWizzardPageBase
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      searchItem: new props.windowDescription.searchParamsType(
        this.props.editedItem
      ),
    };
  }

  //#region Override Functions
  validityCheckerForTheEntireForm() {
    const currentPage =
      this.state.windowDescription.pagesInGeneralEditWizzard[this.props.step];

    for (const field of currentPage.fields) {
      if (
        !field.hide ||
        (typeof field.hide === "function" &&
          field.hide(this.state.editedItem) === false)
      ) {
        if (
          typeof field.validityChecker === "function" &&
          field.validityChecker(this.state.editedItem[field.data]) !== ""
        ) {
          return false;
        }
      }
    }

    return true;
  }

  renderField(field) {
    if (
      field.hide !== undefined &&
      field.hide(this.state.editedItem) === true
    ) {
      return null;
    }

    const coreField = (
      <InputWithClear
        labelText={field.label}
        type={field.type}
        value={this.state.editedItem[field.data]}
        onChange={(field, newValue) =>
          this.setState({
            editedItem: { ...this.state.editedItem, [field]: newValue },
          })
        }
        validityChecker={field.validityChecker}
        fieldName={field.data}
      />
    );
    return (
      <div
        key={`d${field.data}`}
        className={"col-sm-12 col-md-6 col-lg-6 col-xl-6 p-1 showBlock rounded"}
      >
        {coreField}
      </div>
    );
  }

  renderFormData() {
    const currentPage =
      this.state.windowDescription.pagesInGeneralEditWizzard[this.props.step];
    const fields = currentPage.fields.map(field => this.renderField(field));
    return (
      <div className="container-fluid">
        <div className="row">{fields}</div>
      </div>
    );
  }
  //#endregion
}
