import PdfWizzardPage from "../../Elements/Wizzards/PdfWizzardPage";
import InputTypesEnum from "../../Enums/InputTypesEnum";
import PurchaseOrderService from "../../Services/PurchaseOrderService";
import ValidityHelper from "../../Helpers/ValidityHelper";
import PurchaseOrderData from "../Communication/PurchaseOrderData";
import LocationInvoicingDataService from "../../Services/LocationInvoicingDataService";
import LocationInvoicingDataSearchParams from "../SearchParams/LocationInvoicingDataSearchParams";

export default class CreatePurchaseOrderWindowDescription {
  static titleForCreate = "Create Purchase Order";
  static serviceFunction = PurchaseOrderService.CreatePurchaseOrder;
  static pdfSummaryServiceFunction =
    PurchaseOrderService.GetDraftPurchaseOrderPdf;
  static wizzardPageType = PdfWizzardPage;
  static itemType = PurchaseOrderData;
  static searchParamsType = LocationInvoicingDataSearchParams;

  static pagesInGeneralEditWizzard = [
    {
      label: "Invoicing Data",
      input: {
        type: InputTypesEnum.Select,
        label: "Invoicing Data",
        fieldName: "LocationId",
        functionOnChange: LocationInvoicingDataService.GetById,
        validityChecker: ValidityHelper.forRequiredSelectField,
        selectValues: "SelectedLocations",
        hide: (item, manager) =>
          item.SelectedItems.length <= 1 ||
          !manager.invoiceDataPerLocation ||
          item.SelectedItems.every(
            (x) => x.LocationId === item.SelectedItems[0].LocationId
          ),
      },
      fields: [
        {
          label: "First Name",
          data: "FirstName",
        },
        {
          label: "Last Name",
          data: "LastName",
        },
        {
          label: "Company Name",
          data: "CompanyName",
        },
        {
          label: "Street",
          data: "Street",
        },
        {
          label: "City",
          data: "City",
        },
        {
          label: "ZIP Code",
          data: "ZipCode",
        },
        {
          label: "State",
          data: "State",
        },
        {
          label: "Country",
          data: "CountryName",
        },
        {
          label: "VAT",
          data: "VatNumber",
        },
        {
          label: "Invoicing Email",
          data: "InvoicingEmail",
        },
        {
          label: "Internal Reference",
          data: "InternalReference",
        },
        {
          label: "PO Number",
          data: "Ponumber",
        },
        {
          label: "PO Contact Name",
          data: "PocontactName",
        },
        {
          label: "PO Contact Email",
          data: "PocontactEmail",
        },
      ],
    },
    {
      label: "Payment Terms",
      fields: [
        {
          label: "Payment Term Days",
          data: "PaymentTermsDays",
        },
        {
          label: "Early Payment Days",
          data: "EarlyPaymentDays",
        },
        {
          label: "Early Payment Discount Percentage",
          data: (item) => `${item.EarlyPaymentDiscountPercentage ?? 0}%`,
        },
      ],
    },
    {
      label: "Purchase Order Preview",
    },
  ];
}
