import React from "react";
import GeneralItemList from "../Elements/CardList/GeneralItemList";
import LocationListItem from "../Domain/LocationListItem";
import LocationSearchParams from "../Contents/SearchParams/LocationSearchParams";
import LocationDetailItem from "../Domain/LocationDetailItem";
import ButtonColorsEnum from "../Enums/ButtonColorsEnum";
import IconPositionEnum from "../Enums/IconPositionEnum";
import ShownPanelsEnum from "../Enums/ShownPanelsEnum";
import FigmaButtonRow from "../Elements/Button/FigmaButtonRow";
import GeneralEditWizzardBase from "../Elements/Wizzards/GeneralEditWizzardBaseFigma";
import WizzardTypeEnum from "../Enums/WizzardTypeEnum";
import SetInvoicingDataForMultipleLocationsWindowDescription from "../Contents/WindowDescriptions/SetInvoicingDataForMultipleLocationsWindowDescription";
import SimpleWizzardPage from "../Elements/Wizzards/SimpleWizzardPage";
import UpdateMultipleLocationInvoicingDataItem from "../Contents/Communication/UpdateMultipleLocationInvoicingDataItem";

export default class LocationsAdmin extends React.Component {
  static defaultProps = {};

  constructor(props) {
    super(props);
    this.SearchParamsType = LocationSearchParams;
    this.state = {
      searchItem: new this.SearchParamsType(),
      selectedItems: [],
      selectAllLevel: 0,
    };
  }

  componentDidMount() {}

  showPanel(panelId) {
    panelId =
      panelId === this.state.shownPanel ? ShownPanelsEnum.None : panelId;
    this.setState({ shownPanel: panelId });
  }

  renderOnePanel = (panelId, content) =>
    this.state.shownPanel === panelId ? content : null;

  refresh = refreshList => {
    if (refreshList) {
      this.setState({ searchItem: { ...this.state.searchItem } });
    }
    this.showPanel(ShownPanelsEnum.None);
  };
  renderPanels() {
    return (
      <>
        {this.renderOnePanel(
          ShownPanelsEnum.SetInvoicingData,
          <GeneralEditWizzardBase
            crudType={WizzardTypeEnum.Create}
            editedItem={
              new UpdateMultipleLocationInvoicingDataItem(
                this.state.searchItem,
                this.state.selectedItems,
                this.state.selectAllLevel === 2
              )
            }
            clickOnCancel={() => this.refresh(false)}
            clickOnSave={resultItem => this.refresh(true)}
            windowDescription={
              SetInvoicingDataForMultipleLocationsWindowDescription
            }
            wizzardPageType={SimpleWizzardPage}
            errorCode="LA66"
          />
        )}
      </>
    );
  }

  renderButtonsAndPanels() {
    return (
      <>
        <FigmaButtonRow
          isOverWindow={true}
          buttons={[
            {
              iconName: "fas fa-file-lines",
              tooltip: "Set Invoicing Data",
              color: ButtonColorsEnum.Primary,
              iconPosition: IconPositionEnum.Center,
              onClick: () => this.showPanel(ShownPanelsEnum.SetInvoicingData),
              disabled: this.state.selectedItems.length === 0,
            },
          ]}
        />
        {this.renderPanels()}
      </>
    );
  }

  render() {
    return (
      <div>
        {this.renderButtonsAndPanels()}
        <GeneralItemList
          itemType={LocationListItem}
          detailsItemType={LocationDetailItem}
          searchItem={this.state.searchItem}
          searchParamsType={this.SearchParamsType}
          onSelectListChange={(selectedItems, selectAllLevel) =>
            this.setState({ selectedItems, selectAllLevel })
          }
          clickOnStartSearch={searchItem => {
            this.setState({
              searchItem: searchItem,
            });
          }}
        />
      </div>
    );
  }
}
