import React from "react";
import Tooltip from "@mui/material/Tooltip";

export const CardDetails = ({ item }) => {
  return (
    <div className="card-text card-details-text">
      {item.constructor.FieldsToShowInList.map((field, index) => {
        if (typeof field.hide == "function" && field.hide(item)) return null;
        return (
          <Tooltip
            title={
              typeof field.label === "function"
                ? field.label(item)
                : field.label
            }
            key={index}
            placement="left-start"
            arrow={true}
          >
            <div key={index} className={field.className}>
              {field.iconName && (
                <i
                  className={
                    typeof field.iconName === "function"
                      ? field.iconName(item)
                      : field.iconName
                  }
                ></i>
              )}
              {typeof field.data === "function"
                ? field.data(item)
                : item[field.data]}
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};
