import AuthService from "./AuthService.js";

export default class PhraseService {
  static GetTranslatedPhraseAsync(phrase, authenticate = true) {
    if (typeof phrase.text !== "string") {
      return new Promise((resolve, reject) => {
        console.log(`Phrase text is not a string`);
        console.log(phrase.text);
        resolve("Phrase text is not a string");
      });
    }
    const phrasejson = JSON.stringify(phrase);
    const phrasejsonUrlencoded = encodeURIComponent(phrasejson);
    const url = `/api/Phrase_GetTranslation/${phrasejsonUrlencoded}`;
    return AuthService.CallBackendFunction(url, authenticate);
  }

  static GetAllTranslatedPhrasesAsync(languageId) {
    const url = `/api/Phrase_GetAllTranslations/${languageId || 1}`;
    return AuthService.CallBackendFunction(url, false);
  }
}
