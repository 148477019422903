import urlHelper from "../../Helpers/urlHelper";
import GrayContainer from "../GrayContainer";
import LoadingFullScreen from "../Loading/LoadingFullScreen";
import MessageComponent from "../MessageComponent";
import GeneralEditWizzardPageBase from "./GeneralEditWizzardPageBaseFigma";
import InputWithClear from "../Input/InputWithClear";
import AuthService from "../../Services/AuthService";

export default class PdfWizzardPage extends GeneralEditWizzardPageBase {
  //common defaultProps are defined in GeneralEditWizzardPageBase
  constructor(props) {
    super(props);

    const loggedInManager = AuthService.getInstance().GetLoggedInManager();

    this.state = {
      ...this.state,
      uniqueId: null,
      loading: false,
      loggedInManager,
    };
  }

  componentDidMount() {
    this.getDataIfInputHidden();
  }

  getDataIfInputHidden() {
    const input =
      this.state.windowDescription.pagesInGeneralEditWizzard[this.props.step]
        .input;
    if (
      input?.hide &&
      input.hide(this.state.editedItem, this.state.loggedInManager) &&
      !this.state.editedItem[input.fieldName]
    ) {
      this.onChange(
        input,
        input.fieldName,
        this.state.editedItem[input.selectValues][0].value
      );
    }
  }

  getPdfData() {
    this.state.windowDescription
      .pdfSummaryServiceFunction(this.props.editedItem)
      .then((response) => {
        if (response.error) {
          MessageComponent.SetErrorMessage(response.error);
          this.setState({ loading: false });
          return;
        }
        if (response.result) {
          this.setState({ uniqueId: response.result, loading: false });
          return;
        }
      })
      .catch((error) => {
        MessageComponent.SetErrorMessage("Error while loading pdf data.");
        this.setState({ loading: false });
      });
  }

  onChange(input, fieldName, newValue) {
    if (!newValue) {
      const newEditedItem = new this.props.windowDescription.itemType(
        this.state.editedItem.SelectedItems,
        this.state.editedItem[input.selectValues]
      );

      this.setState({ editedItem: newEditedItem, loading: false });
      return;
    }

    if (this.state.windowDescription.searchParamsType) {
      newValue = new this.state.windowDescription.searchParamsType(newValue);
    }

    input
      .functionOnChange(newValue)
      .then((response) => {
        const newEditedItem = new this.props.windowDescription.itemType(
          this.state.editedItem.SelectedItems,
          this.state.editedItem[input.selectValues],
          response
        );

        this.setState({ editedItem: newEditedItem, loading: false });
      })
      .catch((error) => {
        MessageComponent.SetErrorMessage("Error while loading data.", error);
        this.setState({ loading: false });
      });

    if (!this.state.loading) {
      this.setState({ loading: true });
    }
  }

  renderInput(input) {
    if (
      input.hide &&
      input.hide(this.state.editedItem, this.state.loggedInManager)
    ) {
      return null;
    }

    return (
      <div className="w-25 m-auto">
        <InputWithClear
          type={input.type}
          value={this.state.editedItem[input.fieldName]}
          labelText={input.label}
          fieldName={input.fieldName}
          onChange={(fieldName, newValue) =>
            this.onChange(input, fieldName, newValue)
          }
          validityChecker={input.validityChecker}
          selectValues={[
            { label: "Select an option...", value: "" },
            ...this.state.editedItem[input.selectValues],
          ]}
        />
      </div>
    );
  }

  renderPdfDataPage(currentPage) {
    if (this.state.loading) {
      return <LoadingFullScreen />;
    }

    return (
      <>
        {currentPage.input ? this.renderInput(currentPage.input) : null}
        <GrayContainer
          displayedItem={this.state.editedItem}
          fieldsToShow={currentPage.fields}
          className="wizzard-page-container"
        />
      </>
    );
  }

  renderPdf() {
    if (this.state.loading && !this.state.uniqueId) {
      this.getPdfData();
    }

    const url = urlHelper.joinUrlParts([
      process.env.REACT_APP_BASE_URL,
      "api/File",
      this.state.uniqueId,
    ]);

    return this.state.uniqueId ? (
      <iframe src={url} className="wizzard-frame" title="Summary" />
    ) : (
      <LoadingFullScreen />
    );
  }

  //#region Override Functions
  componentDidUpdate(prevProps) {
    this.getDataIfInputHidden();
    if (prevProps !== this.props) {
      if (
        this.props.step ===
          this.state.windowDescription.pagesInGeneralEditWizzard.length - 1 &&
        this.state.uniqueId === null
      ) {
        this.setState({ loading: true });
      }

      if (this.props.editedItem !== prevProps.editedItem) {
        this.setState({
          editedItem: this.props.editedItem,
          saving: false,
          uniqueId: null,
        });
        return;
      }

      this.setState({ saving: false });
    }
  }

  validityCheckerForTheEntireForm() {
    const currentPage =
      this.state.windowDescription.pagesInGeneralEditWizzard[this.props.step];

    if (!currentPage.input) return true;

    return !currentPage.input.validityChecker(
      this.state.editedItem[currentPage.input.fieldName],
      this.state.editedItem
    );
  }

  renderFormData() {
    if (
      this.props.step ===
      this.state.windowDescription.pagesInGeneralEditWizzard.length - 1
    ) {
      return this.renderPdf();
    }

    const currentPage =
      this.state.windowDescription.pagesInGeneralEditWizzard[this.props.step];
    return this.renderPdfDataPage(currentPage);
  }
  //#endregion
}
