import JsxHelper from "../Helpers/JsxHelper";
import moneyHelper from "../Helpers/moneyHelper";
import PricingService from "../Services/PricingService";

export default class PricingListItem {
  constructor(item) {
    if (!item) {
      this.ServiceId = 0;
      this.PricingId = 0;

      this.CaspianServiceId = 0;
      this.ServiceDisplayServiceName = "";
      this.CaspianPricingId = 0;
      this.StatusName = "";
      this.CountryName = "";
      this.ClientFee = 0;
      this.ClientFeeCurrencyCode = "";
      this.IsAppliedToAllLocations = false;
      this.ServiceIsMandatory = false;
      return;
    }

    this.ServiceId = item.serviceId;
    this.PricingId = item.pricingId;
    this.CaspianServiceId = item.caspianServiceId;
    this.ServiceDisplayServiceName = item.serviceDisplayServiceName;
    this.CaspianPricingId = item.caspianPricingId;
    this.StatusName = item.statusName;
    this.CountryName = item.countryName;
    this.ClientFee = item.clientFee;
    this.ClientFeeCurrencyCode = item.clientFeeCurrencyCode;
    this.IsAppliedToAllLocations = item.isAppliedToAllLocations;
    this.ServiceIsMandatory = item.serviceIsMandatory;
  }

  static IdField = "CaspianServiceId";
  static TitleField = "ServiceDisplayServiceName";
  static ShowIdInTitle = true;
  static ShowImage = false;
  static ListServiceFunction = PricingService.GetList;
  static DetailsEnabled = false;

  static FieldsToShowInList = [
    {
      label: "Bravo Pricing ID",
      data: "CaspianPricingId",
    },
    {
      label: "Status (Active/Inactive)",
      data: "StatusName",
    },
    {
      label: "Country",
      data: "CountryName",
    },
    {
      label: "Fee",
      data: (item) =>
        moneyHelper.toMoneyFormat(item.ClientFee, item.ClientFeeCurrencyCode),
    },
    {
      label: "Is Mandatory?",
      data: (item) =>
        JsxHelper.YesNoEmpty(item.ServiceIsMandatory, true, false),
    },
    {
      label: "Is Applied To All Locations",
      data: "IsAppliedToAllLocations",
      iconName: (item) =>
        item.IsAppliedToAllLocations ? "fas fa-check" : null,
      className: "text-success",
    },
  ];
}
