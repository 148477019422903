export default class stringHelper {
  static numberWithLetter(number, firstLetterUppercase = false) {
    const numbers = [
      "zero",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
      "ten",
      "eleven",
      "twelve",
    ];
    if (numbers[number]) {
      const result = numbers[number];
      return firstLetterUppercase
        ? stringHelper.firstLetterToUpperCase(result)
        : result;
    }
    return number.toString();
  }

  static firstLetterToUpperCase(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  static plural(
    number,
    word,
    pluralWord,
    firstLetterUppercase = false,
    have = false
  ) {
    let result = `${stringHelper.numberWithLetter(
      number,
      firstLetterUppercase
    )} ${number > 1 ? (pluralWord ? pluralWord : `${word}s`) : word}`;
    if (have) {
      result += ` ${number > 1 ? "have" : "has"}`;
    }
    return result;
  }

  static pluralWithoutValue(
    number,
    word,
    pluralWord,
    have = false
  ) {
    let result = `${
      number > 1 ? (pluralWord ? pluralWord : `${word}s`) : word
    }`;
    if (have) {
      result += ` ${number > 1 ? "have" : "has"}`;
    }
    return result;
  }

  static removeTrailingZeros(value) {
    if (!value || value === 0 || value === "0") return "0";

    const parts = value.toString().split(".");
    const intPart = parseInt(parts[0]);
    if (parts.length === 1) {
      return intPart.toString();
    }
    let decimalPart = parts[1];

    decimalPart = value.toString().split(".")[1].substring(0, 2);

    return `${intPart}.${decimalPart}`;
  }

  static onlyLettersAndNumbers(str) {
    return /^[A-Za-z0-9]*$/.test(str);
  }
}
