import React from "react";
import FaIcon from "../FaIcon";

class LoadingIcon extends React.Component {
  static icons = [
    "fa-solid fa-loader fa-spin",
    "fas fa-spinner fa-spin",
    "fas fa-circle-notch fa-spin",
    "fas fa-sync fa-spin",
    "fas fa-cog fa-spin",
    "fas fa-spinner fa-pulse",
    "fas fa-stroopwafel fa-spin",
    "fas fa-adjust fa-spin",
    "fas fa-angry fa-spin",
    "fas fa-surprise fa-spin",
    "fas fa-smile-wink fa-spin",
    "fas fa-smile-beam fa-spin",
    "fas fa-meh-rolling-eyes fa-spin",
    "fas fa-grin-squint-tears fa-spin",
    "fas fa-grin-hearts fa-spin",
    "fas fa-grin-tongue-wink fa-spin",
    "fas fa-grimace fa-spin",
    "fas fa-peace fa-spin",
    "fab fa-bitcoin fa-spin",
    "fas fa-certificate fa-spin",
    "fas fa-asterisk fa-spin",
    "fas fa-atom fa-spin",
    "fas fa-bahai fa-spin",
    "fas fa-compact-disc fa-spin",
    "fas fa-compass fa-spin",
    "fas fa-crosshairs fa-spin",
    "fas fa-dharmachakra fa-spin",
    "fas fa-fan fa-spin",
    "fas fa-life-ring fa-spin",
    "far fa-life-ring fa-spin",
    "fas fa-palette fa-spin",
    "fas fa-ring fa-spin",
    "fas fa-slash fa-spin",
    "fas fa-snowflake fa-spin",
    "fas fa-sun fa-spin",
    "fas fa-yin-yang fa-spin",
    "fas fa-ban fa-spin",
    "fas fa-bomb fa-spin",
    "fab fa-battle-net fa-spin",
    "fas fa-bone fa-spin",
    "fas fa-bowling-ball fa-spin",
    "fas fa-cannabis fa-spin",
    "fab fa-centercode fa-spin",
    "fab fa-centos fa-spin",
    "fas fa-clock fa-spin",
  ];

  static defaultProps = {
    loading: true,
    className: "h1",
  };

  constructor(props) {
    super(props);
    this.state = {
      randomNumber: Math.floor(Math.random() * LoadingIcon.icons.length),
    };
  }

  render() {
    if (!this.props.loading) return null;
    return (
      <div
        className={"text-center w-100 " + this.props.className}
        onClick={() =>
          this.setState({
            randomNumber: Math.floor(Math.random() * LoadingIcon.icons.length),
          })
        }
      >
        <FaIcon iconName={LoadingIcon.icons[this.state.randomNumber]} />
      </div>
    );
  }
}

export default LoadingIcon;
