import UIError from "../Domain/UIError";
import AuthService from "./AuthService";

export default class ErrorService {
  static LogUIError(error) {
    const errorData = new UIError(error);
    return AuthService.CallBackendFunctionPost(
      "/api/Error/LogUIErrorAsync",
      errorData,
      false
    );
  }
}
