import moneyHelper from "../Helpers/moneyHelper";
import PurchaseOrderLineService from "../Services/PurchaseOrderLineService";

export default class PurchaseOrderLineForLocationListItem {
  constructor(item) {
    if (!item) {
      this.PurchaseOrderLineId = 0;
      this.PricingServiceDisplayServiceName = "";
      this.NumberOfFieldworkPeriod = 0;
      this.PricingClientFee = 0;
      this.PricingClientFeeCurrencyCode = "";
      this.PricingServiceOwningOffice = "";
      this.PricingServiceOwningOfficeId = 0;
      this.Status = "";
      this.LocationId = 0;
      return;
    }

    this.PurchaseOrderLineId = item.purchaseOrderLineId;
    this.PricingServiceDisplayServiceName =
      item.pricingServiceDisplayServiceName;
    this.NumberOfFieldworkPeriod = item.numberOfFieldworkPeriod;
    this.PricingClientFee = item.pricingClientFee;
    this.PricingClientFeeCurrencyCode = item.pricingClientFeeCurrencyCode;
    this.PricingServiceOwningOffice = item.pricingServiceOwningOffice;
    this.PricingServiceOwningOfficeId = item.pricingServiceOwningOfficeId;
    this.Status = item.status;
    this.LocationId = item.locationId;
  }

  static IdField = "PurchaseOrderLineId";
  static TitleField = "PricingServiceDisplayServiceName";
  static ShowIdInTitle = true;
  static ListServiceFunction = PurchaseOrderLineService.GetList;
  static ShowImage = false;
  static DetailsEnabled = false;

  static FieldsToShowInList = [
    {
      label: "Owning Office",
      data: "PricingServiceOwningOffice",
    },
    {
      label: "Fieldwork Periods",
      data: "NumberOfFieldworkPeriod",
    },
    {
      label: "Fee Per Fieldwork Period",
      data: (item) =>
        moneyHelper.toMoneyFormat(
          item.PricingClientFee,
          item.PricingClientFeeCurrencyCode
        ),
    },
    {
      label: "Total Fee",
      data: (item) =>
        moneyHelper.toMoneyFormat(
          item.NumberOfFieldworkPeriod * item.PricingClientFee,
          item.PricingClientFeeCurrencyCode
        ),
    },
    {
      label: (item) =>
        item.Status === "Created" ? "Status: Open" : "Status: Linked to PO",
      iconName: (item) =>
        item.Status === "Created"
          ? "fa-kit fa-sharp-solid-file-invoice-dollar-circle-xmark color-danger fa-lg"
          : "fa-kit fa-sharp-solid-file-invoice-dollar-circle-check color-success fa-lg",
    },
  ];
}
