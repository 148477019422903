import React from "react";
import axios from "axios";

class AuthService extends React.Component {
  static myInstance = null;
  static getInstance() {
    if (AuthService.myInstance == null) {
      AuthService.myInstance = new AuthService();
    }

    return AuthService.myInstance;
  }

  AuthenticatedApi(authenticate = true) {
    const secret = localStorage.getItem("secret");
    if (!secret && authenticate) {
      throw Error("No secret found");
    }
    const loggedInManager = this.GetLoggedInManager();
    const clientManagerId = loggedInManager
      ? loggedInManager.clientManagerId
      : "-1";
    return axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Authorization: authenticate
          ? `Bearer ${secret}:${clientManagerId}`
          : "",
        "Content-Type": "application/json",
      },
    });
  }

  async LoginWithCredentials(credentials) {
    credentials.uniqueDeviceId = this.GetUniqueDeviceId();
    const url = "/api/Account_Login/";
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        "Content-Type": "application/json",
      },
    });
    const response = await axiosInstance.post(url, credentials);
    this.SetMfaChecked(response.data.mfaChecked);

    if (
      !response.data.userManagers ||
      response.data.userManagers.length === 0
    ) {
      return;
    }
    localStorage.setItem("secret", response.data.secret);
    this.setUserManagers(response.data.userManagers);
    if (response.data.userManagers.length === 1) {
      this.SetLoggedInManager(response.data.userManagers[0]);
    } else {
      this.SetLoggedInManager(null);
    }
  }

  //test  comment 1- remove it later
  //test  comment 2- remove it later
  async LoginWithEmailCode(path) {
    try {
      const parts = path.split("/");
      if (parts.length < 3) return false;
      if (parts[1] !== "reset-password") return false;
      const code = parts[2];
      const url = "/api/Account_LoginWithEmailCode/";
      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
          "Content-Type": "application/json",
        },
      });
      const response = await axiosInstance.post(url, {
        code,
        uniqueDeviceId: this.GetUniqueDeviceId(),
      });
      console.log(response.data);
      this.SetMfaChecked(response.data.mfaChecked);

      if (
        !response.data.userManagers ||
        response.data.userManagers.length === 0
      ) {
        this.deleteSecret();
        return false;
      }
      localStorage.setItem("secret", response.data.secret);
      this.setUserManagers(response.data.userManagers);
      if (response.data.userManagers.length === 1) {
        this.SetLoggedInManager(response.data.userManagers[0]);
      } else {
        this.SetLoggedInManager(null);
      }
    } catch (error) {
      this.deleteSecret();
      window.history.pushState({}, "", "/");
      window.location.reload();
      return false;
    }
    window.history.pushState({}, "", "/");
    return true;
  }

  async LoginWithSecret() {
    try {
      const url = "/api/Account_LoginWithSecret/";
      const response = await AuthService.CallBackendFunctionPost(url, {
        uniqueDeviceId: this.GetUniqueDeviceId(),
      });
      this.SetMfaChecked(response.mfaChecked);
      if (response.userManagers.length > 0) {
        this.setUserManagers(response.userManagers);
        if (response.userManagers.length === 1) {
          this.SetLoggedInManager(response.userManagers[0]);
        }
      }
    } catch (error) {
      this.deleteSecret();
    }
  }

  async CheckPin(pin, rememberMe) {
    try {
      const url = "/api/Account_CheckPin/";
      const response = await AuthService.CallBackendFunctionPost(url, {
        pin,
        rememberMe,
        uniqueDeviceId: this.GetUniqueDeviceId(),
      });
      this.SetMfaChecked(response.mfaChecked);
      if (response.userManagers.length > 0) {
        this.setUserManagers(response.userManagers);
        if (response.userManagers.length === 1) {
          this.SetLoggedInManager(response.userManagers[0]);
        }
      }
    } catch (error) {
      console.log(error);
      this.deleteSecret();
    }
  }

  async SubmitEmailForNewPassword(email) {
    const url = "/api/Account_SubmitEmailForNewPassword/";
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        "Content-Type": "application/json",
      },
    });
    await axiosInstance.post(url, { email });
  }

  async SubmitNewPassword(password) {
    const url = "/api/Account_SubmitNewPassword/";
    const response = await AuthService.CallBackendFunctionPost(url, {
      password,
    });
    console.log(response);
    this.deleteSecret();
  }

  async SendDeleteEmail() {
    const url = "/api/Account_SendDeleteAccountEmail/";
    return AuthService.CallBackendFunctionPost(url, {});
  }

  deleteSecret() {
    localStorage.setItem("secret", "");
  }

  async Logout() {
    try {
      await AuthService.CallBackendFunctionPost("/api/Account_Logout/", {});
    } catch (error) {}
    this.deleteSecret();
  }

  GetLoggedInManager() {
    return JSON.parse(localStorage.getItem("loggedInManager"));
  }

  SetLoggedInManager(manager) {
    localStorage.setItem("loggedInManager", JSON.stringify(manager));
  }

  GetUniqueDeviceId() {
    if (!localStorage.getItem("uniqueDeviceId")) {
      localStorage.setItem(
        "uniqueDeviceId",
        Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15)
      );
    }
    return localStorage.getItem("uniqueDeviceId");
  }

  GetMfaChecked() {
    return this.mfaChecked;
  }

  SetMfaChecked(mfaChecked) {
    this.mfaChecked = mfaChecked;
  }

  GetUserManagers() {
    return this.userManagers;
  }

  setUserManagers(userManagers) {
    this.userManagers = userManagers;
  }

  static HasRole(role) {
    const roles = AuthService.getInstance().GetLoggedInUser().roles;
    return roles.includes("Admin") || roles.includes(role);
  }

  GetApiUrl() {
    return process.env.REACT_APP_BASE_URL;
  }

  static CallBackendFunction(functionAddress, authenticate = true) {
    console.log(`CallBackendFunction: Web api request to ${functionAddress}`);
    const authService = AuthService.getInstance();
    return authService
      .AuthenticatedApi(authenticate)
      .get(functionAddress)
      .then((response) => response.data)
      .catch((error) => {
        console.log(error);
        if (error !== undefined && error.response !== undefined) {
          if (error.response.status === 401) {
            authService.Logout();
            window.location.reload(true);
            return;
          } else if (error.response.data) {
            throw Error(error.response.data);
          }
        }
        throw Error(error);
      });
  }

  static CallBackendFunctionGetFile(functionAddress) {
    const authService = AuthService.getInstance();
    return authService
      .AuthenticatedApi()
      .get(functionAddress, {
        responseType: "blob",
      })
      .then((response) => response.data)
      .catch((error) => {
        console.log(error);
        if (error !== undefined && error.response !== undefined) {
          if (error.response.status === 401) {
            authService.Logout();
            window.location.reload(true);
            return;
          } else if (error.response.data) {
            throw Error(error.response.data);
          }
        }
        throw Error(error);
      });
  }

  static CallBackendFunctionPost(functionAddress, data, authenticate = true) {
    const dataJson = JSON.stringify(data);
    console.log(
      `CallBackendFunctionPost: Web api request to ${functionAddress} with data ${dataJson}`
    );
    const authService = AuthService.getInstance();
    return authService
      .AuthenticatedApi(authenticate)
      .post(functionAddress, dataJson)
      .then((response) => response.data)
      .catch((error) => {
        console.log(error);
        if (error !== undefined && error.response !== undefined) {
          if (error.response.status === 401) {
            authService.Logout();
            window.location.reload(true);
            return;
          } else if (error.response.data) {
            throw Error(error.response.data);
          }
        }
        throw Error(error);
      });
  }

  static CallBackendFunctionPostFile(functionAddress, data) {
    const config = {
      headers: {
        "content-type": "multipart/form-data;",
      },
    };

    const authService = AuthService.getInstance();
    return authService
      .AuthenticatedApi()
      .post(functionAddress, data, config)
      .then((response) => response.data)
      .catch((error) => {
        console.log(error);
        if (error !== undefined && error.response !== undefined) {
          if (error.response.status === 401) {
            authService.Logout();
            window.location.reload(true);
            return;
          } else if (error.response.data) {
            throw Error(error.response.data);
          }
        }
        throw Error(error);
      });
  }

  static CallBackendFunctionPut(functionAddress, data) {
    const dataJson = JSON.stringify(data);
    const authService = AuthService.getInstance();
    return authService
      .AuthenticatedApi()
      .put(functionAddress, dataJson)
      .then((response) => {
        console.log(`Web api response to ${functionAddress}:`);
        console.log(response);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        if (error !== undefined && error.response !== undefined) {
          if (error.response.status === 401) {
            authService.Logout();
            window.location.reload(true);
            return;
          } else if (error.response.data) {
            throw Error(error.response.data);
          }
        }
        throw Error(error);
      });
  }

  static CallBackendFunctionDelete(functionAddress) {
    const authService = AuthService.getInstance();
    return authService
      .AuthenticatedApi()
      .delete(functionAddress)
      .then((response) => response.data)
      .catch((error) => {
        console.log(error);
        if (error !== undefined && error.response !== undefined) {
          if (error.response.status === 401) {
            authService.Logout();
            window.location.reload(true);
            return;
          } else if (error.response.data) {
            throw Error(error.response.data);
          }
        }
        throw Error(error);
      });
  }
}
export default AuthService;
