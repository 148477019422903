import React from "react";
import HomePage from "../../Pages/HomePage";
import { ReactComponent as BareIcon } from "./bare-international-logo.svg";
import { MainRefContext } from "../../MainRefContext";

export default class CxLogoLink extends React.Component {
  static defaultProps = {
    className: "",
  };

  static contextType = MainRefContext;

  onClick = () => {
    if (this.context.current) {
      this.context.current.setState({
        menuId: null,
        menuLink: <HomePage />,
        menuTitle: "Home",
        menuIcon: "fa fa-house",
        menuUrl: "home",
      });
    }
  };

  render() {
    return (
      <div
        className={"bare-logo cursor-pointer " + this.props.className}
        onClick={this.onClick}
      >
        <BareIcon />
        {/*
        <img
          src="/img/conciergex-logo.png"
          alt="Bare International"
          style={{ width: "60px", height: "60px" }}
        />
        */}
      </div>
    );
  }
}
