import ServiceHelper from "../Helpers/ServiceHelper.js";
import AuthService from "./AuthService.js";

export default class BareManagerService {
  static GetList(
    orderBy,
    orderDir,
    pageNumber,
    numberOfElementsOnAPage,
    searchItem
  ) {
    const loggedInManager = AuthService.getInstance().GetLoggedInManager();
    const searchParamsString = ServiceHelper.GetSearchParamsString(
      orderBy,
      orderDir,
      pageNumber,
      numberOfElementsOnAPage,
      { CxClientId: loggedInManager.cxClientId }
    );

    return AuthService.CallBackendFunction(
      `/api/BareManager_GetList/${searchParamsString}`
    );
  }
}
