import React from "react";
import GrayContainer from "../Elements/GrayContainer";
import LoggedInManager from "../Domain/LoggedInManager";

export default class ProfilePage extends React.Component {
  static defaultProps = {
    loggedInManager: null,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    return (
      <div className="profile-page">
        <GrayContainer
          displayedItem={this.props.loggedInManager}
          type={LoggedInManager}
        />
      </div>
    );
  }
}
