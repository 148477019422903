import React from "react";
import FaIcon from "../FaIcon";

export const CardTitle = ({ item, selected }) => {
  return (
    <h5 className="card-title card-title-text">
      {item[item.constructor.TitleField]}{" "}
      {item.constructor.ShowIdInTitle
        ? `(${item[item.constructor.IdField]}) `
        : null}
      {selected && <FaIcon iconName={"fa-solid fa-check"} />}
    </h5>
  );
};
