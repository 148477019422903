import React from "react";
import GeneralItemList from "../Elements/CardList/GeneralItemList";
import FigmaButtonRow from "../Elements/Button/FigmaButtonRow";
import ButtonColorsEnum from "../Enums/ButtonColorsEnum";
import IconPositionEnum from "../Enums/IconPositionEnum";
import ShownPanelsEnum from "../Enums/ShownPanelsEnum";
import GeneralEditWizzardBase from "../Elements/Wizzards/GeneralEditWizzardBaseFigma";
import WizzardTypeEnum from "../Enums/WizzardTypeEnum";
import PurchaseOrderData from "../Contents/Communication/PurchaseOrderData";
import PurchaseOrderLineSearchParams from "../Contents/SearchParams/PurchaseOrderLineSearchParams";
import PurchaseOrderLineItem from "../Domain/PurchaseOrderLineListItem";
import CreatePurchaseOrderWindowDescription from "../Contents/WindowDescriptions/CreatePurchaseOrderWindowDescription";
import MessageComponent from "../Elements/MessageComponent";
import PurchaseOrderLineService from "../Services/PurchaseOrderLineService";
import LoadingFullScreen from "../Elements/Loading/LoadingFullScreen";
import PurchaseOrderService from "../Services/PurchaseOrderService";
import urlHelper from "../Helpers/urlHelper";

export default class PurchaseOrderLinesAdmin extends React.Component {
  static defaultProps = {
    detailsItem: null,
    itemType: PurchaseOrderLineItem,
  };

  constructor(props) {
    super(props);
    this.SearchParamsType = PurchaseOrderLineSearchParams;
    this.state = {
      searchItem: new this.SearchParamsType(),
      shownPanel: ShownPanelsEnum.None,
      itemList: [],
      selectList: [],
      loading: false,
      remainingItems: null,
    };

    if (this.props.detailsItem?.LocationId) {
      this.state.searchItem.LocationId = this.props.detailsItem.LocationId;
      this.state.searchItem.Status = "";
    }

    if (this.props.detailsItem?.PurchaseOrderId) {
      this.state.searchItem.PurchaseOrderId =
        this.props.detailsItem.PurchaseOrderId;
      this.state.searchItem.Status = "Linked";
    }

    this.refresh = this.refresh.bind(this);
    this.showPanel = this.showPanel.bind(this);
    this.onDeleteButtonClick = this.onDeleteButtonClick.bind(this);
    this.onPurchaseOrderCreateBase = this.onPurchaseOrderCreateBase.bind(this);
    this.onDeleteButtonClickFunction =
      this.onDeleteButtonClickFunction.bind(this);
  }

  //#region Event Handlers
  onOpenPdfButtonClick() {
    PurchaseOrderService.GetPurchaseOrderPdf(
      this.state.searchItem.PurchaseOrderId
    )
      .then((result) => {
        const url = urlHelper.joinUrlParts([
          process.env.REACT_APP_BASE_URL,
          "api/File",
          result.result,
        ]);
        window.open(url, "_blank");
      })
      .catch((error) => {
        MessageComponent.SetErrorMessage("POLA69", error);
      });
  }

  onDeleteButtonClick() {
    MessageComponent.SetApprove(
      "Are you sure you want to delete these order lines?",
      this.onDeleteButtonClickFunction
    );
  }

  onDeleteButtonClickFunction() {
    PurchaseOrderLineService.RemoveMultiple(this.state.selectList)
      .then((result) => {
        if (result.error) {
          MessageComponent.SetErrorMessage(result.error);
        }
        MessageComponent.SetNotification(
          "Purchase Order(s) removed successfully."
        );
        this.refresh(true);
      })
      .catch((error) => {
        MessageComponent.SetErrorMessage("POLA92", error);
        this.refresh(false);
      });
    this.setState({ loading: true });
  }

  onPurchaseOrderCreateBase(currentItems, showMessage = true) {
    const orderLines = currentItems.filter(
      (x) =>
        x.PricingServiceOwningOfficeId ===
        currentItems[0].PricingServiceOwningOfficeId
    );

    const remainingItems = currentItems.filter(
      (x) =>
        x.PricingServiceOwningOfficeId !==
        currentItems[0].PricingServiceOwningOfficeId
    );

    const uniqueLocations = orderLines
      .filter(
        (value, index, array) =>
          array.map((x) => x.LocationId).indexOf(value.LocationId) === index
      )
      .map((x) => {
        return { value: x.LocationId, label: x.LocationClientLocationName };
      });

    const purchaseOrder = new PurchaseOrderData(orderLines, uniqueLocations);

    if (showMessage && remainingItems.length > 0) {
      MessageComponent.SetWarning(
        "The selected services belong to multiple BARE offices, so we need to create multiple purchase orders."
      );
    }

    this.setState({
      editedItem: purchaseOrder,
      remainingItems: remainingItems.length > 0 ? remainingItems : null,
      shownPanel: ShownPanelsEnum.CreatePurchaseOrder,
    });
  }
  //#endregion

  refresh(refresh) {
    if (refresh) {
      this.setState({
        shownPanel: ShownPanelsEnum.None,
        searchItem: { ...this.state.searchItem },
        loading: false,
      });
    } else this.setState({ shownPanel: ShownPanelsEnum.None, loading: false });
  }

  renderOnePanel = (panelId, content) =>
    this.state.shownPanel === panelId ? content : null;

  showPanel(panelId) {
    panelId =
      panelId === this.state.shownPanel ? ShownPanelsEnum.None : panelId;
    this.setState({ shownPanel: panelId });
  }

  //#region Render
  renderPanels() {
    return (
      <>
        {
          //This wizzard also exists in PricingsAdmin,
          //if changes occur here, they most likely need to be changed there too
          this.renderOnePanel(
            ShownPanelsEnum.CreatePurchaseOrder,
            <GeneralEditWizzardBase
              crudType={WizzardTypeEnum.Create}
              editedItem={this.state.editedItem}
              clickOnCancel={() =>
                this.refresh(this.state.remainingItems?.length > 0)
              }
              clickOnSave={() =>
                this.state.remainingItems?.length > 0
                  ? this.onPurchaseOrderCreateBase(
                      this.state.remainingItems,
                      false
                    )
                  : this.refresh(true)
              }
              windowDescription={CreatePurchaseOrderWindowDescription}
              errorCode="POLA173"
              followUpMessage={
                this.state.remainingItems?.length > 0
                  ? "Would you like to create the Purchase Order for the next office?"
                  : null
              }
            />
          )
        }
      </>
    );
  }

  renderButtonsAndPanels() {
    if (this.state.searchItem.Status === "Linked")
      return (
        <>
          <FigmaButtonRow
            isOverWindow={true}
            buttons={[
              {
                iconName: "fa-regular fa-file-pdf",
                tooltip: "Open PDF",
                color: ButtonColorsEnum.Primary,
                iconPosition: IconPositionEnum.Center,
                onClick: () => this.onOpenPdfButtonClick(),
              },
            ]}
          />
          {this.renderPanels()}
        </>
      );

    return (
      <>
        <FigmaButtonRow
          isOverWindow={true}
          buttons={[
            {
              iconName: "fas fa-trash",
              tooltip: "Delete Purchase Order Line(s)",
              color: ButtonColorsEnum.Primary,
              iconPosition: IconPositionEnum.Center,
              onClick: () => this.onDeleteButtonClick(),
              disabled: !this.state.selectList.length > 0,
            },
            {
              iconName: "fas fa-basket-shopping",
              tooltip: "Create Purchase Order",
              color: ButtonColorsEnum.Primary,
              iconPosition: IconPositionEnum.Center,
              onClick: () =>
                this.onPurchaseOrderCreateBase(
                  this.state.itemList.filter((x) =>
                    this.state.selectList.includes(x.PurchaseOrderLineId)
                  )
                ),
              disabled: !this.state.selectList.length > 0,
            },
          ]}
        />
        {this.renderPanels()}
      </>
    );
  }

  render() {
    if (this.state.loading === true) return <LoadingFullScreen />;
    return (
      <>
        {this.renderButtonsAndPanels()}
        <GeneralItemList
          itemType={this.props.itemType}
          searchItem={this.state.searchItem}
          searchParamsType={this.SearchParamsType}
          clickOnStartSearch={(searchItem) =>
            this.setState({
              searchItem: {
                ...searchItem,
                LocationId: this.state.searchItem.LocationId,
                Status: this.state.searchItem.Status,
                PurchaseOrderId: this.state.searchItem.PurchaseOrderId,
              },
            })
          }
          onItemListChange={(newItemList) =>
            this.setState({ itemList: newItemList })
          }
          onSelectListChange={(newItemList) =>
            this.setState({ selectList: newItemList })
          }
        />
      </>
    );
  }
  //#endregion
}
