import React from "react";
import AuthService from "../Services/AuthService";
import TabbedPage from "../Elements/TabbedPage";
import ChangePassword from "./ChangePassword";
import ProfilePage from "./ProfilePage";
import DeleteProfile from "./DeleteProfile";

export default class ProfileParentPage extends React.Component {
  static defaultProps = {};

  constructor(props) {
    super(props);

    const loggedInManager = AuthService.getInstance().GetLoggedInManager();
    this.state = {
      loggedInManager,
    };
  }

  static getHeaderText() {
    const user = AuthService.getInstance().GetLoggedInManager();
    return user
      ? `${user.cxClientDisplayName} - ${user.firstName} ${user.lastName}`
      : "";
  }

  render() {
    return (
      <TabbedPage
        tabs={[
          {
            icon: "fas fa-user",
            label: "User",
            component: (
              <ProfilePage loggedInManager={this.state.loggedInManager} />
            ),
          },
          {
            icon: "fas fa-key",
            label: "Change Password",
            component: <ChangePassword />,
          },
          {
            icon: "fas fa-trash",
            label: "Delete Profile",
            component: <DeleteProfile />,
          },
        ]}
      />
    );
  }
}
