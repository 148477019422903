import TranslationComponent from "../Elements/TranslationComponent";

export default class selectHelper {
  static generateSelectValues(base) {
    const result = [];
    base.forEach((b) => result.push({ label: b, value: b, disabled: false }));
    return result;
  }

  static generateOptions(optionValues) {
    const options = [];
    optionValues.forEach(o =>
      options.push(
        <option value={o.value} disabled={o.disabled} key={`key${o.value}`}>
          {o.label}{" "}
          <TranslationComponent phrase="ELEMENTS/PAGE" loadingEnabled={false} />
        </option>
      )
    );
    return options;
  }
}
