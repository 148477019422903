export default class LoggedInManager {
  constructor(loggedInManager) {
    if (loggedInManager) {
      this.ClientManagerId = loggedInManager.clientManagerId;
      this.ClientManagerThirdPartyId =
        loggedInManager.clientManagerThirdPartyId;
      this.ClientDomain = loggedInManager.clientDomain;
      this.FirstName = loggedInManager.firstName;
      this.LastName = loggedInManager.lastName;
      this.Email = loggedInManager.email;
      this.RoleDescriptions = loggedInManager.roleDescriptions.join(", ");
      return;
    }
    this.ClientManagerId = "";
    this.ClientManagerThirdPartyId = "";
    this.ClientDomain = "";
    this.FirstName = "";
    this.LastName = "";
    this.Email = "";
    this.RoleDescriptions = "";
  }

  static fieldsToShow = [
    { label: "Concierge Manager ID", data: "ClientManagerId" },
    { label: "Third Party Manager ID", data: "ClientManagerThirdPartyId" },
    {
      label: "Client Domain",
      data: "ClientDomain",
      type: "longText",
    },
    { label: "First Name", data: "FirstName" },
    { label: "Last Name", data: "LastName" },
    { label: "Email", data: "Email" },
    { label: "Roles", data: "RoleDescriptions" },
  ];
}
