import ServiceHelper from "../Helpers/ServiceHelper";
import AuthService from "./AuthService";

export default class PurchaseOrderService {
  static CreatePurchaseOrder(editItem) {
    return AuthService.CallBackendFunctionPost(
      "/api/Service_CreatePurchaseOrder/",
      editItem
    );
  }

  static GetDraftPurchaseOrderPdf(editItem) {
    return AuthService.CallBackendFunctionPost(
      "/api/Service_CreateDraftPurchaseOrderPdf/",
      editItem
    );
  }

  static GetPurchaseOrderList(
    orderBy,
    orderDir,
    pageNumber,
    numberOfElementsOnAPage,
    searchItem
  ) {
    const searchParamsString = ServiceHelper.GetSearchParamsString(
      orderBy,
      orderDir,
      pageNumber,
      numberOfElementsOnAPage,
      searchItem
    );

    return AuthService.CallBackendFunction(
      `/api/Service_GetPurchaseOrderList/${searchParamsString}`
    );
  }

  static GetById(id) {
    return AuthService.CallBackendFunction(
      `/api/Service_GetPurchaseOrderById/${id}`
    );
  }

  static GetPurchaseOrderPdf(id) {
    return AuthService.CallBackendFunctionPost(
      `/api/Service_CreatePurchaseOrderPdf/${id ?? 0}`
    );
  }
}
