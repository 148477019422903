import React from "react";
import FaIcon from "../Elements/FaIcon";
import stringHelper from "./stringHelper";

export class dateTimeHelper {
  static getFormattedDate(date, separator) {
    const realMonth = date.getMonth() + 1;
    const month =
      realMonth.toString().length === 1 ? `0${realMonth}` : realMonth;
    const day =
      date.getDate().toString().length === 1
        ? `0${date.getDate()}`
        : date.getDate();

    return date.getFullYear() + separator + month + separator + day;
  }

  static addDaysToDate(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  static addHoursToDate(date, hours) {
    const result = new Date(date);
    result.setHours(result.getHours() + hours);
    return result;
  }

  static addMinutesToDate(date, minutes) {
    const result = new Date(date);
    result.setMinutes(result.getMinutes() + minutes);
    return result;
  }

  static addMonthsToDate(date, months) {
    months = parseInt(months);
    const result = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result;
  }

  static getMondayOfISOWeek(w, y) {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    if (dow <= 4) simple.setDate(simple.getDate() - simple.getDay() + 1);
    else simple.setDate(simple.getDate() + 8 - simple.getDay());
    return simple;
  }

  static getWeekDay(date) {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const d = this.getDateFromDateString(date);

    return days[d.getDay()];
  }

  static convertTimeToMinutes(timeToConvert) {
    const splitTime = timeToConvert.split(":");

    return Number(splitTime[0] * 60) + Number(splitTime[1]);
  }

  static convertMinutesToDate(totalSeconds) {
    const seconds = Number(totalSeconds);
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor((seconds % (3600 * 24)) / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);

    const dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days ") : "";
    const hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours ") : "";
    const mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes ") : "";
    const sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
  }

  static convertMinutesToTime(minutesToConvert) {
    for (; minutesToConvert < 0; ) {
      minutesToConvert += 24 * 60;
    }

    for (; minutesToConvert > 24 * 60 - 1; ) {
      minutesToConvert -= 24 * 60;
    }

    let hours = String(
      (Number(minutesToConvert) - (Number(minutesToConvert) % 60)) / 60
    );
    let minutes = String(Number(minutesToConvert) % 60);

    if (hours.length === 1) {
      hours = `0${hours}`;
    }

    if (minutes.length === 1) {
      minutes = `0${minutes}`;
    }

    return `${hours}:${minutes}`;
  }

  static convertMinutesToTimeHM(minutesValue) {
    const hours = String(
      (Number(minutesValue) - (Number(minutesValue) % 60)) / 60
    );
    const minutes = String(Number(minutesValue) % 60);

    return `${hours}H ${minutes > 0 ? `${minutes}M` : ""}`;
  }

  static getWeekOfYear(date) {
    const dt = this.getDateFromDateString(date);
    const tdt = new Date(dt.valueOf());
    const dayn = (dt.getDay() + 6) % 7;
    tdt.setDate(tdt.getDate() - dayn + 3);
    const firstThursday = tdt.valueOf();
    tdt.setMonth(0, 1);

    if (tdt.getDay() !== 4) {
      tdt.setMonth(0, 1 + ((4 - tdt.getDay() + 7) % 7));
    }

    let res = 1 + Math.ceil((firstThursday - tdt) / 604800000);
    if (
      date.toString().includes("Chile Standard Time") ||
      date.toString().includes("hora estándar de Chile")
    )
      res--;

    return res;
  }

  static getFormattedDateTime(date) {
    return `${date.getFullYear()}.${date.getMonth()}.${date.getDay()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  }

  static getUSFormattedDate(date) {
    date = this.getDateFromDateString(date);
    const month = date.toLocaleString("default", { month: "short" });
    const day =
      date.getDate().toString().length === 1
        ? `0${date.getDate()}`
        : date.getDate();
    const year = date.getFullYear();

    return `${month} ${day}, ${year}`;
  }

  static convertToUtc(date) {
    if (typeof date.getMonth !== "function") date = new Date(date);
    const now_utc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );

    return new Date(now_utc);
  }

  static getDateFromDateString(dateString) {
    return new Date(
      `${dateString
        .toString()
        .replace(".", "-")
        .replace(".", "-")
        .replace(".", "")} (UTC)`
    );
  }

  static getDateFromDateStringForFF(dateString) {
    return new Date(
      Date.parse(
        dateString
          .toString()
          .replace(".", "-")
          .replace(".", "-")
          .replace(".", "")
      )
    );
  }

  static isDate(date) {
    return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
  }

  static isItAValidTime(time) {
    if (typeof time.getMonth === "function") return true;
    if (typeof time === "object") return false;
    if (time.length !== 5) return false;
    if (!this.checkIsACharANumber(time.charAt(0))) return false;
    if (!this.checkIsACharANumber(time.charAt(1))) return false;
    if (time.charAt(2) !== ":") return false;
    if (!this.checkIsACharANumber(time.charAt(3))) return false;
    if (!this.checkIsACharANumber(time.charAt(4))) return false;
    if (this.getHoursFromTimeString(time) > 23) return false;
    if (this.getMinutesFromTimeString(time) > 59) return false;
    return true;
  }

  static checkIsACharANumber(char) {
    if (char.length > 1) return false;
    return char >= "0" && char <= "9";
  }

  static getHoursFromTimeString(time) {
    return time.substring(0, 2);
  }

  static getMinutesFromTimeString(time) {
    return time.slice(-2);
  }

  static toYYYYsMMsDDWithIndefinite(date, separator) {
    if (date === null) return "";
    if (dateTimeHelper.isIndefinite(date)) {
      return <>&#8734;</>;
    }
    return dateTimeHelper.toYYYYsMMsDD(date, separator);
  }

  static toYYYYsMMsDDWithIndefiniteIcon(date, separator) {
    if (dateTimeHelper.isIndefinite(date)) {
      return <FaIcon iconName="fas fa-infinity" innerClassName="text-green" />;
    }
    return dateTimeHelper.toYYYYsMMsDD(date, separator);
  }

  static toYYYYsMMsWithIndefiniteIcon(date, separator) {
    if (dateTimeHelper.isIndefinite(date)) {
      return <FaIcon iconName="fas fa-infinity" innerClassName="text-green" />;
    }
    return dateTimeHelper.toYYYYsMMs(date, separator);
  }

  static toYYYYsMMsDD(date, separator = "/") {
    if (!date) return "";
    separator = separator ? separator : "-";
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join(separator);
  }

  static toMMsDD(date, separator = "-") {
    separator = separator ? separator : "-";
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [month, day].join(separator);
  }

  static toYYYYsMM(date, separator = "-") {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;

    return [year, month].join(separator);
  }

  static toYYYYsMMs(date, separator = "-") {
    separator = separator ? separator : "-";
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;

    return [year, month].join(separator);
  }

  static toYYYYsMMsDDsHHsMM(date, separator = "-") {
    if (!date) return "";
    separator = separator ? separator : "-";
    const d = new Date(date);
    let month = (d.getMonth() + 1).toString();
    let day = d.getDate().toString();
    const year = d.getFullYear();
    let hours = d.getHours().toString();
    let minutes = d.getMinutes().toString();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;
    if (minutes.length < 2) minutes = `0${minutes}`;
    if (hours.length < 2) hours = `0${hours}`;

    return `${[year, month, day].join(separator)} ${hours}:${minutes}`;
  }

  static toYYYYsMMsDDsHHsMMsSS(date, separator = "-", useMs = false) {
    if (!date) return "";
    separator = separator ? separator : "-";
    const d = new Date(date);
    let month = (d.getMonth() + 1).toString();
    let day = d.getDate().toString();
    const year = d.getFullYear();
    let hours = d.getHours().toString();
    let minutes = d.getMinutes().toString();
    let secs = d.getSeconds().toString();
    const ms = d.getMilliseconds().toString();

    if (hours.length < 2) hours = `0${hours}`;
    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;
    if (minutes.length < 2) minutes = `0${minutes}`;
    if (secs.length < 2) secs = `0${secs}`;

    return `${[year, month, day].join(separator)} ${hours}:${minutes}:${secs}${
      useMs ? `:${ms}` : ""
    }`;
  }

  static LastDayOfMonth(year, month) {
    const d = new Date(year, month + 1, 0);
    return d.getDate();
  }

  static LimitDayNumber(year, month, day) {
    if (day > dateTimeHelper.LastDayOfMonth(year, month)) {
      day = dateTimeHelper.LastDayOfMonth(year, month);
    }
    if (day < 1) {
      return 1;
    }
    return day;
  }

  static getMonday(d) {
    d = new Date(d);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  static addYearsToNowDate(yearsToAdd) {
    return new Date().setFullYear(new Date().getFullYear() + yearsToAdd);
  }

  static indefiniteDateValue() {
    return new Date(9999, 11, 31);
  }

  static isIndefinite(date) {
    date = new Date(date);
    return date.getFullYear() >= 9999;
  }

  static firstDayOfTheActualMonth() {
    const today = new Date();
    return dateTimeHelper.toYYYYsMMsDD(
      new Date(today.getFullYear(), today.getMonth(), 1)
    );
  }

  static firstDayOfTheNextMonth() {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth() + 1, 1);
  }

  static lastDayOfTheActualMonth() {
    const today = new Date();
    return dateTimeHelper.toYYYYsMMsDD(
      new Date(today.getFullYear(), today.getMonth() + 1, 0)
    );
  }

  static convertUTCDateStringToLocale(date) {
    return new Date(`${date} UTC`);
  }

  static convertLocalToUTCDate(date) {
    if (!date) return date;
    return new Date(date);
    // return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    // return date;
  }

  static nowStr(date) {
    return dateTimeHelper.toYYYYsMMsDDsHHsMMsSS(new Date(), "-", true);
  }

  static log(desc) {}

  static timestamp(useMilliseconds = false) {
    const d = new Date();
    let month = (d.getMonth() + 1).toString();
    let day = d.getDate().toString();
    const year = d.getFullYear();
    let hours = d.getHours().toString();
    let minutes = d.getMinutes().toString();
    let secs = d.getSeconds().toString();
    const ms = d.getMilliseconds().toString();

    if (hours.length < 2) hours = `0${hours}`;
    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;
    if (minutes.length < 2) minutes = `0${minutes}`;
    if (secs.length < 2) secs = `0${secs}`;

    const a = [year, month, day, hours, minutes, secs];
    if (useMilliseconds) a.push(ms);

    return a.join("");
  }

  static startOfTheDay(date) {
    date = new Date(date);
    date.setHours(0, 0, 0, 0);
    return date;
  }

  static endOfTheDay(date) {
    date = new Date(date);
    date.setUTCHours(23, 59, 59, 9999);
    return date;
  }

  static differenceTime(date1, date2) {
    if (!date1 || !date2) return "";
    date1 = new Date(date1);
    date2 = new Date(date2);
    const diffTime = Math.abs(date2 - date1);
    return dateTimeHelper.millissecondsToTime(diffTime);
  }

  static millissecondsToTime(s) {
    const ms = s % 1000;
    s = (s - ms) / 1000;
    let secs = s % 60;
    s = (s - secs) / 60;
    let mins = s % 60;
    let hrs = (s - mins) / 60;
    hrs = stringHelper.numberWithLeadingZero(hrs);
    mins = stringHelper.numberWithLeadingZero(mins);
    secs = stringHelper.numberWithLeadingZero(secs);

    return `${hrs}:${mins}:${secs}`;
  }

  static removeTime(date = new Date()) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  static dateDiffInDays(a, b) {
    if (
      Object.prototype.toString.call(a) !== "[object Date]" ||
      Object.prototype.toString.call(b) !== "[object Date]"
    ) {
      return null;
    }

    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  static calculateTimeDifference(startTime, endTime) {
    const start = new Date(startTime);
    const end = new Date(endTime);
    const diff = (end.getTime() - start.getTime()) / 1000;
    return Math.round(diff / 60);
  }
  static getYrMoFromDate(year, month) {
    return `${year}${stringHelper.addLeadingZero(month)}`;
  }

  static MonthName(month) {
    switch (month) {
      case 0:
        return "January";
      case 1:
        return "February";
      case 2:
        return "March";
      case 3:
        return "April";
      case 4:
        return "May";
      case 5:
        return "June";
      case 6:
        return "July";
      case 7:
        return "August";
      case 8:
        return "September";
      case 9:
        return "October";
      case 10:
        return "November";
      case 11:
        return "December";
      default:
        return "";
    }
  }

  static IsWeekend(year, month, day) {
    const d = new Date(year, month, day);
    return d.getDay() === 6 || d.getDay() === 0;
  }
}

export default dateTimeHelper;
