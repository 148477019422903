import React from "react";
import OfficeService from "../../../Services/OfficeService";
import MessageComponent from "../../MessageComponent";
import LoadingIcon from "../../Loading/LoadingIcon";
import MultiChoice from "./MultiChoice";

export default class OfficeMultiChoice extends React.Component {
  static defaultProps = {
    onChange: () => alert("CountryMultiChoice Onchange"),
    fieldName: "",
    selected: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      itemList: [],
    };
  }

  componentDidMount() {
    this.populateListData();
  }

  populateListData() {
    OfficeService.GetOfficeList()
      .then((result) => {
        const optValues = [];
        result.forEach((office) => {
          optValues.push({
            label: office.name,
            value: office.officeId,
          });
        });
        this.setState({ itemList: optValues, loading: false });
      })
      .catch(() => {
        this.setState({ itemList: [], loading: false });
        MessageComponent.SetErrorMessage("OMC40");
      });
  }

  renderList() {
    return (
      <MultiChoice
        label=""
        p-multiselect-panel={true}
        className="rmsc"
        options={this.state.itemList}
        selected={this.props.selected}
        onChange={(selected) =>
          this.props.onChange(this.props.fieldName, selected)
        }
        labelledBy="Select"
      />
    );
  }

  render() {
    return (
      <div className="w-100 text-center hover rounded arial">
        {this.state.loading ? (
          <LoadingIcon loading={true} className="h6" />
        ) : (
          this.renderList()
        )}
      </div>
    );
  }
}
