import React from "react";
import "../css/GrayContainer.css";
import TranslationComponent from "./TranslationComponent";
import FieldTypesEnum from "../Enums/FieldTypesEnum";
import DataWithInPlaceEdit from "./DataWithInPlaceEdit";
import AuthService from "../Services/AuthService";
import FigmaButtonRow from "./Button/FigmaButtonRow";

export default class DetailsContainer extends React.Component {
  static defaultProps = {
    title: "",
    className: "",
    displayedItem: null,
    fieldsToShow: [],
    updateFunction: () => {},
    clickOnSave: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      loggedInManager: AuthService.getInstance().GetLoggedInManager(),
    };
  }

  renderField(field) {
    if (field.type === FieldTypesEnum.DataWithInPlaceEdit) {
      return (
        <DataWithInPlaceEdit
          dataToShow={this.props.displayedItem[field.dataToShow]}
          value={this.props.displayedItem[field.value]}
          fieldName={field.value}
          type={field.inputType}
          editEnabled={field.editEnabled}
          validityChecker={(value, editedItem) =>
            typeof field.validityChecker === "function"
              ? field.validityChecker(
                  value,
                  editedItem,
                  this.state.loggedInManager
                )
              : ""
          }
          editedItem={this.props.displayedItem}
          clickOnSave={this.props.clickOnSave}
          serviceFunction={this.props.updateFunction}
        />
      );
    }

    if (field.type === FieldTypesEnum.Function) {
      return field.value(this.props.displayedItem);
    }

    if (field.type === FieldTypesEnum.Date) {
      return this.props.displayedItem[field.value]
        ? new Date(this.props.displayedItem[field.value]).toLocaleDateString()
        : null;
    }

    return this.props.displayedItem[field.value];
  }

  validate(field) {
    return (
      field.validityChecker &&
      typeof field.validityChecker === "function" &&
      field.validityChecker(
        this.props.displayedItem[field.value],
        this.props.displayedItem,
        this.state.loggedInManager
      )
    );
  }

  validateEntireTab(fields) {
    for (const field of fields.data) {
      if (this.validate(field)) {
        return true;
      }
    }
    return false;
  }

  getButtons(itemDetails) {
    const buttons = [];
    if (!this.props.buttons || !Array.isArray(this.props.buttons)) {
      return null;
    }

    this.props.buttons.forEach((buttonData, index) => {
      buttonData.hide = buttonData.hidden && buttonData.hidden(itemDetails);
      buttonData.onClick = () => buttonData.function(itemDetails);

      buttons.push(buttonData);
    });

    return <FigmaButtonRow isOverWindow={true} buttons={buttons} />;
  }

  renderFields() {
    return this.props.fieldsToShow.map((fields, index) => {
      return (
        <div
          className={`col-12 ${
            this.props.fieldsToShow.length === 2 && "col-md-6"
          }`}
          style={{ maxWidth: "750px" }}
          key={fields.title + index}
        >
          {fields.title && (
            <span className="gray-container-header">
              <TranslationComponent
                phrase={fields.title}
                loadingEnabled={false}
              />
            </span>
          )}

          {this.validateEntireTab(fields) ? (
            <span className="text-danger">
              &nbsp;
              <TranslationComponent
                phrase="Some required fields are empty!"
                loadingEnabled={false}
              />
              <br />
              <TranslationComponent
                phrase="Labels with red marks are mandatory."
                loadingEnabled={false}
              />
            </span>
          ) : null}

          <div
            className={`gray-container gray-details-container mt-4 mb-4 ${this.props.className}`}
          >
            {fields.data.flatMap((field, ind) => [
              ind === 0 ? null : (
                <div key={`divider${ind}`} className="gray-border" />
              ),
              <span key={field.label} className="gray-container-label">
                <TranslationComponent
                  phrase={field.label}
                  loadingEnabled={false}
                />
                {this.validate(field) ? (
                  <span className="text-danger">*</span>
                ) : null}
              </span>,
              <span key={`${field.label}value`}>
                {this.renderField(field)}
              </span>,
            ])}
          </div>
        </div>
      );
    });
  }

  render() {
    return (
      <div
        className="gray-container-spacing container-fluid"
        style={{ maxWidth: "100vw" }}
      >
        {this.getButtons(this.props.displayedItem)}
        <div className="row justify-content-center">
          {this.props.displayedItem ? this.renderFields() : null}
        </div>
      </div>
    );
  }
}
