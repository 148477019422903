import React from "react";
import "../../css/Header.css";
import AuthService from "../../Services/AuthService";
import LanguageDropdown from "./LanguageDropdown";
import CxLogoLink from "./CxLogoLink";
import ProfileDropdown from "./ProfileDropdown";

export default class AppHeader extends React.Component {
  static defaultProps = {
    handleLogout: () => {
      throw new Error("handleLogout not implemented.");
    },
    toggleNavBar: () => {},
  };

  constructor(props) {
    super(props);
    const loggedInManager = AuthService.getInstance().GetLoggedInManager();
    this.state = {
      loggedInManager,
    };
  }

  renderCxLogo() {
    return <CxLogoLink className="me-auto hide-sm" />;
  }

  renderNavbarButton() {
    return (
      <div
        className="Navbar-toggle-icon me-auto show-sm"
        onClick={() => {
          if (this.props.toggleNavBar) {
            this.props.toggleNavBar();
          }
        }}
      >
        <i className="fa-solid fa-bars" />
      </div>
    );
  }

  renderLanguageDropdown() {
    return <LanguageDropdown className="ms-auto header-button" />;
  }

  renderUserDropdown() {
    return <ProfileDropdown handleLogout={this.props.handleLogout} />;
  }

  renderclientLogo() {
    return this.state.loggedInManager.clientLogoData ? (
      <img
        src={this.state.loggedInManager.clientLogoData}
        height={70}
        className="mx-2 hide-sm"
        alt="logo"
      />
    ) : null;
  }

  render() {
    return (
      <header className="App-header">
        {this.renderCxLogo()}
        {this.renderNavbarButton()}
        {this.renderLanguageDropdown()}
        {this.renderUserDropdown()}
        {this.renderclientLogo()}
      </header>
    );
  }
}
