import React from "react";
import JsxHelper from "../Helpers/JsxHelper";
import FaIcon from "./FaIcon";
import { MainRefContext } from "../MainRefContext";
import TranslationComponent from "./TranslationComponent";

export default class Link extends React.Component {
  static defaultProps = {
    className: "link",
    iconName: null,
    title: null,
    headerText: null,
    linktext: null,
    link: null,
    iconInLinktext: true,
    tooltipText: "",
    withoutText: false,
    disabled: false,
    url: "home",
    target: "_self",
  };

  static contextType = MainRefContext;

  onClick = (e) => {
    if (this.props.target === "_blank" && this.props.url) {
      window.open(`/${this.props.url}`, "_blank");
      return;
    }
    if (this.context.current) {
      this.context.current.setState({
        menuId: null,
        menuLink: this.props.link,
        menuTitle: this.props.title,
        menuHeaderText: this.props.headerText,
        menuIcon: this.props.iconName,
        menuUrl: this.props.url,
      });
    }
  };

  render() {
    const enabled = !this.props.disabled;

    const showedText =
      this.props.linktext || this.props.linktext === ""
        ? this.props.linktext
        : this.props.title;

    return (
      <span
        className={
          enabled
            ? this.props.className
            : `${this.props.className} linkdisabled`
        }
        onClick={enabled ? this.onClick : null}
        data-toggle="tooltip"
        data-placement="top"
        title={enabled ? this.props.tooltipText : ""}
      >
        {this.props.iconInLinktext && this.props.iconName && (
          <FaIcon
            iconName={this.props.iconName}
            tooltipText={enabled ? this.props.tooltipText : ""}
          />
        )}
        {this.props.iconInLinktext &&
          this.props.iconName &&
          !this.props.withoutText &&
          JsxHelper.NonBreakableSpace()}
        {!this.props.withoutText && (
          <TranslationComponent phrase={showedText} loadingEnabled={false} />
        )}
      </span>
    );
  }
}
