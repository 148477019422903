const FieldTypesEnum = {
  PureDataField: "puredatafield",
  DataWithInPlaceEdit: "datawithinplaceedit",
  Date: "date",
  Function: "function",
  Text: "text",
  TextArea: "textarea",
  Select: "select",
  Checkbox: "checkbox",
  CountrySelect: "countryselect",
};

export default FieldTypesEnum;
