import React from "react";
import LoadingFullScreen from "../Loading/LoadingFullScreen";
import Card from "../Card/Card";
import Pagination from "../Pagination/Pagination";
import "../../css/CardListComponent.css";
import GeneralSearchStrip from "../Search/GeneralSearchStrip";
import ShownPanelsEnum from "../../Enums/ShownPanelsEnum";
import GeneralItemDetails from "./GeneralItemDetails";
import FaIcon from "../FaIcon";
import MessageComponent from "../MessageComponent";
import DataTableHeader from "../DataTable/DataTableHeader";
import DataTableBody from "../DataTable/DataTableBody";

export default class GeneralItemList extends React.Component {
  static defaultProps = {
    itemType: null,
    detailsItemType: null,
    searchItem: null,
    searchParamsType: null,
    numberOfElementsOnAPage: 20,
    searchStripVisible: true,
    clickOnStartSearch: null,
    cardList: true,
    inputFieldOnChange: () => {},
    selectButtonDisabled: false,
    alternateShowInList: [],
    onItemListChange: () => {},
    onSelectListChange: () => {},
    filterToSelected: false,
    paginationVisible: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      itemList: null,
      pageNumber: 1,
      numberOfElementsOnAPage: props.numberOfElementsOnAPage,
      totalNumberOfElements: 0,
      orderBy: "Id",
      orderDirection: "asc",
      isSelectMode: false,
      selectedItemIds: [],
      isToTheTopButtonVisible: false,
      selectAllLevel: 0,
      searchItem: props.searchItem,
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.onChange = this.onChange.bind(this);

    //The clickOnSelect needs to be binded here for the allItemsAreSelected variable to be in sync with the parent component
    if (!props.cardList && props.itemType.clickOnSelect !== undefined) {
      props.itemType.clickOnSelect = this.clickOnSelect.bind(this);
      props.itemType.clickOnGlobalSelect = this.clickOnGlobalSelect.bind(this);
      props.itemType.selectedStatus = this.selectedStatus.bind(this);
    }
  }

  handleScroll(e) {
    const yPosition = document.getElementsByClassName("App-main")[0].scrollTop;

    if (yPosition > 400) {
      this.setState({ isToTheTopButtonVisible: true });
    } else {
      this.setState({ isToTheTopButtonVisible: false });
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, true);

    this.populateItemListData(
      this.state.orderBy,
      this.state.orderDirection,
      this.state.pageNumber,
      this.state.numberOfElementsOnAPage,
      this.state.searchItem
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.searchItem !== prevProps.searchItem) {
      this.populateItemListData(
        this.state.orderBy,
        this.state.orderDirection,
        this.state.pageNumber,
        this.state.numberOfElementsOnAPage,
        this.props.searchItem
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll, true);
  }

  populateItemListData(
    orderBy,
    orderDir,
    pageNumber,
    numberOfElementsOnAPage,
    searchItem
  ) {
    this.props.itemType
      .ListServiceFunction(
        orderBy,
        orderDir,
        pageNumber,
        numberOfElementsOnAPage,
        searchItem
      )
      .then(response => {
        if (response.error) {
          MessageComponent.SetErrorMessage(`Error: ${response.error}`);
          this.setState({ loading: false });
          return;
        }

        const convertedResult = this.mapListToType(response.data);

        this.setState({
          loading: false,
          itemList: convertedResult,
          pageNumber: response.pageNumber,
          numberOfElementsOnAPage: response.numberOfElementsOnAPage,
          totalNumberOfElements: response.totalNumberOfElements,
          selectedItemIds: [],
          searchItem,
        });
        this.props.onItemListChange(convertedResult);
        this.props.onSelectListChange([]);
      })
      .catch(error => {
        console.error(error);
        this.setState({ loading: false });
        MessageComponent.SetErrorMessage("Couldn't populate list data.");
      });
  }

  mapListToType(itemlist) {
    const convertedItems = [];

    if (itemlist !== null) {
      itemlist.forEach(item => {
        const newItem = new this.props.itemType(item);
        convertedItems.push(newItem);
      });
    }

    return convertedItems;
  }

  paginationOnChangeOfRangeSelectorHandler(newRange) {
    this.setState({
      numberOfElementsOnAPage: newRange,
      loading: true,
    });
    this.populateItemListData(
      this.state.orderBy,
      this.state.orderDirection,
      this.state.pageNumber,
      newRange,
      this.state.searchItem
    );
  }

  startSearch(searchItem) {
    if (this.props.clickOnStartSearch)
      this.props.clickOnStartSearch(searchItem);

    this.setState({
      searchItem,
      pageNumber: 1,
      loading: true,
    });

    this.populateItemListData(
      this.state.orderBy,
      this.state.orderDirection,
      1,
      this.state.numberOfElementsOnAPage,
      searchItem
    );
  }

  clickOnPagination(pageNumber) {
    this.setState({ loading: true });
    this.populateItemListData(
      this.state.orderBy,
      this.state.orderDirection,
      pageNumber,
      this.state.numberOfElementsOnAPage,
      this.state.searchItem
    );
  }

  renderDetailBox() {
    if (this.props.itemType.DetailsEnabled !== true) return null;
    return (
      <GeneralItemDetails
        itemType={this.props.detailsItemType}
        selectedId={this.state.selectedId}
        clickOnCancel={() => this.setState({ selectedId: -1 })}
      />
    );
  }

  clickOnCard = idValue => {
    if (this.state.isSelectMode) return this.clickOnCardSelect(idValue);

    if (!this.props.itemType.DetailsEnabled) return;

    this.setState({
      selectedId: idValue === this.state.selectedId ? -1 : idValue,
    });
  };

  clickOnCardSelect = idValue => {
    if (this.state.selectAllLevel === 2) return;

    if (this.state.selectAllLevel === 1) {
      this.setState({ selectAllLevel: 0 });
    }
    if (this.state.selectedItemIds.includes(idValue)) {
      const newSelectedId = this.state.selectedItemIds.filter(
        i => i !== idValue
      );
      this.setState({
        selectedItemIds: newSelectedId,
      });
      return;
    }
    this.props.onSelectListChange(
      [...this.state.selectedItemIds, idValue],
      this.state.selectAllLevel
    );
    this.setState({
      selectedItemIds: [...this.state.selectedItemIds, idValue],
    });
  };

  handleOrderChange = orderBy => {
    const parts = orderBy.split("_");

    this.setState({
      orderBy: parts[0],
      orderDirection: parts[1],
      loading: true,
    });
    this.populateItemListData(
      parts[0],
      parts[1],
      this.state.pageNumber,
      this.state.numberOfElementsOnAPage,
      this.state.searchItem
    );
  };

  renderSearchStrip() {
    if (this.props.searchStripVisible === false) return null;
    return (
      <GeneralSearchStrip
        searchItem={this.state.searchItem}
        isRefreshDisabled={false}
        isFilterDisabled={false}
        searchParamsType={this.props.searchParamsType}
        clickOnOk={(type, item) => this.startSearch(item)}
        clickOnCancel={() =>
          this.setState({ shownPanel: ShownPanelsEnum.None })
        }
        clickOnSelectMode={() =>
          this.setState({
            isSelectMode: !this.state.isSelectMode,
            selectedItemIds: [],
          })
        }
        clickOnSelectAll={selectAllLevel => {
          const newSelectedItemIds =
            selectAllLevel === 0
              ? []
              : this.state.itemList?.map(i => i[i.constructor.IdField]);
          this.props.onSelectListChange(newSelectedItemIds, selectAllLevel);
          this.setState({
            selectAllLevel,
            selectedItemIds: newSelectedItemIds,
          });
        }}
        selectAllLevel={this.state.selectAllLevel}
        clickOnOrderOption={this.handleOrderChange}
        isSelectDisabled={this.props.selectButtonDisabled}
        singleSearchLine={this.props.itemType.SingleSearchLine}
      />
    );
  }

  renderCardList() {
    return (
      <div className="container-fluid maxWidth px-4 ">
        <div className="row gx-5 justify-content-center">
          {this.state.itemList?.map(item => this.renderCard(item))}
        </div>
      </div>
    );
  }

  renderCard(item) {
    return (
      <Card
        onClick={this.clickOnCard}
        key={item[item.constructor.IdField]}
        item={item}
        selected={this.state.selectedItemIds.includes(
          item[item.constructor.IdField]
        )}
        detailsEnabled={this.props.itemType.DetailsEnabled === true}
      />
    );
  }

  //#region functions for basic table design for selection wizzard
  renderTable() {
    const itemList = this.props.filterToSelected
      ? this.state.itemList?.filter(x => x.Selected === true)
      : this.state.itemList;
    return (
      <div className="FigmaDataTableBorder">
        <table className="stripped tab">
          <DataTableHeader
            type={this.props.itemType}
            alternateShowInList={this.props.alternateShowInList}
          />
          {itemList?.map((item, Lineindex) =>
            this.renderTableRow(item, Lineindex)
          )}
        </table>
      </div>
    );
  }

  onChange(fieldName, newValue, index) {
    const newList = this.state.itemList;
    newList[index][fieldName] = newValue;
    this.props.inputFieldOnChange(fieldName, newValue);
    this.setState({ itemList: [...newList] });
  }

  renderTableRow(listItem, lineIndex) {
    return (
      <tbody key={`tbody${lineIndex}`}>
        <DataTableBody
          listItem={listItem}
          lineIndex={lineIndex}
          type={this.props.itemType}
          inputFieldOnChange={(fieldName, newValue) =>
            this.onChange(fieldName, newValue, lineIndex)
          }
          alternateShowInList={this.props.alternateShowInList}
        />
      </tbody>
    );
  }

  clickOnSelect(item, selected) {
    item.Selected = selected;
    this.setState({ itemList: [...this.state.itemList] });
    this.props.onItemListChange(this.state.itemList);
  }

  clickOnGlobalSelect(item, selected) {
    const selectedStatus = this.state.itemList.some(
      item => item.Selected === false
    );
    const templist = [...this.state.itemList];

    if (templist !== undefined && templist.length > 0) {
      templist.map(c => (c.Selected = selectedStatus));
    }
    this.setState({ itemList: templist });
    this.props.onItemListChange(templist);
  }

  selectedStatus() {
    if (this.state.itemList.some(x => x.Selected === false)) {
      return false;
    }

    return true;
  }
  //#endregion

  renderPagination() {
    if (this.props.paginationVisible === false) return null;

    return (
      <Pagination
        pageNumber={this.state.pageNumber}
        numberOfElementsOnAPage={this.state.numberOfElementsOnAPage}
        totalNumberOfElements={this.state.totalNumberOfElements}
        onClick={pageNumberInPagination =>
          this.clickOnPagination(pageNumberInPagination)
        }
        rangeMax={500}
        onChangeOfRangeSelector={this.paginationOnChangeOfRangeSelectorHandler.bind(
          this
        )}
        isSelectMode={this.state.isSelectMode}
        selectedItemIdsCount={
          this.state.selectAllLevel === 2
            ? this.state.totalNumberOfElements
            : this.state.selectedItemIds.length
        }
      />
    );
  }

  render() {
    if (this.state.loading) return <LoadingFullScreen />;
    return (
      <>
        <div id="top" />
        {this.renderSearchStrip()}
        {this.props.cardList ? this.renderCardList() : this.renderTable()}
        {this.renderPagination()}
        {this.state.selectedId &&
          this.state.selectedId !== -1 &&
          this.renderDetailBox()}
        {this.state.isToTheTopButtonVisible && (
          <a
            className="top"
            onClick={e => {
              e.preventDefault();
              document.getElementById("top").scrollIntoView();
            }}
            href="#top"
          >
            <FaIcon iconName="fa-solid fa-chevron-up" />
          </a>
        )}
      </>
    );
  }
}
