import React from "react";
import ProgressBarDotsFigma from "./ProgressBarDotsFigma";
import WizzardTypeEnum from "../../Enums/WizzardTypeEnum";
import InputTypesEnum from "../../Enums/InputTypesEnum";
import ButtonTypeEnum from "../../Enums/ButtonTypeEnum";
import ButtonColorsEnum from "../../Enums/ButtonColorsEnum";
import IconPositionEnum from "../../Enums/IconPositionEnum";
import FigmaButton from "../Button/FigmaButton";
import OverWindow from "../OverWindow";

//Base class for WizzardPages
//Override renderFormData to render the different pages
//Override validityCheckerForTheEntireForm if the default validation does not suit your needs
//If you override another function, mention it here and tag the function with //Overridable
export default class GeneralEditWizzardPageBase extends React.Component {
  static defaultProps = {
    clickOnSave: (step, editedItem) => {},
    clickOnCancel: () => {},
    crudType: WizzardTypeEnum.Create,
    editedItem: null,
    onChangeEditedItem: (newEditedItem) => {},
    step: 0,
    windowDescription: {
      navigateToOnCreation: null, // A base url to navigate to after creation. The id of the created item will be added to the end of the url.
      itemType: {},
      searchParamsType: {},
      detailsItemType: {},
      pagesInGeneralEditWizzard: [
        {
          label: "Page 1",
          fields: [
            {
              className: null,
              disableClearButton: false,
              disabled: false,
              EntityId: null,
              EntityTypeId: null,
              fieldName: null, //This can also be a function that returns a string. (editedItem) => {}
              hide: false, //This can also be a function that returns a boolean. (editedItem) => {}
              idFilter: null,
              idFilterArray: {}, //Some inputs will use multiple values to filter
              label: null,
              onFieldChange: (editedItem) => {}, //Can change the value of the field regardless of the user input.
              roleKey: null,
              selectvalues: null, //This can be used to insert custom values into a Select field
              showFilterAt: null, //This can also be a function that returns a number. (editedItem) => {}
              type: InputTypesEnum.EmptySpace,
              validityChecker: (field, editedItem) => {}, //Check if the field value is correct
            },
          ],
        },
      ],
      serviceFunction: () => {}, //The function that will be called when the user clicks on 'Finish', it is in most cases a backend call.
      pdfSummaryServiceFunction: () => {}, //The function that will be called when the user navigates to the last page, it is in most cases a backend call.
      titleForCreate: "Add New Item",
      titleForEdit: "Edit Item",
    },
  };

  constructor(props) {
    super(props);

    const newWindowDescription = {
      ...props.windowDescription,
      pagesInGeneralEditWizzard:
        props.windowDescription.pagesInGeneralEditWizzard.filter(
          (f) => f.hide === undefined || f.hide(props.editedItem) === false
        ),
    };

    this.state = {
      editedItem: props.editedItem,
      saving: false,
      windowDescription: newWindowDescription,
    };

    this.progressBarHeight = 64;

    this.clickOnProgressBarDot = this.clickOnProgressBarDot.bind(this);
    this.changeOfEditedItem = this.changeOfEditedItem.bind(this);
  }

  componentDidMount() {}

  //Overridable
  componentDidUpdate(newProps) {
    if (newProps !== this.props) {
      if (this.props.editedItem !== newProps.editedItem) {
        this.setState({ editedItem: this.props.editedItem, saving: false });
        return;
      }
      this.setState({ saving: false });
    }
  }

  changeOfEditedItem(fieldName, newValue) {
    const newEdited = { ...this.state.editedItem, [fieldName]: newValue };
    if (this.props.onChangeEditedItem) this.props.onChangeEditedItem(newEdited);

    const newWindowDescription = {
      ...this.state.windowDescription,
      pagesInGeneralEditWizzard:
        this.props.windowDescription.pagesInGeneralEditWizzard.filter(
          (f) => f.hide === undefined || f.hide(newEdited) === false
        ),
    };

    this.setState({
      editedItem: newEdited,
      windowDescription: newWindowDescription,
    });
  }

  //Overridable
  validityCheckerForTheEntireForm() {
    const currentPage =
      this.state.windowDescription.pagesInGeneralEditWizzard[this.props.step];

    if (currentPage.fields === undefined) return true;

    for (const field of currentPage.fields) {
      if (
        field.hide !== undefined &&
        field.hide(this.state.editedItem) === true
      ) {
      } else {
        if (!field.validityChecker) {
          continue;
        }

        const validation = field.validityChecker(
          this.state.editedItem[field.fieldName],
          this.state.editedItem
        );

        if (validation !== "" && validation !== undefined) return false;
      }
    }

    return true;
  }

  getNumberOfEditPages() {
    return this.state.windowDescription.pagesInGeneralEditWizzard.length;
  }

  clickOnProgressBarDot(index) {
    if (index === this.props.step) return;
    this.props.clickOnSave(index, this.state.editedItem);
  }

  renderProgressBarDots() {
    if (this.getNumberOfEditPages() === 1) return null;
    return (
      <ProgressBarDotsFigma
        actual={this.props.step + 1}
        data={this.state.windowDescription}
        clickOnDot={this.clickOnProgressBarDot}
      />
    );
  }

  renderPreviousButton() {
    if (this.getNumberOfEditPages() === 1) {
      return null;
    }
    return (
      <FigmaButton
        label="Previous"
        type={ButtonTypeEnum.NormalIconLeft}
        color={ButtonColorsEnum.Common}
        iconPosition={IconPositionEnum.Left}
        iconName="fas fa-arrow-left"
        disabled={this.props.step === 0}
        onClick={() => {
          this.props.clickOnSave(this.props.step - 1, this.state.editedItem);
        }}
      />
    );
  }

  renderButton() {
    if (
      this.props.step !==
      this.state.windowDescription.pagesInGeneralEditWizzard.length - 1
    )
      return (
        <FigmaButton
          label="Next"
          type={ButtonTypeEnum.NormalIconRight}
          color={ButtonColorsEnum.Secondary}
          iconPosition={IconPositionEnum.Right}
          onClick={() => {
            this.setState({ saving: true });
            this.props.clickOnSave(this.props.step + 1, this.state.editedItem);
          }}
          iconName="fas fa-arrow-right"
          disabled={
            this.props.step ===
              this.state.windowDescription.pagesInGeneralEditWizzard.length -
                1 || !this.validityCheckerForTheEntireForm()
          }
        />
      );
    if (
      (this.props.crudType !== WizzardTypeEnum.Create &&
        this.props.step >=
          this.state.windowDescription.pagesInGeneralEditWizzard.length - 1) ||
      !this.state.saving
    )
      return (
        <FigmaButton
          label="Finish"
          type={ButtonTypeEnum.NormalNormal}
          color={ButtonColorsEnum.Primary}
          disabled={
            (this.props.crudType === WizzardTypeEnum.Create &&
              this.props.step <
                this.state.windowDescription.pagesInGeneralEditWizzard.length -
                  1) ||
            !this.validityCheckerForTheEntireForm() ||
            this.state.saving
          }
          onClick={() => {
            this.setState({ saving: true });
            this.props.clickOnSave(
              this.props.windowDescription.pagesInGeneralEditWizzard.length,
              this.state.editedItem
            );
          }}
        />
      );
    return "";
  }

  renderFooter() {
    return (
      <div>
        <div className="EditBoxFooter">
          <div className="EditBoxFooterRight">
            {this.renderPreviousButton()}
            {this.renderButton()}
          </div>
        </div>
      </div>
    );
  }

  //Overridable
  renderFormData() {
    return <div>GeneralEditWizzardPageBaseFigma</div>;
  }

  renderForm() {
    return (
      <>
        <div className="EditPanelContent">
          <div
            className="EditPanelInput"
            style={{
              height: `calc(100vh - 17rem - ${
                this.getNumberOfEditPages() === 1 ? this.progressBarHeight : 0
              }px)`,
            }}
          >
            <div className="container-fluid">{this.renderFormData()}</div>
          </div>
        </div>
        <br />
        {this.renderFooter()}
      </>
    );
  }

  render() {
    const title =
      this.props.crudType === WizzardTypeEnum.Create
        ? typeof this.state.windowDescription.titleForCreate === "function"
          ? this.state.windowDescription.titleForCreate(this.state.editedItem)
          : this.state.windowDescription.titleForCreate
        : typeof this.state.windowDescription.titleForEdit === "function"
          ? this.state.windowDescription.titleForEdit(this.state.editedItem)
          : this.state.windowDescription.titleForEdit;

    return (
      <OverWindow
        clickOnCancel={this.props.clickOnCancel}
        closeOnClickOutside={false}
        title={title}
        iconName={
          this.props.crudType === WizzardTypeEnum.Create
            ? "fas fa-magic"
            : "fas fa-edit"
        }
        crudType={this.props.crudType}
      >
        {this.renderProgressBarDots()}
        {this.renderForm()}
      </OverWindow>
    );
  }
}
