import FieldTypesEnum from "../Enums/FieldTypesEnum";
import ValidityHelper from "../Helpers/ValidityHelper";
import LocationService from "../Services/LocationService";
import PricingsAdmin from "../Pages/PricingsAdmin";
import MessageComponent from "../Elements/MessageComponent";
import PurchaseOrderLinesAdmin from "../Pages/PurchaseOrderLinesAdmin";
import InvoicingDataPage from "../Pages/InvoicingDataPage";
import ButtonColorsEnum from "../Enums/ButtonColorsEnum";
import PurchaseOrderLineForLocationListItem from "./PurchaseOrderLineForLocationListItem";

export default class LocationDetailItem {
  constructor(item) {
    if (!item) {
      this.LocationId = 0;
      this.CxClientId = 0;
      this.ClientLocationId = 0;
      this.ClientLocationName = "";
      this.CountryCode = "";
      this.City = "";
      this.IsActive = false;
      this.GroupName = "";
      this.Zip = "";
      this.Address = "";
      this.Phone = "";

      this.Level1 = "";
      this.Level2 = "";
      this.Level3 = "";
      this.Level4 = "";
      this.Level5 = "";

      this.Confirmed = false;
      this.ConfirmedOn = null;
      this.ModifiedOn = null;
      this.LocationManagerName = "";
      this.LocationManagerEmail = "";
      this.LocationManagerPhone = "";

      return;
    }

    this.LocationId = item.locationId;
    this.CxClientId = item.cxClientId;
    this.ClientLocationId = item.clientLocationId;
    this.ClientLocationName = item.clientLocationName;
    this.City = item.city;
    this.CountryCode = item.countryCode;
    this.IsActive = item.isActive;
    this.IsActiveText = item.isActive ? "Not Active" : "Active";
    this.GroupName = item.groupName;
    this.Zip = item.zip;
    this.Address = item.address;
    this.Phone = item.phone;

    this.Level1 = item.level1;
    this.Level2 = item.level2;
    this.Level3 = item.level3;
    this.Level4 = item.level4;
    this.Level5 = item.level5;

    this.Confirmed = item.confirmed;
    this.ConfirmedOn = item.confirmedOn;
    this.ModifiedOn = item.modifiedOn;
    this.LocationManagerName = item.locationManagerName;
    this.LocationManagerEmail = item.locationManagerEmail;
    this.LocationManagerPhone = item.locationManagerPhone;
  }

  static DetailsTitle = "Location Details";
  static DetailsServiceFunction = LocationService.GetById;
  static reloadFunctionDetailsAfterSave = () => {};

  static fieldsToShow = [
    {
      tabName: "Details",
      type: "details",
      buttons: [
        {
          label: "Confirm",
          color: ButtonColorsEnum.Secondary,
          hidden: (item) => item.Confirmed,
          function: LocationDetailItem.ConfirmLocation,
        },
      ],
      data: [
        {
          title: "Location Details",
          data: [
            { label: "Location Id", value: "ClientLocationId" },
            { label: "Location Name", value: "ClientLocationName" },
            { label: "Group Name", value: "GroupName" },
            { label: "Country", value: "CountryCode" },
            { label: "City", value: "City" },
            { label: "Zip Code", value: "Zip" },
            { label: "Address", value: "Address" },
            { label: "Contact Phone", value: "Phone" },
            { label: "Active?", value: "IsActiveText" },
            {
              label: "Confirmed",
              value: (item) => (item.Confirmed ? "Confirmed" : "Not Confirmed"),
              type: FieldTypesEnum.Function,
            },
            {
              label: "Confirmed on",
              value: "ConfirmedOn",
              type: FieldTypesEnum.Date,
            },
            {
              label: "Modified on",
              value: "ModifiedOn",
              type: FieldTypesEnum.Date,
            },
          ],
        },
        {
          title: "Levels",
          data: [
            { label: "Level 1", value: "Level1" },
            { label: "Level 2", value: "Level2" },
            { label: "Level 3", value: "Level3" },
            { label: "Level 4", value: "Level4" },
            { label: "Level 5", value: "Level5" },
          ],
        },
      ],
    },
    {
      tabName: "Invoicing",
      type: "component",
      hide: (manager) =>
        !manager.collectInvoiceDataEnabled || !manager.invoiceDataPerLocation,
      data: InvoicingDataPage,
    },
    {
      tabName: "Manager",
      type: "details",
      updateFunction: LocationService.UpdateLocationManagerData,
      data: [
        {
          title: "Location Manager",
          data: [
            {
              label: "Location Manager Name",
              value: "LocationManagerName",
              type: FieldTypesEnum.DataWithInPlaceEdit,
              validityChecker: ValidityHelper.forRequiredTextField,
            },
            {
              label: "Location Manager Email",
              value: "LocationManagerEmail",
              type: FieldTypesEnum.DataWithInPlaceEdit,
              validityChecker: ValidityHelper.forEmailClientRequired,
            },
            {
              label: "Location Manager Phone",
              value: "LocationManagerPhone",
              type: FieldTypesEnum.DataWithInPlaceEdit,
              validityChecker: ValidityHelper.forRequiredPhoneNumber,
            },
          ],
        },
      ],
    },
    {
      tabName: "Services",
      type: "component",
      data: PricingsAdmin,
    },
    {
      tabName: "Purchase Order Lines",
      type: "component",
      data: PurchaseOrderLinesAdmin,
      itemType: PurchaseOrderLineForLocationListItem,
    },
  ];

  static ConfirmLocation(item) {
    MessageComponent.SetApprove(
      "Are you sure you want to confirm this location?",
      () =>
        LocationService.ConfirmLocation(item)
          .then(() => {
            MessageComponent.SetSuccess("Location confirmed.");
            item.Confirmed = true;
            item.ConfirmedOn = new Date();
            LocationDetailItem.reloadFunctionDetailsAfterSave();
          })
          .catch((error) => {
            MessageComponent.SetErrorMessage(
              "Error: Location couldn't be confirmed.",
              error
            );
          })
    );
  }
}
