import "./css/App.css";
import "./css/Button.css";
import React from "react";
import LoginPage from "./Pages/OuterPages/LoginPage";
import AuthService from "./Services/AuthService";
import ClientSelectorPage from "./Pages/OuterPages/ClientSelectorPage";
import MainPage from "./Pages/MainPage";
import AppHeader from "./Elements/Header/AppHeader";
import NavBar from "./Elements/NavBar/NavBar";
import MessageComponent from "./Elements/MessageComponent";
import MfaPage from "./Pages/OuterPages/MfaPage";
import LoadingFullScreen from "./Elements/Loading/LoadingFullScreen";
import OuterPageBase from "./Elements/OuterPageBase";
import { MainRefContext } from "./MainRefContext";
import ChangePassword from "./Pages/ChangePassword";
import OuterPageCard from "./Elements/OuterPageCard";

export default class App extends React.Component {
  static defaultProps = {};

  constructor(props) {
    super(props);

    this.state = {
      path: window.location.pathname,
      loggedInManager: null,
      userManagers: null,
      loading: true,
      showNavBar: false,
      isPasswordReset: false,
    };

    this.mainRef = React.createRef();
  }

  async componentDidMount() {
    const authService = AuthService.getInstance();
    let userManagers = authService.GetUserManagers();
    let loggedInManager = authService.GetLoggedInManager();
    let mfaChecked = authService.GetMfaChecked();
    if (!userManagers || userManagers.length === 0) {
      const isPasswordReset = await authService.LoginWithEmailCode(
        this.state.path
      );
      if (isPasswordReset) {
        this.setState({
          loading: false,
          isPasswordReset: true,
        });

        return;
      }
      await authService.LoginWithSecret();
      userManagers = authService.GetUserManagers();
      loggedInManager = authService.GetLoggedInManager();
      mfaChecked = authService.GetMfaChecked();
    }
    this.setState({
      loading: false,
      loggedInManager,
      userManagers,
      mfaChecked,
    });
  }

  renderLoginPage() {
    return (
      <LoginPage
        handleLogin={() => {
          const authSevice = AuthService.getInstance();
          this.setState({
            loading: false,
            userManagers: authSevice.GetUserManagers(),
            loggedInManager: authSevice.GetLoggedInManager(),
            mfaChecked: authSevice.GetMfaChecked(),
          });
        }}
      />
    );
  }

  renderMfaPage() {
    return (
      <MfaPage
        handleEnteringPin={() => {
          const authService = AuthService.getInstance();
          const loggedInManager = authService.GetLoggedInManager();
          const mfaChecked = authService.GetMfaChecked();
          this.setState({
            loading: false,
            loggedInManager,
            mfaChecked,
          });
        }}
      />
    );
  }

  renderChangePassword() {
    return (
      <OuterPageCard className="credential-card-wide">
        <ChangePassword />
      </OuterPageCard>
    );
  }

  renderClientSelectorPage() {
    return (
      <ClientSelectorPage
        handleChoosing={() => {
          const loggedInManager =
            AuthService.getInstance().GetLoggedInManager();
          this.setState({
            loading: false,
            loggedInManager,
          });
        }}
      />
    );
  }

  renderHeader() {
    return (
      <AppHeader
        handleLogout={() => {
          this.setState({
            loggedInManager: null,
            userManagers: null,
          });
        }}
        toggleNavBar={() => {
          this.setState({ showNavBar: !this.state.showNavBar });
        }}
      />
    );
  }

  renderNavBar() {
    return (
      <NavBar
        showNavBar={this.state.showNavBar}
        hideNavBar={() => this.setState({ showNavBar: false })}
      />
    );
  }

  renderBody() {
    return <MainPage ref={this.mainRef} />;
  }

  render() {
    if (this.state.loading) {
      return <OuterPageBase cardElement={<LoadingFullScreen />} />;
    }
    if (this.state.isPasswordReset) {
      return <OuterPageBase cardElement={this.renderChangePassword()} />;
    }
    if (!this.state.userManagers || this.state.userManagers.length === 0) {
      return <OuterPageBase cardElement={this.renderLoginPage()} />;
    }
    if (!this.state.mfaChecked) {
      return <OuterPageBase cardElement={this.renderMfaPage()} />;
    }
    if (this.state.loggedInManager == null) {
      return (
        <OuterPageBase
          cardElement={this.renderClientSelectorPage()}
          cardClassName="accounts-card"
        />
      );
    }

    return (
      <MainRefContext.Provider value={this.mainRef}>
        <div className="App">
          <MessageComponent />
          {this.renderHeader()}
          {this.renderNavBar()}
          {this.renderBody()}
        </div>
      </MainRefContext.Provider>
    );
  }
}
