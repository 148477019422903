import React from "react";
import HomePage from "./Pages/HomePage";
import LocationsAdmin from "./Pages/LocationsAdmin";
import ProfileParentPage from "./Pages/ProfileParentPage";
import InvoicingDataPage from "./Pages/InvoicingDataPage";
import BareManagerAdmin from "./Pages/BareManagerAdmin";
import PricingsAdmin from "./Pages/PricingsAdmin";
import PurchaseOrderParentPage from "./Pages/PurchaseOrderParentPage";

// prettier-ignore
const menuitems = [
  {
    text: "Home",
    link: <HomePage />,
    icon: "fa fa-house",
    url: "home",
    file: "HomePage.js",
  },
  {
    text: "Locations",
    link: <LocationsAdmin />,
    icon: "fa-regular fa-location-dot",
    url: "locations",
    file: "LocationsAdmin.js",
  },
  {
    text: "Purchase Orders",
    link: <PurchaseOrderParentPage />,
    icon: "fas fa-store",
    url: "purchaseorders",
    file: "PurchaseOrderParentPage.js",
  },
  {
    text: "Services",
    link: <PricingsAdmin />,
    icon: "fa-solid fa-tags",
    url: "services",
    file: "PricingsAdmin.js",
  },
  {
    text: "Invoicing",
    link: <InvoicingDataPage />,
    icon: "fas fa-file-lines",
    url: "invoicingdata",
    file: "InvoicingDataPage.js",
    hide: manager =>
      !manager.collectInvoiceDataEnabled || manager.invoiceDataPerLocation,
  },
  {
    text: "Our team",
    link: <BareManagerAdmin />,
    icon: "fa-regular fa-users",
    url: "team",
    file: "BareManagerAdmin.js",
  },
  {
    text: "Profile",
    headerText: ProfileParentPage.getHeaderText,
    link: <ProfileParentPage />,
    icon: "fas fa-user",
    url: "profile",
    file: "ProfileParentPage.js",
    hide: true,
  },
];

export default menuitems;
