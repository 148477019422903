import AuthService from "./AuthService";

export default class CountryService {
  static countries = [{ countryId: 1, name: "United States" }];

  static async GetCountryList() {
    if (!this.countries || this.countries.length === 1) {
      CountryService.countries = await AuthService.CallBackendFunction(
        "/api/Country/GetCountriesAsync"
      );
    }
    return CountryService.countries;
  }
}
