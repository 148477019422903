import MessageComponent from "../Elements/MessageComponent";
import ButtonColorsEnum from "../Enums/ButtonColorsEnum";
import FieldTypesEnum from "../Enums/FieldTypesEnum";
import IconPositionEnum from "../Enums/IconPositionEnum";
import moneyHelper from "../Helpers/moneyHelper";
import urlHelper from "../Helpers/urlHelper";
import PurchaseOrderLinesAdmin from "../Pages/PurchaseOrderLinesAdmin";
import PurchaseOrderService from "../Services/PurchaseOrderService";
import PurchaseOrderLineForPOListItem from "./PurchaseOrderLineForPOListItem";

export default class PurchaseOrderDetailItem {
  constructor(item) {
    if (!item) {
      this.PurchaseOrderId = 0;
      this.QuantityOfShops = 0;
      this.TotalFee = 0;
      this.CurrencyCode = "";
      this.CreatedOn = "";
      this.DueDate = "";
      this.EarlyPaymentDiscountPercentage = 0;
      this.EarlyPaymentDiscountDueDate = "";
      this.CreatedByName = "";
      this.OwningOfficeAddressText = "";

      this.FirstName = "";
      this.LastName = "";
      this.CompanyName = "";
      this.Street = "";
      this.City = "";
      this.ZipCode = "";
      this.State = "";
      this.CountryName = "";
      this.VatNumber = "";
      this.InvoicingEmail = "";
      this.InternalReference = "";
      this.Ponumber = "";
      this.PocontactName = "";
      this.PocontactEmail = "";
      this.PurchaseOrderName = "Purchase Order";
      return;
    }

    this.PurchaseOrderId = item.purchaseOrderId;
    this.QuantityOfShops = item.quantityOfShops;
    this.TotalFee = item.totalFee;
    this.CurrencyCode = item.currencyCode;
    this.CreatedOn = item.createdOn;
    this.DueDate = item.dueDate;
    this.EarlyPaymentDiscountPercentage = item.earlyPaymentDiscountPercentage;
    this.EarlyPaymentDiscountDueDate = item.earlyPaymentDiscountDueDate;
    this.CreatedByName = item.createdByName;
    this.OwningOfficeAddressText = item.owningOfficeAddressText;

    this.FirstName = item.firstName;
    this.LastName = item.lastName;
    this.CompanyName = item.companyName;
    this.Street = item.street;
    this.City = item.city;
    this.ZipCode = item.zipCode;
    this.State = item.state;
    this.CountryName = item.countryName;
    this.VatNumber = item.vatNumber;
    this.InvoicingEmail = item.invoicingEmail;
    this.InternalReference = item.internalReference;
    this.Ponumber = item.ponumber;
    this.PocontactName = item.pocontactName;
    this.PocontactEmail = item.pocontactEmail;
    this.PurchaseOrderName = "Purchase Order";
  }

  static IdField = "PurchaseOrderId";
  static TitleField = "PurchaseOrderName";
  static DetailsServiceFunction = PurchaseOrderService.GetById;

  static fieldsToShow = [
    {
      tabName: "Details",
      type: "details",
      buttons: [
        {
          iconName: "fa-regular fa-file-pdf",
          tooltip: "Open PDF",
          color: ButtonColorsEnum.Primary,
          iconPosition: IconPositionEnum.Center,
          function: PurchaseOrderDetailItem.OpenPdf,
        },
      ],
      data: [
        {
          title: "Purchase Order Details",
          data: [
            { label: "Quantity Of Shops", value: "QuantityOfShops" },
            {
              label: "Total Fee",
              value: (item) =>
                moneyHelper.toMoneyFormat(item.TotalFee, item.CurrencyCode),
              type: FieldTypesEnum.Function,
            },
            {
              label: "Order Date",
              value: "CreatedOn",
              type: FieldTypesEnum.Date,
            },
            { label: "Due Date", value: "DueDate", type: FieldTypesEnum.Date },
            {
              label: "Early Payment Discount Percentage",
              value: (item) => `${item.EarlyPaymentDiscountPercentage}%`,
              type: FieldTypesEnum.Function,
            },
            {
              label: "Early Payment Due Date",
              value: "EarlyPaymentDiscountDueDate",
              type: FieldTypesEnum.Date,
            },
            { label: "Created By", value: "CreatedByName" },
            {
              label: "Office Billing Address",
              value: "OwningOfficeAddressText",
            },
          ],
        },
        {
          title: "Invoicing Data",
          data: [
            { label: "First Name", value: "FirstName" },
            { label: "Last Name", value: "LastName" },
            { label: "Company Name", value: "CompanyName" },
            { label: "Street", value: "Street" },
            { label: "City", value: "City" },
            { label: "Zip", value: "ZipCode" },
            { label: "State", value: "State" },
            { label: "Country", value: "CountryName" },
            { label: "VAT Number", value: "VatNumber" },
            { label: "Invoicing Email", value: "InvoicingEmail" },
            { label: "Internal Reference", value: "InternalReference" },
            { label: "PO Number", value: "Ponumber" },
            { label: "PO Contact Name", value: "PocontactName" },
            { label: "PO Contact Email", value: "PocontactEmail" },
          ],
        },
      ],
    },
    {
      tabName: "Purchase Order Lines",
      type: "component",
      data: PurchaseOrderLinesAdmin,
      itemType: PurchaseOrderLineForPOListItem,
    },
  ];

  static OpenPdf(item) {
    PurchaseOrderService.GetPurchaseOrderPdf(item.PurchaseOrderId)
      .then((result) => {
        const url = urlHelper.joinUrlParts([
          process.env.REACT_APP_BASE_URL,
          "api/File",
          result.result,
        ]);
        window.open(url, "_blank");
      })
      .catch((error) => {
        MessageComponent.SetErrorMessage("POLA69", error);
      });
  }
}
