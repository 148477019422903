import React from "react";
import FaIcon from "../Elements/FaIcon";

export default class urlHelper {
  static getProperUrl(url = "", params) {
    if (!url.startsWith("http")) url = `${window.location.origin}/${url}`;
    url += params ? `?${params}` : "";
    return url;
  }

  static addSearchParamsToUrl(searchItem, newSearchItem, urlMappedValues = []) {
    if (urlMappedValues === undefined) urlMappedValues = [];
    const searchElement = {};
    Object.keys(searchItem).forEach((key) => {
      if (key === "SingleSearchLineText") return;
      if (
        searchItem !== undefined &&
        newSearchItem !== undefined &&
        (searchItem[key] !== newSearchItem[key] ||
          urlMappedValues.includes(key))
      ) {
        if (Array.isArray(searchItem[key])) {
          if (
            JSON.stringify(searchItem[key]) !==
            JSON.stringify(newSearchItem[key])
          ) {
            searchElement[key] = searchItem[key];
          }
        } else {
          // M: Checks if the element is a date, and only adds it to the search bar if the value is more than 600, which means at least an hour had passed since the previous page load.
          if (
            !(
              searchItem[key] &&
              searchItem[key].getMonth &&
              typeof searchItem[key].getMonth === "function" &&
              newSearchItem[key] &&
              newSearchItem[key].getMonth &&
              typeof newSearchItem[key].getMonth === "function" &&
              (Date.parse(newSearchItem[key]) - Date.parse(searchItem[key])) /
                1000 <
                600
            )
          ) {
            searchElement[key] = searchItem[key];
          }
        }
      }
    });

    if (Object.keys(searchElement).length === 0) {
      urlHelper.removeSearchParamsFromUrl();
    } else {
      const newParameters = new URLSearchParams([
        ...Object.entries(searchElement),
      ]);
      if (window.location.pathname.includes("search")) {
        window.history.pushState(
          "",
          "",
          `${urlHelper.getProperUrl()}${
            window.location.pathname.split("/")[1]
          }/search?${newParameters}`
        );
      } else {
        window.history.pushState(
          "",
          "",
          `${window.location.pathname}/search?${newParameters}`
        );
      }
    }
  }

  static removeSearchParamsFromUrl(originalUrl = "") {
    const url = window.location.href;
    if (url.includes("/search?")) {
      window.history.pushState(
        "",
        "",
        originalUrl === ""
          ? `${urlHelper.getProperUrl()}${
              window.location.pathname.split("/")[1]
            }`
          : originalUrl
      );
    }
  }

  static generateLeadSearchRedirectUrl(path, paramName, value) {
    return `${urlHelper.getProperUrl()}${path}/search?${paramName}=${encodeURIComponent(
      value
    )}`;
  }

  static generateRedirectUrlWithMultipleParameters(path, parameters) {
    let url = `${urlHelper.getProperUrl()}${path}/search?`;

    parameters.forEach((param) => {
      url += `${param.key}=${param.value}&`;
    });

    return url.slice(0, -1);
  }

  static getLinkComponent(linktext, url, params, tooltip) {
    if (!url) url = linktext;
    const link = urlHelper.getProperUrl(url, params);
    return (
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        data-toggle="tooltip"
        data-placement="top"
        title={tooltip}
      >
        {linktext}
      </a>
    );
  }

  static getIconLinkComponent(
    linktext,
    iconName,
    className,
    url,
    params,
    tooltip
  ) {
    const link = urlHelper.getProperUrl(url, params);
    return (
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        data-toggle="tooltip"
        data-placement="top"
        title={tooltip}
      >
        <FaIcon iconName={iconName} className={className} text={linktext} />
      </a>
    );
  }

  static getIconLinkComponentFigma(
    linktext,
    iconName,
    className,
    url,
    params,
    tooltip
  ) {
    const link = urlHelper.getProperUrl(url, params);
    return (
      <a
        className="secondarylink"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        data-toggle="tooltip"
        data-placement="top"
        title={tooltip}
      >
        {linktext}
      </a>
    );
  }

  static isUrlOnWhiteList(url) {
    url = url.replace("http://", "").replace("https://", "");
    const startOfUrl = url.substring(0, url.indexOf("/"));
    return urlHelper.urlWhiteList.includes(startOfUrl);
  }

  static urlWhiteList = [
    "caspianstage.bareinternational.com",
    "caspian.bareinternational.com",
    "bravostage.bareinternational.com",
    "bravo.bareinternational.com",
    "bravowiki.bareinternational.com",
    "caspian2stage.bareinternational.com",
    "",
  ];

  static joinUrlParts(parts) {
    if (parts && parts.length > 0) {
      let completeString = "";
      for (let ind = 0; ind < parts.length; ind++) {
        const element = parts[ind];

        if (!element) continue;

        let endsWithSlash = completeString.endsWith("/");
        let startsWithSlash = element.startsWith("/");

        if (endsWithSlash && startsWithSlash) {
          completeString += element.substring(1);
        } else if (!endsWithSlash && !startsWithSlash && ind !== 0) {
          completeString += "/" + element;
        } else {
          completeString += element;
        }
      }
      return completeString;
    }
    return "";
  }
}
