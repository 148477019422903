import InputTypesEnum from "../Enums/InputTypesEnum";
import PricingService from "../Services/PricingService";

export default class PricingForSelectionItem {
  constructor(item) {
    if (item) {
      this.ServiceId = item.serviceId;
      this.PricingId = item.pricingId;
      this.ServiceDisplayServiceName = item.serviceDisplayServiceName;
      this.ClientFee = item.clientFee;
      this.CyclesPerYearLeft = item.cyclesPerYearLeft;
      this.ClientFeeCurrencyCode = item.clientFeeCurrencyCode;
      this.NumberOfFieldworkPeriods = item.cyclesPerYearLeft;
      this.Selected = false;
      this.ServiceIsMandatory = item.serviceIsMandatory;
      return;
    }
    this.ServiceId = 0;
    this.PricingId = 0;
    this.ServiceDisplayServiceName = "";
    this.ClientFee = 0;
    this.CyclesPerYearLeft = 0;
    this.NumberOfFieldworkPeriods = 0;
    this.ClientFeeCurrencyCode = "";
    this.Selected = false;
    this.ServiceIsMandatory = false;
  }

  static IdField = "ServiceId";
  static ShowIdInTitle = true;
  static ShowImage = false;
  static ListServiceFunction = PricingService.GetListForOrderLine;
  static DetailsEnabled = false;

  static FieldsToShowInList = [
    {
      label: PricingForSelectionItem.getSelectedCellHeader,
      data: PricingForSelectionItem.getSelectedCellContent,
      minWidth: 20,
    },
    {
      label: "Service",
      data: "ServiceDisplayServiceName",
      minWidth: 200,
    },
    {
      label: "Fieldwork Periods",
      data: "NumberOfFieldworkPeriods",
      inputType: InputTypesEnum.Number,
      validityChecker: PricingForSelectionItem.validateFieldWorkPeriods,
      minWidth: 50,
      disabled: item => !item.Selected,
    },
    {
      label: "Is Mandatory?",
      data: item => (item.ServiceIsMandatory ? "Yes" : "No"),
    },
  ];

  static validateFieldWorkPeriods(value, item) {
    if (!item.Selected) {
      return "";
    }

    if (!item.NumberOfFieldworkPeriods) {
      return "Fieldwork Period is required";
    }

    if (item.NumberOfFieldworkPeriods < 0) {
      return "Fieldwork Periods cannot be negative";
    }

    if (item.CyclesPerYearLeft < item.NumberOfFieldworkPeriods) {
      return "More Fieldwork Periods than you have available";
    }

    return "";
  }

  static getSelectedCellContent(item) {
    return (
      <input
        type="checkbox"
        checked={item.Selected}
        onChange={e => {
          PricingForSelectionItem.clickOnSelect(item, e.target.checked);
        }}
      />
    );
  }

  static getSelectedCellHeader(item) {
    return (
      <input
        type="checkbox"
        checked={PricingForSelectionItem.selectedStatus()}
        onChange={e => {
          PricingForSelectionItem.clickOnGlobalSelect(item, e.target.checked);
        }}
      />
    );
  }

  static clickOnGlobalSelect(item, selected) {
    // Placeholder, don't remove it!
  }

  static clickOnSelect(item, selected) {
    // Placeholder, don't remove it!
  }

  static selectedStatus() {
    return false;
  }
}
