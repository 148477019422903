import React from "react";
import CardImage from "./CardImage";
import { CardDetails } from "./CardDetails";
import { CardTitle } from "./CardTitle";
import "../../css/Card.css";

export default class Card extends React.Component {
  static defaultProps = {
    item: null,
    onClick: () => alert("Please implement onClick"),
    selected: false,
    detailsEnabled: true,
  };

  getClassName() {
    const className = `card-area card px-3 pt-3 m-4 text-start ${
      this.props.selected && "bg-gray text-white"
    } ${this.props.detailsEnabled && "cursor-pointer"}`;
    return className;
  }

  render() {
    return (
      <div
        className={this.getClassName()}
        onClick={() =>
          this.props.onClick(
            this.props.item[this.props.item.constructor.IdField]
          )
        }
      >
        <div className="row h-100">
          <CardImage item={this.props.item} />
          <div
            className={
              this.props.item.constructor.ShowImage
                ? "col-sm-12 col-md-9"
                : "col-12"
            }
          >
            <div className="card-body card-body-area">
              <CardTitle
                item={this.props.item}
                selected={this.props.selected}
              />
              <CardDetails item={this.props.item} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
