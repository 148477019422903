import InputTypesEnum from "../../Enums/InputTypesEnum";

export default class PurchaseOrderLineSearchParams {
  constructor() {
    this.initialize();
  }

  initialize() {
    this.LocationId = null;
    this.Status = "Created";
    this.PurchaseOrderLineId = "";
    this.ServiceName = "";
    this.OwningOfficeIds = [];
    this.PurchaseOrderId = null;
  }

  ClearAll() {
    this.initialize();
  }

  static fieldsInSearchBox = [
    {
      fieldName: "PurchaseOrderLineId",
      label: "PO Line ID",
      className: "col-4",
      type: InputTypesEnum.Number,
    },
    {
      fieldName: "ServiceName",
      label: "Service Name",
      className: "col-4",
      type: InputTypesEnum.Text,
    },
    {
      fieldName: "OwningOfficeIds",
      label: "Owning Office",
      className: "col-4",
      type: InputTypesEnum.OfficeMultiChoice,
    },
  ];

  static OrderByFields = [
    {
      label: "PO Line ID Asc.",
      data: "PurchaseOrderLineId_asc",
    },
    {
      label: "PO Line ID Desc.",
      data: "PurchaseOrderLineId_desc",
    },
    {
      label: "Service Name Asc.",
      data: "Pricing.Service.DisplayServiceName_asc",
    },
    {
      label: "Service Name Desc.",
      data: "Pricing.Service.DisplayServiceName_desc",
    },
    {
      label: "Owning Office Asc.",
      data: "Pricing.Service.OwningOffice_asc",
    },
    {
      label: "Owning Office Desc.",
      data: "Pricing.Service.OwningOffice_desc",
    },
  ];
}
