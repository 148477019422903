import React from "react";
import ClientLinkboxComponent from "./ClientLinkboxComponent";
import "../../css/ClientLinkboxPanelComponent.css";

class ClientLinkboxPanelComponent extends React.Component {
  static defaultProps = { clientLinkboxList: [] };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="container clientLinkboxContainer">
        <div className="row justify-content-md-center">
          {this.props.clientLinkboxList.map((link) => (
            <ClientLinkboxComponent
              key={link.clientLinkboxId}
              description={link}
            />
          ))}
        </div>
      </div>
    );
  }
}
export default ClientLinkboxPanelComponent;
