import React from "react";
import TranslationComponent from "../TranslationComponent";
import "../../css/DataTable.css";

export default class DataTableHeader extends React.Component {
  static defaultProps = {
    type: {
      FieldsToShowInList: [],
    },
    alternateShowInList: [],
  };

  GetHeaderCellContent(item) {
    let content = typeof item.label === "function" ? item.label() : item.label;
    content =
      typeof content === "string" ? (
        <TranslationComponent phrase={content} />
      ) : (
        content
      );
    return content;
  }
  renderTableHeaderCell(item, Lineindex) {
    const width = item.minWidth ? item.minWidth : 100;
    const tablewidth = item.tableminWidth ? item.tableminWidth : "";

    let content = this.GetHeaderCellContent(item);

    return (
      <th
        key={`th${Lineindex}`}
        style={{ backgroundColor: item.bgcolor, width: tablewidth }}
        className="tdclass tdTopclass"
      >
        <div className="figmaInline">
          <p className="DataTableHeaderSeparator" />
          <div
            className="datatableheader"
            style={{ minWidth: width }}
            key={`key${Lineindex}`}
          >
            <span className="tableheaderTex">{content}</span>
          </div>
        </div>
      </th>
    );
  }

  render() {
    const showInList =
      this.props.alternateShowInList ?? this.props.type.FieldsToShowInList;
    return (
      <thead key={"th2"}>
        <tr className={`FigmaDetailTableHeader`} key={"tr2"}>
          {showInList.map((item, Lineindex) =>
            this.renderTableHeaderCell(item, Lineindex)
          )}
        </tr>
      </thead>
    );
    //}
  }
}
