import React from "react";
import InputWithClear from "./Input/InputWithClear";
import FaIcon from "./FaIcon";
import MessageComponent from "./MessageComponent";
import InputTypesEnum from "../Enums/InputTypesEnum";

export default class DataWithInPlaceEdit extends React.Component {
  static defaultProps = {
    dataToShow: null,
    serviceFunction: null,
    value: null,
    fieldName: null,
    type: InputTypesEnum.Text,
    clickOnSave: null,
    editEnabled: true,
    editedItem: {},
    validityChecker: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      dataEdited: false,
      value: null,
      changed: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ changed: false });
    }
  }

  renderNotEdited() {
    return (
      <div>
        {this.state.changed
          ? this.state.value
          : this.props.dataToShow
            ? this.props.dataToShow
            : this.props.value}
        {this.props.serviceFunction && this.props.editEnabled && (
          <FaIcon
            iconName="fa fa-pencil"
            className="edit-icon mx-1 px-1 border rounded"
            onClick={() =>
              this.setState({ dataEdited: true, value: this.props.value })
            }
          />
        )}
      </div>
    );
  }

  renderCancelButton() {
    return (
      <button className="btn btn-sm btn-danger m-1" type="reset">
        <FaIcon iconName="fa fa-times" />
      </button>
    );
  }

  renderOkButton() {
    return (
      <button
        className="btn btn-sm btn-success m-1"
        disabled={
          typeof this.props.validityChecker === "function" &&
          !!this.props.validityChecker(this.state.value, this.props.editedItem)
        }
        type="submit"
      >
        <FaIcon iconName="fa fa-check" />
      </button>
    );
  }

  handleOnSubmit() {
    this.setState({
      dataEdited: false,
      changed: true,
    });
    this.props
      .serviceFunction(
        this.props.fieldName,
        this.state.value,
        this.props.editedItem
      )
      .then(result => {
        if (result.error) {
          MessageComponent.SetErrorMessage(result.error);
          return;
        }
        this.setState({
          value: result.dataToShow ? result.dataToShow : this.state.value,
        });
        this.props.clickOnSave(
          this.props.fieldName,
          result.dataToShow ? result.dataToShow : this.state.value,
          !!this.props.dataToShow
        );
      })
      .catch(error => {
        if (error?.message) {
          MessageComponent.SetErrorMessage(error?.message);
          return;
        }
        MessageComponent.SetErrorMessage("DWIPE88", error);
      });
  }

  handleOnReset() {
    this.setState({ dataEdited: false });
  }

  renderEdited() {
    return (
      <div className="text-center">
        <form
          onSubmit={e => {
            e.preventDefault();
            this.handleOnSubmit();
          }}
          onReset={e => this.handleOnReset()}
        >
          <InputWithClear
            type={this.props.type}
            value={this.state.value}
            labelIsDisabled={true}
            onChange={(field, newValue) => this.setState({ value: newValue })}
            validityChecker={this.props.validityChecker}
            fieldName={this.props.fieldName}
          />
          {this.renderCancelButton()}
          {this.renderOkButton()}
        </form>
      </div>
    );
  }

  render() {
    if (!this.state.dataEdited) {
      return this.renderNotEdited();
    }
    return this.renderEdited();
  }
}
