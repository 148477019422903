import ServiceHelper from "../Helpers/ServiceHelper.js";
import AuthService from "./AuthService.js";

export default class PricingService {
  static GetList(
    orderBy,
    orderDir,
    pageNumber,
    numberOfElementsOnAPage,
    searchItem
  ) {
    const searchParamsString = ServiceHelper.GetSearchParamsString(
      orderBy,
      orderDir,
      pageNumber,
      numberOfElementsOnAPage,
      searchItem
    );

    return AuthService.CallBackendFunction(
      `/api/Service_GetPricingList/${searchParamsString}`
    );
  }

  static GetById(id) {
    return AuthService.CallBackendFunction(`/api/Service_GetPricingById/${id}`);
  }

  static GetListForOrderLine(
    orderBy,
    orderDir,
    pageNumber,
    numberOfElementsOnAPage,
    searchItem
  ) {
    const searchParamsString = ServiceHelper.GetSearchParamsString(
      orderBy,
      orderDir,
      pageNumber,
      numberOfElementsOnAPage,
      searchItem
    );

    return AuthService.CallBackendFunction(
      `/api/Service_GetPricingListForOrderLine/${searchParamsString}`
    );
  }
}
