import InputTypesEnum from "../../Enums/InputTypesEnum";

export default class PurchaseOrderSearchParams {
  constructor() {
    this.initialize();
  }

  initialize() {
    this.PurchaseOrderId = "";
    this.ClientPoNumber = "";
    this.OwningOfficeIds = [];
  }

  ClearAll() {
    this.initialize();
  }

  static fieldsInSearchBox = [
    {
      fieldName: "PurchaseOrderId",
      label: "Purchase Order ID",
      className: "col-4",
      type: InputTypesEnum.Number,
    },
    {
      fieldName: "ClientPoNumber",
      label: "Client PO Number",
      className: "col-4",
      type: InputTypesEnum.Text,
    },
    {
      fieldName: "OwningOfficeIds",
      label: "OwningOfficeIds",
      className: "col-4",
      type: InputTypesEnum.OfficeMultiChoice,
    },
  ];

  static OrderByFields = [
    {
      label: "Purchase Order ID Asc.",
      data: "PurchaseOrderId_asc",
    },
    {
      label: "PO Line ID Desc.",
      data: "PurchaseOrderId_desc",
    },
    {
      label: "Owning Office Asc.",
      data: "OwningOffice.Name_asc",
    },
    {
      label: "Owning Office Desc.",
      data: "OwningOffice.Name_desc",
    },
  ];
}
