import React from "react";
import PopupTypesEnum from "../Enums/PopupTypesEnum";
import WarningTypesEnum from "../Enums/WarningTypesEnum";
import "../css/Figma.css";
import TranslationComponent from "./TranslationComponent";

export default class FigmaPopup extends React.Component {
  static defaultProps = {
    type: PopupTypesEnum.Attention,
    text: "defaultText",
    textForReason: "Enter reason for converting",
    okButtonText: "",
    warningType: WarningTypesEnum.WithComment,
    cancelButtonText: "Cancel",
    okPopupFunction: () => alert("Please, create the okPopupFunction"),
    closePopupFunction: () => alert("Please, create the closePopupFunction"),
    placeholder: "Enter your reason...",
    iconName: "",
    headerText: "",
    reasonInRequiredInWarningType: false,
    // pageCount: 20,
    // allCount: 200,
    overButtonText: "",
    authenticate: true,
    textIsHtml: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      okButtonText: this.getOkButtonText(props),
      iconboxClassName: this.getIconboxClassName(props),
      headerClassName: this.getHeaderClassName(props),
      iconName: this.getIconName(props),
      headerText: this.getHeaderText(props),
      reason: "",
      buttonRow: null,
    };
    this.keyDownHandler = this.keyDownHandler.bind(this);
  }

  componentDidMount() {
    this.setState({ buttonRow: this.renderButtonRow() });
    document.addEventListener("keydown", this.keyDownHandler, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDownHandler, false);
  }

  keyDownHandler(event) {
    if (event.keyCode === 13) {
      this.props.okPopupFunction();
    }
    if (event.keyCode === 27) {
      this.props.closePopupFunction();
    }
  }

  getHeaderText(props) {
    if (props.headerText) return props.headerText;
    switch (props.type) {
      //case PopupTypesEnum.Approve:
      case PopupTypesEnum.Attention:
        return "Attention";
      case PopupTypesEnum.Success:
        return "Success!";
      case PopupTypesEnum.Warning:
        return "Warning!";
      // case PopupTypesEnum.Save:
      //   return "Save Settings";
      // case PopupTypesEnum.Select:
      //   return "Select all items";
      case PopupTypesEnum.Info:
        return "Info";
      default:
        return "Wrong Type";
    }
  }

  getIconboxClassName(props) {
    switch (props.type) {
      //case PopupTypesEnum.Approve:
      case PopupTypesEnum.Attention:
        return "figmapopupiconbox attentioniconbox";
      case PopupTypesEnum.Success:
        return "figmapopupiconbox successiconbox";
      case PopupTypesEnum.Warning:
        return "figmapopupiconbox warningiconbox";
      // case PopupTypesEnum.Save:
      //   return "figmapopupiconbox attentioniconbox";
      // case PopupTypesEnum.Select:
      //   return "figmapopupiconbox attentioniconbox";
      case PopupTypesEnum.Info:
        return "figmapopupiconbox infoiconbox";
      default:
        return "Wrong Type";
    }
  }

  getHeaderClassName(props) {
    switch (props.type) {
      //case PopupTypesEnum.Approve:
      case PopupTypesEnum.Attention:
        return "attentionheader";
      case PopupTypesEnum.Success:
        return "successheader";
      case PopupTypesEnum.Warning:
        return "warningheader";
      // case PopupTypesEnum.Save:
      //   return "attentionheader";
      // case PopupTypesEnum.Select:
      //   return "attentionheader";
      case PopupTypesEnum.Info:
        return "infoheader";
      default:
        return "Wrong Type";
    }
  }

  getIconName(props) {
    if (props.iconName) return props.iconName;
    switch (props.type) {
      //case PopupTypesEnum.Approve:
      //case PopupTypesEnum.Select:
      case PopupTypesEnum.Attention:
      case PopupTypesEnum.Info:
        return "fa-solid fa-circle-info";
      case PopupTypesEnum.Success:
        return "fa-solid fa-circle-check";
      case PopupTypesEnum.Warning:
        return "fa-solid fa-circle-exclamation";
      // case PopupTypesEnum.Save:
      //   return "fa-solid fa-folder-plus";
      default:
        return "fa-solid fa-ban-bug";
    }
  }

  getOkButtonText(props) {
    if (props.okButtonText) return props.okButtonText;
    switch (props.type) {
      case PopupTypesEnum.Attention:
        return "YES";
      case PopupTypesEnum.Success:
        return "I understood";
      case PopupTypesEnum.Warning:
        return "Send";
      // case PopupTypesEnum.Save:
      //   return "Save";
      // case PopupTypesEnum.Select:
      //   return "All";
      default:
        return "Wrong Type";
    }
  }

  renderButtonRow() {
    switch (this.props.type) {
      // case PopupTypesEnum.Select:
      //   return (
      //     <div className="row">
      //       <div className="col-12 ">
      //         <div className="col-12 text-center oswaldtext p-4">
      //           HOW DO YOU WANT TO CONTINUE?
      //         </div>
      //       </div>
      //       <div
      //         className="col-4 pb-3 mt-3 text-center"
      //         style={{
      //           visibility: this.props.cancelButtonText ? "visible" : "hidden",
      //         }}
      //         onClick={this.props.closePopupFunction}
      //       >
      //         {this.props.cancelButtonText ? (
      //           <button className="figmabuttondestroy destroy smallnormal">
      //             {this.props.cancelButtonText}
      //           </button>
      //         ) : (
      //           " "
      //         )}
      //       </div>
      //       <div
      //         className="col-4 mt-3 text-center"
      //         onClick={() => this.props.okPopupFunction()}
      //       >
      //         <button className="p-3 figmabutton secondary smallnormal savepanelsavebutton">
      //           Page only ({this.props.pageCount})
      //         </button>
      //       </div>
      //       <div
      //         className="col-4 mt-3 text-center"
      //         onClick={() => this.props.okPopupFunction()}
      //       >
      //         <button className="p-3 figmabutton secondary smallnormal savepanelsaveandusebutton">
      //           {this.state.okButtonText} ({this.props.allCount})
      //         </button>
      //       </div>
      //     </div>
      //   );
      // case PopupTypesEnum.Save:
      //   return (
      //     <div className="row">
      //       <div className="col-12 ">
      //         <label
      //           for="username"
      //           className="oswaldtext text-left savinglabel"
      //         >
      //           Saving as
      //         </label>
      //         <input
      //           type="text"
      //           id="username"
      //           name="username"
      //           className="oswaldtext figmainput mr-2 text-left savepanelinput"
      //           maxlength="1"
      //           size="1"
      //           placeholder=" Type the filter name..."
      //         />
      //       </div>
      //       <div
      //         className="col-4 pb-3 mt-3 text-center"
      //         style={{
      //           visibility: this.props.cancelButtonText ? "visible" : "hidden",
      //         }}
      //         onClick={this.props.closePopupFunction}
      //       >
      //         {this.props.cancelButtonText ? (
      //           <button className="figmabuttondestroy destroy smallnormal">
      //             {this.props.cancelButtonText}
      //           </button>
      //         ) : (
      //           " "
      //         )}
      //       </div>
      //       <div
      //         className="col-4 mt-3 text-center"
      //         onClick={() => this.props.okPopupFunction()}
      //       >
      //         <button className="p-3 figmabutton secondary smallnormal savepanelsavebutton">
      //           {this.state.okButtonText}
      //         </button>
      //       </div>
      //       <div
      //         className="col-4 mt-3 text-center"
      //         onClick={() => this.props.okPopupFunction()}
      //       >
      //         <button className="p-3 figmabutton secondary smallnormal savepanelsaveandusebutton">
      //           Save & Use
      //         </button>
      //       </div>
      //     </div>
      //   );
      case PopupTypesEnum.Attention:
        return (
          <div className="row">
            <div className="col-12 text-center oswaldtext p-4">
              <TranslationComponent
                phrase={
                  this.props.overButtonText
                    ? this.props.overButtonText
                    : "DO YOU WANT TO CONTINUE?"
                }
                authenticate={this.props.authenticate}
              />
            </div>
            <div className="col-3" />
            <div
              className="col-3 pb-3 d-flex justify-content-center"
              style={{
                visibility: this.props.cancelButtonText ? "visible" : "hidden",
              }}
              onClick={this.props.closePopupFunction}
            >
              <button className="figmabuttondestroy destroy smallnormal">
                <TranslationComponent
                  phrase={
                    this.props.cancelButtonText
                      ? this.props.cancelButtonText
                      : "NO"
                  }
                  authenticate={this.props.authenticate}
                />
              </button>
            </div>
            <div
              className="col-3 pb-3 d-flex justify-content-center"
              onClick={() => this.props.okPopupFunction()}
            >
              <button className="figmabutton secondary smallnormal">
                <TranslationComponent
                  phrase={
                    this.props.cancelButtonText
                      ? this.props.okButtonText
                      : "YES"
                  }
                  authenticate={this.props.authenticate}
                />
              </button>
            </div>
            <div className="col-3" />
          </div>
        );
      // case PopupTypesEnum.Approve:
      //   return (
      //     <div className="row">
      //       <div className="col-12 text-center oswaldtext p-4">
      //         DO YOU WANT TO CONTINUE?
      //       </div>
      //       &nbsp;
      //       <div className="col-3" />
      //       <div
      //         className="col-3 p-1 pb-3"
      //         style={{
      //           visibility: this.props.cancelButtonText ? "visible" : "hidden",
      //         }}
      //         onClick={this.props.closePopupFunction}
      //       >
      //         {this.props.cancelButtonText ? (
      //           <button className="mr-2 figmabuttondestroy destroy smallnormal">
      //             NO
      //           </button>
      //         ) : (
      //           " "
      //         )}
      //       </div>
      //       <div
      //         className="col-3 p-1 pointer"
      //         onClick={() => this.props.okPopupFunction()}
      //       >
      //         <button className="figmabutton secondary smallnormal p-3 pointer">
      //           YES
      //         </button>
      //       </div>
      //       <div className="col-3" />
      //     </div>
      //   );
      case PopupTypesEnum.Success:
        return (
          <div className="d-flex justify-content-center">
            <button
              className="my-4 figmabutton secondary smallnormal"
              onClick={() => this.props.okPopupFunction()}
              target="_blank"
            >
              <TranslationComponent
                phrase="Ok"
                authenticate={this.props.authenticate}
              />
            </button>
          </div>
        );
      case PopupTypesEnum.Warning:
        switch (this.props.warningType) {
          case WarningTypesEnum.WithComment:
            return (
              <div className="row">
                <div className="col-12">
                  <label
                    htmlFor="story"
                    className="oswaldtext mt-4 text-centered"
                  >
                    <TranslationComponent
                      phrase={this.props.textForReason}
                      authenticate={this.props.authenticate}
                    />
                  </label>
                </div>
                <div className="col-12">
                  <textarea
                    id="story"
                    name="story"
                    className="figmatextarea warningpopupinputtext"
                    value={this.state.reason}
                    placeholder={this.props.placeholder}
                    onChange={(e) => this.setState({ reason: e.target.value })}
                  />
                </div>
                <div className="col-1" />
                <div
                  className="col-3 my-3 d-flex justify-content-center"
                  style={{
                    visibility: this.props.cancelButtonText
                      ? "visible"
                      : "hidden",
                  }}
                  onClick={this.props.closePopupFunction}
                >
                  {this.props.cancelButtonText ? (
                    <button className="figmabuttondestroy destroy smallnormal">
                      <TranslationComponent
                        phrase={this.props.cancelButtonText}
                        authenticate={this.props.authenticate}
                      />
                    </button>
                  ) : (
                    " "
                  )}
                </div>
                <div className="col-4" />
                <div
                  className="col-3 my-3 d-flex justify-content-center"
                  onClick={() => this.props.okPopupFunction(this.state.reason)}
                >
                  <button
                    className="figmabutton secondary smallnormal"
                    disabled={
                      this.props.reasonInRequiredInWarningType &&
                      !this.state.reason
                    }
                  >
                    <TranslationComponent
                      phrase={this.state.okButtonText}
                      authenticate={this.props.authenticate}
                    />
                  </button>
                </div>
                <div className="col-1" />
              </div>
            );
          // case WarningTypesEnum.WithTwoButtons:
          //   return (
          //     <div className="row">
          //       <div className="col-12 text-center oswaldtext p-4">
          //         Do you want to continue?
          //       </div>
          //       &nbsp;
          //       <div className="col-3" />
          //       <div
          //         className="col-3 p-1 pb-3"
          //         style={{
          //           visibility: this.props.cancelButtonText
          //             ? "visible"
          //             : "hidden",
          //         }}
          //         onClick={this.props.closePopupFunction}
          //       >
          //         {this.props.cancelButtonText ? (
          //           <button className="mr-2 figmabuttondestroy destroy smallnormal">
          //             {this.props.cancelButtonText}
          //           </button>
          //         ) : (
          //           " "
          //         )}
          //       </div>
          //       <div
          //         className="col-3 p-1 pointer"
          //         onClick={() => this.props.okPopupFunction()}
          //       >
          //         <button className="figmabutton secondary smallnormal p-3 pointer">
          //           {this.state.okButtonText}
          //         </button>
          //       </div>
          //       <div className="col-3" />
          //     </div>
          //   );
          case WarningTypesEnum.WithOneButton:
            return (
              <div className="d-flex justify-content-center">
                <button
                  className="my-4 figmabutton secondary smallnormal"
                  onClick={() => this.props.okPopupFunction()}
                >
                  <TranslationComponent
                    phrase="Ok"
                    authenticate={this.props.authenticate}
                  />
                </button>
              </div>
            );
          default:
            return null;
        }
      case PopupTypesEnum.Info:
        return (
          <div className="d-flex justify-content-center">
            <button
              type="button"
              className="my-4 figmabuttoncommon common smallnormal"
              onClick={() => this.props.okPopupFunction()}
            >
              <TranslationComponent
                phrase="Close"
                authenticate={this.props.authenticate}
              />
            </button>
          </div>
        );
      default:
        return null;
    }
  }

  renderText() {
    if (this.props.type === PopupTypesEnum.Info) {
      if (this.props.textIsHtml) {
        return (
          <p
            style={{ whiteSpace: "pre-line" }}
            className="border p-2 bg-white text-dark"
            dangerouslySetInnerHTML={{ __html: this.props.text }}
          />
        );
      }
      return (
        <p
          style={{ whiteSpace: "pre-line" }}
          className="border p-2 bg-white text-dark info-text"
        >
          <TranslationComponent
            phrase={this.props.text}
            authenticate={this.props.authenticate}
          />
        </p>
      );
    }
    return (
      <p className="oswaldtextlowercase">
        <TranslationComponent
          phrase={this.props.text}
          authenticate={this.props.authenticate}
        />
      </p>
    );
  }

  render() {
    return (
      <div className="confirmationPopup">
        <div className="figmaPopup_inner popupslide">
          <div className="container-fluid">
            <div className={`row ${this.state.headerClassName}`}>
              <div className={`col-2 ${this.state.iconboxClassName}`}>
                <h1>
                  <i className={this.state.iconName} />
                </h1>
              </div>
              {/* {this.props.type === PopupTypesEnum.Save ? (
                <div className="col-10">
                  <h5 className="oswaldtext figmasavetitle">
                    {this.state.headerText}
                  </h5>
                </div>
              ) : (
                <div className="col-10">
                  <h5 className="oswaldtext figmapopuptitle">
                    {this.state.headerText}
                  </h5>
                  <br />
                  {this.renderText()}
                </div>
              )} */}
              <div className="col-10">
                <h5 className="oswaldtext figmapopuptitle">
                  <TranslationComponent
                    phrase={this.state.headerText}
                    authenticate={this.props.authenticate}
                  />
                </h5>
                <br />
                {this.renderText()}
              </div>
            </div>
            {this.renderButtonRow()}
          </div>
        </div>
      </div>
    );
  }
}
