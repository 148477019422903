import React from "react";
import GeneralItemList from "../CardList/GeneralItemList";
import GeneralEditWizzardPageBase from "./GeneralEditWizzardPageBaseFigma";

export default class SelectionWizzardPage extends GeneralEditWizzardPageBase {
  //common defaultProps are defined in GeneralEditWizzardPageBase
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      searchItem: new props.windowDescription.searchParamsType(
        this.props.editedItem
      ),
    };

    this.startSearch = this.startSearch.bind(this);
    this.onListChange = this.onListChange.bind(this);
  }

  startSearch(searchItem) {
    let baseItem = {};

    const newItem = new this.props.windowDescription.searchParamsType();
    newItem.SingleSearchLineText = "";

    if (JSON.stringify(searchItem) === JSON.stringify(newItem)) {
      baseItem = new this.props.windowDescription.searchParamsType(
        this.props.editedItem
      );
    }

    this.setState({ searchItem: { ...searchItem, ...baseItem } });
  }

  onListChange(newItemList) {
    const currentPage =
      this.state.windowDescription.pagesInGeneralEditWizzard[this.props.step];

    const selected = newItemList.filter(x => x.Selected === true);
    this.changeOfEditedItem(currentPage.selectField, selected);
  }

  //#region Override Functions
  validityCheckerForTheEntireForm() {
    const currentPage =
      this.state.windowDescription.pagesInGeneralEditWizzard[this.props.step];

    if (
      !this.state.editedItem[currentPage.selectField] ||
      this.state.editedItem[currentPage.selectField].length === 0
    ) {
      return false;
    }

    for (const field of currentPage.fields) {
      if (
        field.hide !== undefined &&
        field.hide(this.state.editedItem) === true
      ) {
      } else {
        if (!field.validityChecker) {
          continue;
        }

        for (const row of this.state.editedItem[currentPage.selectField]) {
          const validation = field.validityChecker(row[field.fieldName], row);
          if (validation !== "" && validation !== undefined) return false;
        }
      }
    }

    return true;
  }

  renderFormData() {
    const currentPage =
      this.state.windowDescription.pagesInGeneralEditWizzard[this.props.step];
    return (
      <GeneralItemList
        itemType={this.props.windowDescription.itemType}
        detailsItemType={this.props.windowDescription.detailsItemType}
        searchItem={this.state.searchItem}
        searchParamsType={this.props.windowDescription.searchParamsType}
        searchStripVisible={currentPage.isSelection}
        clickOnStartSearch={searchItem => this.startSearch(searchItem)}
        cardList={false}
        inputFieldOnChange={this.changeOfEditedItem}
        selectButtonDisabled={true}
        alternateShowInList={currentPage.fields}
        onItemListChange={newItemList => this.onListChange(newItemList)}
        filterToSelected={currentPage.isSelection === false}
        numberOfElementsOnAPage={9999}
        paginationVisible={false}
      />
    );
  }
  //#endregion
}
