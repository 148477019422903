import React from "react";
import "../../css/OuterPage.css";
import LoadingFullScreen from "../../Elements/Loading/LoadingFullScreen";
import LoginService from "../../Services/LoginService";
import OuterPageCard from "../../Elements/OuterPageCard";
import TranslationComponent from "../../Elements/TranslationComponent";
import CookieHelper from "../../Helpers/cookieHelper";

export default class ForgottenPasswordPage extends React.Component {
  static defaultProps = {
    handleSubmit: () => {},
    email: "",
  };

  constructor(props) {
    super(props);

    this.state = {
      email: this.props.email,
      loading: false,
      emailSent: false,
      error: false,
    };
  }

  componentDidMount() {}

  async handleSubmit() {
    try {
      this.setState({ loading: true });
      await LoginService.SubmitEmailForNewPassword(
        this.state.email,
        CookieHelper.getCookie("language", "1")
      );
      this.setState({ emailSent: true, loading: false });
    } catch (e) {
      console.log(e);
      this.setState({ loading: false, emailSent: false, error: true });
    }
  }

  render() {
    if (this.state.loading) return <LoadingFullScreen />;
    if (this.state.emailSent)
      return (
        <OuterPageCard showBackArrow={true}>
          <h4>
            <TranslationComponent
              phrase="Reset Password"
              authenticate={false}
            />
          </h4>
          <span>
            <TranslationComponent
              phrase="An email has been sent. Please check your email account to reset your password."
              authenticate={false}
            />
          </span>
        </OuterPageCard>
      );
    if (this.state.error)
      return (
        <OuterPageCard showBackArrow={true}>
          <h4>
            <TranslationComponent
              phrase="Reset Password"
              authenticate={false}
            />
          </h4>
          <span>
            <TranslationComponent
              phrase="Something went wrong. Please try again."
              authenticate={false}
            />
          </span>
        </OuterPageCard>
      );
    return (
      <OuterPageCard showBackArrow={true}>
        <h4>
          <TranslationComponent
            phrase="Reset Password"
            authenticate={false}
          />
        </h4>
        <span>
          <TranslationComponent
            phrase="Please enter your email below to reset your password."
            authenticate={false}
          />
        </span>
        <div className="input-container">
          <input
            placeholder="Email"
            className="text-center"
            value={this.state.email}
            type="text"
            onChange={(e) => this.setState({ email: e.target.value })}
          />
        </div>

        <button
          onClick={() => {
            this.setState({ loading: true });
            this.handleSubmit();
          }}
          className="outer-button"
        >
          <TranslationComponent phrase="Submit" authenticate={false} />
        </button>
      </OuterPageCard>
    );
  }
}
