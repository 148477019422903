import AuthService from "../Services/AuthService";
import LocationService from "../Services/LocationService";

export default class LocationListItem {
  constructor(item) {
    if (!item) {
      this.LocationId = 0;
      this.ClientLocationId = 0;
      this.ClientLocationName = "";
      this.CountryCode = "";
      this.City = "";
      this.IsActive = false;
      this.IsMissingData = true;
      this.Confirmed = false;
      return;
    }

    this.LocationId = item.locationId;
    this.ClientLocationId = item.clientLocationId;
    this.ClientLocationName = item.clientLocationName;
    this.City = item.city;
    this.CountryCode = item.countryCode;
    this.IsActive = item.isActive;
    this.IsActiveText = item.isActive ? "Not Active" : "Active";
    this.IsMissingData = item.isMissingData;
    this.Confirmed = item.confirmed;
  }

  static IdField = "LocationId";
  static TitleField = "ClientLocationName";
  static ShowIdInTitle = false;
  static ShowImage = false;
  static ListServiceFunction = LocationService.GetLocationList;
  static DetailsEnabled = true;
  static SingleSearchLine = true;

  static FieldsToShowInList = [
    {
      label: "Client Location Id",
      data: "ClientLocationId",
    },
    {
      label: "City",
      data: "City",
    },
    {
      label: "Country Code",
      data: "CountryCode",
    },
    {
      label: "Is Active",
      data: "IsActiveText",
    },
    {
      label: item =>
        item.IsMissingData
          ? "Invoicing data incomplete"
          : "Invoicing data complete",
      iconName: item =>
        item.IsMissingData
          ? "fa-regular fa-square-xmark color-danger fa-lg"
          : "fa-regular fa-square-check color-success fa-lg",
      data: "IsMissingData",
      hide: item => {
        const loggedInClientManager =
          AuthService.getInstance().GetLoggedInManager();
        return (
          !loggedInClientManager.collectInvoiceDataEnabled ||
          !loggedInClientManager.invoiceDataPerLocation
        );
      },
    },
    {
      label: item => (item.Confirmed ? "Confirmed" : "Not Confirmed"),
      iconName: item =>
        item.Confirmed
          ? "fa-regular fa-badge-check color-success fa-lg"
          : "fa-regular fa-seal-exclamation color-danger fa-lg",
      data: "Confirmed",
    },
  ];
}
