export default class PurchaseOrderData {
  constructor(selectedItems, locations = [], item = null) {
    this.SelectedLocations = locations;
    this.SelectedItems = selectedItems;

    if (item) {
      this.LocationId = item.locationId;
      this.CxClientId = item.cxClientId;
      this.LocationInvoicingDataId = item.locationInvoicingDataId;
      this.FirstName = item.firstName;
      this.LastName = item.lastName;
      this.CompanyName = item.companyName;
      this.Street = item.street;
      this.City = item.city;
      this.ZipCode = item.zipCode;
      this.State = item.state;
      this.CountryName = item.countryName;
      this.CountryId = item.countryId;
      this.VatNumber = item.vatNumber;
      this.InvoicingEmail = item.invoicingEmail;
      this.InternalReference = item.internalReference;
      this.Ponumber = item.ponumber;
      this.PocontactName = item.pocontactName;
      this.PocontactEmail = item.pocontactEmail;
      this.PaymentTermsDays = item.cxClientPaymentTermsDays ?? 0;
      this.EarlyPaymentDays = item.cxClientEarlyPaymentDays ?? 0;
      this.EarlyPaymentDiscountPercentage =
        item.cxClientEarlyPaymentDiscountPercentage ?? 0;
      return;
    }
    this.LocationId = 0;
    this.CxClientId = 0;
    this.LocationInvoicingDataId = 0;
    this.FirstName = "";
    this.LastName = "";
    this.CompanyName = "";
    this.Street = "";
    this.City = "";
    this.ZipCode = "";
    this.State = "";
    this.CountryName = "";
    this.CountryId = 0;
    this.VatNumber = "";
    this.InvoicingEmail = "";
    this.InternalReference = "";
    this.Ponumber = "";
    this.PocontactName = "";
    this.PocontactEmail = "";
    this.PaymentTermsDays = 0;
    this.EarlyPaymentDays = 0;
    this.EarlyPaymentDiscountPercentage = 0;
  }
}
