import React from "react";
import AuthService from "../../Services/AuthService";
import Link from "../Link";
import ProfileParentPage from "../../Pages/ProfileParentPage";
import TranslationComponent from "../TranslationComponent";

export default class ProfileDropdown extends React.Component {
  static defaultProps = {
    handleLogout: () => {},
  };

  constructor(props) {
    super(props);
    const loggedInManager = AuthService.getInstance().GetLoggedInManager();
    this.state = {
      loggedInManager,
    };
  }

  renderUserDisplay() {
    return (
      <div className="px-1 text-end">
        {this.state.loggedInManager.cxClientDisplayName}
        <br />
        {`${this.state.loggedInManager.firstName} ${this.state.loggedInManager.lastName}`}
      </div>
    );
  }

  renderProfileButton() {
    return (
      <Link
        className="dropdown-item header-dropdown-button px-1"
        linktext="Profile"
        link={<ProfileParentPage />}
        url="profile"
        title="Profile"
        headerText={ProfileParentPage.getHeaderText}
        iconName="fas fa-user"
        iconInLinktext={false}
      />
    );
  }

  renderLogoutButton() {
    return (
      <div
        className="dropdown-item header-dropdown-button px-1"
        onClick={() => {
          AuthService.getInstance().Logout();
          this.props.handleLogout();
          this.setState({
            loggedInManager: null,
          });
        }}
      >
        <TranslationComponent phrase="Logout" loadingEnabled={false} />
      </div>
    );
  }

  render() {
    return (
      <div className="dropdown">
        <div
          className="dropdown-toggle header-button"
          data-bs-toggle="dropdown"
        >
          <i className="fas fa-user h3 m-0" />
        </div>
        <div className="dropdown-menu">
          {this.renderUserDisplay()}
          <div className="dropdown-divider" />
          {this.renderProfileButton()}
          {this.renderLogoutButton()}
        </div>
      </div>
    );
  }
}
