import React from "react";
import PurchaseOrderSearchParams from "../Contents/SearchParams/PurchaseOrderSearchParams";
import GeneralItemList from "../Elements/CardList/GeneralItemList";
import PurchaseOrderListItem from "../Domain/PurchaseOrderListItem";
import PurchaseOrderDetailItem from "../Domain/PurchaseOrderDetailItem";

export default class PurchaseOrdersAdmin extends React.Component {
  static defaultProps = {};

  constructor(props) {
    super(props);
    this.SearchParamsType = PurchaseOrderSearchParams;
    this.state = {
      searchItem: new this.SearchParamsType(),
    };
  }

  render() {
    return (
      <GeneralItemList
        itemType={PurchaseOrderListItem}
        detailsItemType={PurchaseOrderDetailItem}
        searchItem={this.state.searchItem}
        searchParamsType={this.SearchParamsType}
        clickOnStartSearch={(searchItem) =>
          this.setState({ searchItem: { ...searchItem } })
        }
      />
    );
  }
}
