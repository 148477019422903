import PhraseService from "../Services/PhraseService";
import CookieHelper from "./cookieHelper";

export default class TranslateTool {
  static phrases = [];

  static isGettingAll = false;

  static language = CookieHelper.getCookie("language", "1");

  static async Translate(textToTranslate, authenticate = true) {
    if (!TranslateTool.language) {
      TranslateTool.language = CookieHelper.getCookie("language", "1");
    }

    if (
      TranslateTool.phrases.length === 0 &&
      TranslateTool.language !== "1" &&
      !TranslateTool.isGettingAll
    ) {
      TranslateTool.isGettingAll = true;
      const translatedArray = await PhraseService.GetAllTranslatedPhrasesAsync(
        TranslateTool.language
      );

      const newArray = [];
      translatedArray.forEach(element => {
        newArray[element.phrase] = element;
      });
      TranslateTool.phrases = newArray;
    }

    const translatedPhrase = TranslateTool.phrases[textToTranslate];

    if (translatedPhrase) {
      return translatedPhrase;
    }

    if (TranslateTool.language === "1") {
      const translated = {
        phrase: textToTranslate,
        translated: textToTranslate,
      };
      TranslateTool.phrases[textToTranslate] = translated;

      return translated;
    }

    const translated = await PhraseService.GetTranslatedPhraseAsync(
      {
        text: textToTranslate,
        languageId: TranslateTool.language,
      },
      authenticate
    );
    TranslateTool.phrases[textToTranslate] = translated;

    return translated;
  }

  static TranslateText(textToTranslate, updateFunction) {
    this.Translate(textToTranslate)
      .then((response) => {
        if (response.phrase && response.phrase === textToTranslate) {
          updateFunction(response.translated);
          return;
        }
      })
      .catch(() => {});
  }
}
