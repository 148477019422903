const ButtonTypeEnum = {
  NormalNormal: "Normalnormal",
  NormalIconLeft: "Normaliconleft",
  NormalIconRight: "Normaliconright",
  NormalIconOnly: "Normalicononly",
  SmallNormal: "Smallnormal",
  SmallIconLeft: "Smalliconleft",
  SmallIconRight: "Smalliconright",
  SmallIconOnly: "Smallicononly",
  LargeIconOnly: "Largeicononly",
  LargeNormal: "LargeNormal",
  ExtraLargeNormal: "ExtralargeNormal",
};

export default ButtonTypeEnum;
