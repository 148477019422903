export default class PurchaseOrderLineData {
  constructor(item) {
    if (item) {
      this.LocationId = item.LocationId;
      this.CxClientId = item.CxClientId;
      this.SelectedItems = [];
      return;
    }
    this.LocationId = 0;
    this.CxClientId = 0;
    this.SelectedItems = [];
  }
}
