import React from "react";
import InputTypesEnum from "../../Enums/InputTypesEnum";
import InputWithClearBase from "./InputWithClearBase";
import PlaceholderField from "./Base/PlaceholderField";
import TextField from "./Base/TextField";
import EmptyYesNoSelect from "./Select/EmptyYesNoSelect";
import CountrySelect from "./Select/CountrySelect";
import CountryMultiChoice from "./MultiChoice/CountryMultiChoice";
import OfficeMultiChoice from "./MultiChoice/OfficeMultiChoice";
import SelectFieldCheckbox from "./Select/SelectFIeldCheckbox";

export default class InputWithClear extends React.Component {
  static defaultProps = {
    className: "w-100 form-control form-control-sm",
    changeOfEditedMultipItem: () => {},
    disableClearButton: false,
    disabled: false,
    displayHyperlink: false,
    editedItem: null,
    errorMessage: "",
    fieldName: "defaultFieldname",
    idFilter: -1,
    info: "",
    isInOneRow: false,
    labelIsDisabled: false,
    labelText: "Default Label Text",
    margin: "m-1",
    onChange: () => {},
    placeholder: "",
    renderedInputField: null,
    selectValues: [],
    tooltipText: null,
    type: InputTypesEnum.Text,
    validityChecker: null,
    value: null,
    textBeforeInput: null, //for TextField
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  renderTextField(fieldType, readOnly) {
    return (
      <TextField
        className={this.props.className}
        fieldName={this.props.fieldName}
        onChange={this.props.onChange}
        placeholder={this.props.placeholder}
        value={this.props.value}
        editedItem={this.props.editedItem}
        fieldType={fieldType}
        readonly={readOnly}
        textBeforeInput={this.props.textBeforeInput}
      />
    );
  }

  renderEmptySpace() {
    return <div> </div>;
  }

  renderPlaceholderField() {
    return (
      <PlaceholderField
        className={this.props.className}
        fieldName={this.props.fieldName}
      />
    );
  }

  renderEmptyYesNoSelect() {
    return (
      <EmptyYesNoSelect
        value={this.props.value}
        className={this.props.className}
        fieldName={this.props.fieldName}
        onChange={this.props.onChange}
      />
    );
  }

  renderCountrySelect() {
    return (
      <CountrySelect
        value={this.props.value}
        className={this.props.className}
        fieldName={this.props.fieldName}
        onChange={this.props.onChange}
        filterCleared={this.props.filterCleared}
      />
    );
  }

  renderCountryMultiChoice() {
    return (
      <CountryMultiChoice
        selected={this.props.value}
        fieldName={this.props.fieldName}
        onChange={this.props.onChange}
      />
    );
  }

  renderOfficeMultiChoice() {
    return (
      <OfficeMultiChoice
        selected={this.props.value}
        fieldName={this.props.fieldName}
        onChange={this.props.onChange}
      />
    );
  }

  renderSelectField() {
    return (
      <SelectFieldCheckbox
        value={this.props.value}
        className={this.props.className}
        showSelectFilterAt={this.props.showSelectFilterAt}
        filterCleared={this.props.filterCleared}
        selectValues={this.props.selectValues}
        IsCheckBoxEnabled={this.props.IsCheckBoxEnabled}
        onOffValue={this.props.onOffValue}
        editedItem={this.props.editedItem}
        changeOfEditedMultipItem={this.props.changeOfEditedMultipItem}
        fieldName={this.props.fieldName}
        onChange={this.props.onChange}
      />
    );
  }

  renderInputField() {
    switch (this.props.type) {
      case InputTypesEnum.Number:
        return this.renderTextField(
          [null, "", "-", "+"].includes(this.props.value) ? "text" : "number",
          false
        );
      case InputTypesEnum.TextReadOnly:
        return this.renderTextField("text", true);
      case InputTypesEnum.Placeholder:
        return this.renderPlaceholderField();
      case InputTypesEnum.EmptyYesNoSelect:
        return this.renderEmptyYesNoSelect();
      case InputTypesEnum.CountrySelect:
        return this.renderCountrySelect();
      case InputTypesEnum.CountryMultiChoice:
        return this.renderCountryMultiChoice();
      case InputTypesEnum.OfficeMultiChoice:
        return this.renderOfficeMultiChoice();
      case InputTypesEnum.Select:
        return this.renderSelectField();
      default:
        return this.renderTextField("text", false);
    }
  }

  render() {
    const labelText =
      typeof this.props.labelText === "function"
        ? this.props.labelText(this.props.editedItem)
        : this.props.labelText;

    const disabled =
      typeof this.props.disabled === "function"
        ? this.props.disabled(this.props.editedItem)
        : this.props.disabled;
    const errorMessage =
      this.props.validityChecker !== null
        ? this.props.validityChecker(this.props.value, this.props.editedItem)
        : this.props.errorMessage;

    return (
      <InputWithClearBase
        disableClearButton={this.props.disableClearButton}
        disabled={disabled}
        displayHyperlink={this.props.displayHyperlink}
        errorMessage={errorMessage}
        fieldName={this.props.fieldName}
        info={this.props.info}
        isInOneRow={this.props.isInOneRow}
        labelIsDisabled={this.props.labelIsDisabled}
        labelText={labelText}
        margin={this.props.margin}
        onChange={this.props.onChange}
        renderedInputField={this.renderInputField()}
        tooltipText={this.props.tooltipText}
        type={this.props.type}
        value={this.props.value}
      />
    );
  }
}
