import React from "react";
import WizzardTypeEnum from "../../Enums/WizzardTypeEnum";
import MessageComponent from "../MessageComponent";
import SelectionWizzardPage from "./SelectionWizzardPage";

export default class GeneralEditWizzardBase extends React.Component {
  static defaultProps = {
    clickOnSave: () => {},
    clickOnCancel: () => {},
    crudType: WizzardTypeEnum.Create,
    editedItem: null,
    errorCode: "GEWBF12",
    onChangeEditedItem: () => {},
    approveBeforeSaveCheck: null,
    approveBeforeSaveMessage: null,
    followUpMessage: null,
    windowDescription: {
      navigateToOnCreation: null, // A base url to navigate to after creation. The id of the created item will be added to the end of the url.
      pagesInGeneralEditWizzard: [],
      serviceFunction: editedItem => {}, //The function that will be called when the user clicks on 'Finish', it is in most cases a backend call.
      titleForCreate: "Add New Item",
      titleForEdit: "Edit Item",
      messageOnSuccess: "Item has been saved.",
      showErrorList: false,
      itemType: null,
      wizzardPageType: SelectionWizzardPage,
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      editedItem: props.editedItem
        ? props.editedItem
        : new props.windowDescription.itemType(),
    };

    this.clickOnSaveInWizzardPage = this.clickOnSaveInWizzardPage.bind(this);
    this.serviceBaseFunction = this.serviceBaseFunction.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.editedItem !== this.props.editedItem) {
      this.setState({
        editedItem: this.props.editedItem,
        step: 0,
      });
    }
  }

  clickOnSaveInWizzardPage(newStep, edited) {
    if (
      newStep === this.props.windowDescription.pagesInGeneralEditWizzard.length
    ) {
      if (
        this.props.approveBeforeSaveCheck &&
        this.props.approveBeforeSaveCheck(edited)
      ) {
        MessageComponent.SetApprove(
          this.props.approveBeforeSaveMessage ??
            "Are you sure you want to save?",
          () => this.serviceBaseFunction(edited)
        );
        return;
      }
      this.serviceBaseFunction(edited);
      return;
    }
    this.setState({
      step: newStep,
      editedItem: edited,
    });
  }

  serviceBaseFunction(edited) {
    MessageComponent.SetLoading(true);
    this.props.windowDescription
      .serviceFunction({ ...edited })
      .then(response => {
        MessageComponent.SetLoading(false);
        if (this.props.windowDescription.showErrorList) {
          if (response.errorList) {
            const errorJsx = (
              <div>
                {response.errorList.map(error => {
                  return (
                    <>
                      {error}
                      <br />
                    </>
                  );
                })}
              </div>
            );
            this.props.clickOnCancel();

            MessageComponent.SetWarning(errorJsx, "Error");
            return;
          } else if (response.error) {
            this.props.clickOnCancel();
            MessageComponent.SetWarning(response.error, "Error");

            return;
          }
        }

        if (response.error) {
          this.setState({
            step: 0,
            editedItem: edited,
          });
          MessageComponent.SetErrorMessage(response.error);
          return;
        }

        if (
          response &&
          response.navigationId &&
          this.props.windowDescription.navigateToOnCreation
        ) {
          if (this.props.clickOnSave) this.props.clickOnSave();
          const tab = window.open(
            this.props.windowDescription.navigateToOnCreation +
              response.navigationId,
            "_blank"
          );
          if (tab) tab.focus();
          return;
        }

        if (this.props.followUpMessage) {
          MessageComponent.SetApprove(
            this.props.followUpMessage,
            () => this.props.clickOnSave(response.resultItem),
            "WOULD YOU LIKE TO PROCEED?",
            "NO",
            "YES",
            true,
            this.props.clickOnCancel
          );
          return;
        }

        MessageComponent.SetSuccess(
          this.props.windowDescription.messageOnSuccess
            ? this.props.windowDescription.messageOnSuccess
            : "Item has been saved.",
          () => {
            this.props.clickOnSave();
          }
        );
      })
      .catch(() => {
        MessageComponent.SetLoading(false);
        this.setState({
          step: 0,
          editedItem: edited,
        });
        MessageComponent.SetErrorMessage(
          `Error! ErrorCode: GEWBF55 - ${this.props.errorCode}`
        );
      });
  }

  handleOnChangeEditedItem() {
    return this.props.onChangeEditedItem;
  }

  render() {
    return (
      <this.props.windowDescription.wizzardPageType
        step={this.state.step}
        crudType={this.props.crudType}
        windowDescription={this.props.windowDescription}
        clickOnCancel={this.props.clickOnCancel}
        clickOnSave={this.clickOnSaveInWizzardPage}
        editedItem={this.state.editedItem}
        onChangeEditedItem={this.handleOnChangeEditedItem()}
      />
    );
  }
}
