import React from "react";
import TranslateTool from "../Helpers/TranslateTool";
import MessageComponent from "./MessageComponent";
import CookieHelper from "../Helpers/cookieHelper";
import { Tooltip } from "@mui/material";

export default class TranslationComponent extends React.Component {
  static defaultProps = {
    phrase: "Good Morning!",
    authenticate: true,
    loadingEnabled: true,
  };

  language = 0;
  constructor(props) {
    super(props);
    this.language = CookieHelper.getCookie("language", "1");
    this.state = {
      loading: true,
      translated: "",
    };
  }

  componentDidMount() {
    this.populateData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.phrase !== this.props.phrase) {
      this.populateData();
    }
  }

  populateData() {
    if (typeof this.props.phrase !== "string") {
      this.setState({
        loading: false,
        translated: "Phrase text is not a string",
      });
      return;
    }
    TranslateTool.Translate(this.props.phrase, this.props.authenticate)
      .then(response => {
        if (response.phrase && response.phrase === this.props.phrase) {
          this.setState({
            loading: false,
            translated: response.translated,
          });
          return;
        }
        if (
          response.error &&
          response.error.startsWith(
            "Something went wrong during the Phrase_GetTranslation process."
          )
        ) {
          this.setState({ loading: false, translated: this.props.phrase });
        }
      })
      .catch(error => {
        MessageComponent.SetErrorMessage("Couldn't get translation.", error);
        this.setState({
          loading: false,
          translated: this.props.phrase,
        });
      });
  }

  render() {
    if (this.language === "1" || this.props.phrase === this.state.translated) {
      return this.props.phrase;
    }
    if (!this.props.loadingEnabled) {
      return this.state.translated;
    }
    if (this.state.loading) {
      return (
        <>
          {`${this.props.phrase} `}
          <i className="fa fa-spinner fa-spin" />
        </>
      );
    }
    return (
      <Tooltip title={this.props.phrase} placement="left-start" arrow={true}>
        {this.state.translated}
      </Tooltip>
    );
  }
}
