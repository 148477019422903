import React from "react";
import "../../css/NavBar.css";
import menuitems from "../../menuitems";
import Navbutton from "./NavButton";
import AuthService from "../../Services/AuthService";
import { MainRefContext } from "../../MainRefContext";

export default class NavBar extends React.Component {
  static defaultProps = {
    showNavBar: false,
    hideNavBar: () => {},
  };

  constructor(props) {
    super(props);
    const loggedInManager = AuthService.getInstance().GetLoggedInManager();
    this.state = {
      loggedInManager,
      lastRefState: null,
    };
  }

  static contextType = MainRefContext;

  componentDidUpdate(prevProps) {
    //This is to update the component if menuId changes in MainPage, as that component re-renders later than this one
    //We have to force the nav to rerender after 'current' is updated, to show where the user currently is
    if (
      this.state.lastRefState?.menuId !== this.context.current?.state?.menuId
    ) {
      this.setState({ lastRefState: this.context.current?.state });
    }
  }

  renderNavBarItems() {
    const buttons = [];
    for (let i = 0; i < menuitems.length; i++) {
      if (
        menuitems[i].hide === true ||
        (typeof menuitems[i].hide === "function" &&
          menuitems[i].hide(this.state.loggedInManager))
      ) {
        continue;
      }

      const isActive =
        (i === 0 && window.location.pathname === "/") ||
        (!this.context.current &&
          menuitems[i].url.toLocaleLowerCase() ===
            window.location.pathname.split("/")[1].toLocaleLowerCase()) ||
        i === this.context.current?.state?.menuId;

      buttons.push(
        <Navbutton
          key={`navbutton${i}`}
          link={menuitems[i].link}
          text={menuitems[i].text}
          icon={menuitems[i].icon}
          url={menuitems[i].url}
          active={isActive}
          hideNavBar={this.props.hideNavBar}
          id={i}
        />
      );
    }

    return buttons;
  }

  render() {
    return (
      <div
        className={`App-navbar${
          this.props.showNavBar ? " App-navbar-show" : ""
        }`}
      >
        {this.renderNavBarItems()}
      </div>
    );
  }
}
