import React from "react";
import "../../css/OuterPage.css";
import AuthService from "../../Services/AuthService";
import LoadingFullScreen from "../../Elements/Loading/LoadingFullScreen";
import ForgottenPasswordPage from "./ForgottenPasswordPage";
import OuterPageCard from "../../Elements/OuterPageCard";
import TranslationComponent from "../../Elements/TranslationComponent";
import CookieHelper from "../../Helpers/cookieHelper";

export default class LoginPage extends React.Component {
  static defaultProps = {
    handleLogin: () => {},
  };

  constructor(props) {
    super(props);
    const credentials = { email: "", password: "" };

    this.state = {
      credentials,
      passwordShown: false,
      loading: false,
      errorMessage: "",
      forgottenPassword: false,
    };
  }

  async handleLogin() {
    try {
      await AuthService.getInstance().LoginWithCredentials({
        ...this.state.credentials,
        language: CookieHelper.getCookie("language", "1"),
      });
      this.props.handleLogin();
    } catch (e) {
      this.setState({ loading: false });
      this.setState({ errorMessage: "Email or password is incorrect." });
    }
  }

  changeOfValue(field, e) {
    const credentials = this.state.credentials;
    credentials[field] = e.target.value;
    this.setState({ credentials });
  }

  //#region Render Functions
  renderLoginCard() {
    return (
      <OuterPageCard>
        {this.renderInputs()}
        {this.renderButton()}
        {this.state.errorMessage ? (
          <p className="text-danger pt-3 m-0">{this.state.errorMessage}</p>
        ) : null}
      </OuterPageCard>
    );
  }

  renderInputs() {
    return (
      <>
        <div className="input-container">
          <input
            placeholder="Email"
            value={this.state.credentials.email}
            type="text"
            onChange={(e) => this.changeOfValue("email", e)}
          />
        </div>
        <div className="input-container">
          <input
            placeholder="Password"
            value={this.state.credentials.password}
            type={this.state.passwordShown ? "text" : "password"}
            onChange={(e) => this.changeOfValue("password", e)}
          />
          <i
            className="fas fa-eye"
            id="eye"
            onMouseDown={() => this.setState({ passwordShown: true })}
            onMouseUp={() => this.setState({ passwordShown: false })}
          />
        </div>
      </>
    );
  }

  renderButton() {
    return (
      <>
        <button
          className="outer-button"
          type="button"
          onClick={() => {
            this.setState({ loading: true });
            this.handleLogin();
          }}
        >
          <TranslationComponent phrase="Login" authenticate={false} />
        </button>
        <span
          className="forgotten-pass-link"
          onClick={() => {
            this.setState({ forgottenPassword: true });
          }}
        >
          <TranslationComponent
            phrase="Reset My Password"
            authenticate={false}
          />
        </span>
      </>
    );
  }
  //#endregion

  render() {
    if (this.state.forgottenPassword) {
      return (
        <ForgottenPasswordPage
          email={this.state.credentials.email}
          handleSubmit={() => this.setState({ forgottenPassword: false })}
        />
      );
    }
    return this.state.loading ? <LoadingFullScreen /> : this.renderLoginCard();
  }
}
