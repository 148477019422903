export default class UIError
{
    constructor(error)
    {
        if(!error)
        {
            this.Message = "";
            this.Name = "";
            this.Stack = "";
        }
        else
        {
            this.Message = error.message;
            this.Name = error.name;
            this.Stack = error.stack;
        }
    }
}