import React from "react";
import ClientLinkboxService from "../../Services/ClientLinkboxService";
import ClientLinkboxPanelComponent from "./ClientLinkboxPanelComponent";
import MessageComponent from "../MessageComponent";

export default class ClientLinkboxWrapperComponent extends React.Component {
  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = { loading: true, clientLinkboxList: [] };
  }

  componentDidMount() {
    ClientLinkboxService.GetClientLinkboxes()
      .then((response) => {
        this.setState({ clientLinkboxList: response, loading: false });
      })
      .catch(() => {
        MessageComponent.SetErrorMessage("Couldn't populate linkbox data.");
      });
  }

  render() {
    return (
      <div className="border rounded">
        {this.state.loading ? (
          "Loading..."
        ) : (
          <div>
            <ClientLinkboxPanelComponent
              clientLinkboxList={this.state.clientLinkboxList}
            />
          </div>
        )}
      </div>
    );
  }
}
