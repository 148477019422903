import React from "react";
import FigmaButton from "./Button/FigmaButton";
import ButtonTypeEnum from "../Enums/ButtonTypeEnum";
import ButtonColorsEnum from "../Enums/ButtonColorsEnum";
import IconPositionEnum from "../Enums/IconPositionEnum";

class OverWindow extends React.Component {
  static defaultProps = {
    clickOnCancel: () => {
      alert("Define prop: clickOnCancel");
    },
    title: "Default Title",
    children: <div className="border bg-warning">This is the content.</div>,
    closeOnClickOutside: true,
    iconName: "fas fa-window-maximize",
    verticalScroll: false,
    maxWidth: 0,
    minHeight: 0,
    className: "top-of-boxFigma verticalScroll",
  };

  constructor(props) {
    super(props);
    this.windowref = React.createRef();

    this.escFunction = this.escFunction.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    if (this.props.closeOnClickOutside) {
      document.addEventListener("mousedown", this.handleClickOutside, false);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
    if (this.props.closeOnClickOutside) {
      document.removeEventListener("mousedown", this.handleClickOutside, false);
    }
  }

  escFunction(event) {
    if (event.keyCode === 27 && this.props.clickOnCancel) {
      this.props.clickOnCancel();
    }
  }

  handleClickOutside(event) {
    if (this.props.closeOnClickOutside) {
      const node = this.windowref.current;

      if (event.target.contains(node) && event.target !== node) {
        event.preventDefault();
        if (this.props.clickOnCancel) {
          this.props.clickOnCancel();
        }
      }
    }
  }

  render() {
    const style =
      this.props.maxWidth === 0
        ? {}
        : {
            maxWidth: `${this.props.maxWidth}px`,
            margin: "auto",
          };

    if (this.props.minHeight !== 0)
      style.minHeight = `${this.props.minHeight}px`;

    return (
      <div className="disable-outside-clicks">
        <div
          className={this.props.className}
          ref={this.windowref}
          style={style}
        >
          <div className="OverWindowWrapper ">
            <h6 className="OverWindowTitle">
              <p>{this.props.title}</p>
            </h6>
            <div className="OverWindowHeaderbutton">
              {this.props.clickOnCancel && (
                <FigmaButton
                  type={ButtonTypeEnum.NormalIconOnly}
                  iconPosition={IconPositionEnum.Center}
                  color={ButtonColorsEnum.Common}
                  iconName="fa-solid fa-x"
                  onClick={() => {
                    if (this.props.clickOnCancel) {
                      this.props.clickOnCancel();
                    }
                  }}
                />
              )}
            </div>
          </div>
          <div className={this.props.verticalScroll ? " verticalScroll" : ""}>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default OverWindow;
