import React from "react";
import TabbedPage from "../Elements/TabbedPage";
import PurchaseOrdersAdmin from "./PurchaseOrdersAdmin";
import PurchaseOrderLinesAdmin from "./PurchaseOrderLinesAdmin";

export default class PurchaseOrderParentPage extends React.Component {
  static defaultProps = {};

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <TabbedPage
        tabs={[
          {
            icon: "fas fa-cash-register",
            label: "Purchase Orders",
            component: <PurchaseOrdersAdmin />,
          },
          {
            icon: "fas fa-file-lines",
            label: "Open Purchase Order Lines",
            component: <PurchaseOrderLinesAdmin />,
          },
        ]}
      />
    );
  }
}
