import React from "react";
import MessageComponent from "../../MessageComponent";

export default class SelectWithFilter extends React.Component {
  static defaultProps = {
    showSelectFilterAt: 10,
    selectValues: [],
    filterCleared: false,
    className: "w-100 form-control form-control-sm",
    onChange: () => {},
    fieldName: "defaultFieldname",
    value: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      filter: "",
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    // M: This is used to clear the filter field too on clear all button press.
    // The filterCleared value from prop originates from InputWithClear's prop,
    // which comes from GeneralSearch's filterCleared state.
    if (this.props.filterCleared && this.state.filter !== "") {
      this.setState({ filter: "" });
    }
  }

  renderFilterField() {
    if (this.props.selectValues.length > this.props.showSelectFilterAt) {
      return (
        <input
          type="text"
          className={`${this.props.className} `}
          placeholder="Type to search..."
          value={this.state.filter}
          onChange={(e) => {
            const filter = e.target.value;
            const first = this.props.selectValues.find((value) =>
              value.label.toLowerCase().includes(filter.toLowerCase())
            );
            const value = first !== undefined ? first.value : "";
            this.setState({
              filter: e.target.value,
              value,
            });
            if (value) this.props.onChange({ value });
          }}
          id={`htmlidfor${this.props.fieldName}`}
          key={`keyfilter${this.props.fieldName}`}
          autoComplete="off"
        />
      );
    }
    return null;
  }

  renderOption(label, value, disabled, index) {
    return (
      <option value={value} key={`key${index}`} disabled={disabled}>
        {label}
      </option>
    );
  }

  render() {
    let options = [];
    try {
      options = this.props.selectValues
        .filter((v) =>
          v.label.toLowerCase().includes(this.state.filter.toLowerCase())
        )
        .map((v, index) =>
          this.renderOption(v.label, v.value, v.disabled, index)
        );
    } catch {
      MessageComponent.SetErrorMessage("Couldn't display select field.");
    }

    return (
      <div className="d-flex w-100">
        {this.renderFilterField()}
        <select
          className={this.props.className}
          children={options}
          onChange={(e) => {
            this.setState({
              value: e.value,
            });
            this.props.onChange(e.target);
          }}
          value={this.props.value === null ? "" : this.props.value}
          key={`keyselect${this.props.fieldName}`}
        />
      </div>
    );
  }
}
