import React from "react";
import InputWithClear from "../Input/InputWithClear";
import InputTypesEnum from "../../Enums/InputTypesEnum";
import FieldTypesEnum from "../../Enums/FieldTypesEnum";
import moneyHelper from "../../Helpers/moneyHelper";
import JsxHelper from "../../Helpers/JsxHelper";
import dateTimeHelper from "../../Helpers/dateTimeHelper";

export default class DataTableBody extends React.Component {
  static defaultProps = {
    listItem: {},
    Lineindex: 0,
    inputFieldOnChange: () => {},
    type: {
      FieldsToShowInList: [],
      BoolFields: [],
      DateFields: [],
      MoneyFields: [],
      Currency: () => {},
    },
    alternateShowInList: [],
  };

  renderTableCell(listItem, field, key) {
    const content = this.generateContent(listItem, field);

    return (
      <td key={key + this.props.Lineindex} className={"defaultTdStyle"}>
        <div
          className={`Tablehiddenoverflow ${
            field.className ? field.className : ""
          }`}
        >
          <div className="DataTableBodyText">{content}</div>
        </div>
      </td>
    );
  }

  generateContentCell(data, field, item) {
    if (
      (!data || data === field.label || data.toString().trim() === "") &&
      (field.data in item || typeof field.data === "function")
    ) {
      return "";
    }
    return <div className="DataTableBodyText">{data}</div>;
  }

  renderInputField(field) {
    return (
      <InputWithClear
        fieldName={field.data}
        value={this.props.listItem[field.data]}
        onChange={(fieldName, newValue) =>
          this.props.inputFieldOnChange(fieldName, newValue)
        }
        type={field.inputType}
        validityChecker={field.validityChecker}
        labelIsDisabled={true}
        disableClearButton={true}
        editedItem={this.props.listItem}
        disabled={field.disabled}
      />
    );
  }

  generateContent(item, field) {
    if (
      [InputTypesEnum.Text, InputTypesEnum.Number].includes(field.inputType)
    ) {
      return this.generateContentCell(
        this.renderInputField(field),
        field,
        item
      );
    }

    const data =
      typeof field.data === "function" ? field.data(item) : item[field.data];

    if (!field.type) field.type = FieldTypesEnum.PureDataField;

    if (
      this.props.type.MoneyFields !== undefined &&
      this.props.type.MoneyFields.includes(field.data)
    ) {
      return this.generateContentCell(
        moneyHelper.toMoneyFormat(data, item[this.props.type.Currency()]),
        field,
        item
      );
    }

    if (
      this.props.type.BoolFields !== undefined &&
      this.props.type.BoolFields.includes(field.data)
    ) {
      return this.generateContentCell(
        JsxHelper.YesNoEmpty(item[field.data], true, false),
        field,
        item
      );
    }

    if (
      this.props.type.DateFields !== undefined &&
      this.props.type.DateFields.includes(field.data)
    ) {
      return this.generateContentCell(
        dateTimeHelper.toYYYYsMMsDDWithIndefinite(data),
        field,
        item
      );
    }

    return data;
  }

  render() {
    const showInList =
      this.props.alternateShowInList ?? this.props.type.FieldsToShowInList;
    return (
      <tr key={`tr${this.props.Lineindex}`} className="FigmaDetailTableRow">
        {showInList.map((field, index) =>
          this.renderTableCell(this.props.listItem, field, index)
        )}
      </tr>
    );
  }
}
