import React from "react";
import "../../css/ClientLinkboxComponent.css";

class ClientLinkboxComponent extends React.Component {
  static defaultProps = {
    description: {
      title: "Title of Linkbox",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore",
      url: "Url",
      target: "_self",
      foreColor: "dark",
      backColor: "light",
    },
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="col-sm-12 col-md-6 col-lg-4 text-left">
        <a
          href={this.props.description.url}
          target={this.props.description.target}
          rel="noopener noreferrer"
        >
          <div
            className={`card bg-${this.props.description.backColor} mb-3 pe-auto linkboxCard`}
            style={{ minWidth: "290px", height: "210px" }}
          >
            {this.renderCard()}
          </div>
        </a>
      </div>
    );
  }

  renderCard() {
    return (
      <div className="card-body">
        {this.renderCardTitle()}
        {this.renderCardText()}
      </div>
    );
  }

  renderCardText() {
    return (
      <p
        className={`card-text linkboxCardText text-${this.props.description.foreColor}`}
      >
        {this.props.description.text}
      </p>
    );
  }

  renderCardTitle() {
    return (
      <h5
        className={`card-title linkboxCardTitle text-${this.props.description.foreColor}`}
      >
        {this.props.description.title}{" "}
        {this.props.description.target === "_blank" && (
          <i className="fa-solid fa-link-simple fa-2xs" />
        )}
      </h5>
    );
  }
}

export default ClientLinkboxComponent;
