import React from "react";
import FaIcon from "./FaIcon";
import MessageComponent from "./MessageComponent";
import "../css/LongText.css";

export default class LongText extends React.Component {
  static defaultProps = {
    text: "Sample text",
    maxLength: 50,
    type: "tooltip",
    maxLengthForTooltip: 100,
  };

  render() {
    if (this.props.text == null || this.props.maxLength < 1)
      return "Error in LongText component.";
    if (typeof this.props.text !== "string") return this.props.text;
    if (this.props.text.length <= this.props.maxLength)
      return <span className="m-1">{this.props.text}</span>;
    const type =
      this.props.type === "tooltip" &&
      this.props.length < this.props.maxLengthForTooltip
        ? "tooltip"
        : "info";
    return (
      <span className="m-1">
        {this.props.text.substring(0, this.props.maxLength)}
        ...
        <FaIcon
          className={
            type === "tooltip" ? "text-secondary" : "icon long-text__fa-eye"
          }
          iconName="fa-solid fa-eye long-text__fa-eye"
          onClick={() => MessageComponent.SetInfo(this.props.text)}
          tooltipText={
            type === "tooltip"
              ? this.props.text
              : this.props.maxLengthForTooltip < this.props.text
                ? `${this.props.text.substring(
                    0,
                    this.props.maxLengthForTooltip
                  )}...`
                : this.props.text
          }
        />
      </span>
    );
  }
}
