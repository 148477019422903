import React from "react";
import LanguageDropdown from "./Header/LanguageDropdown";
import MessageComponent from "./MessageComponent";
import ErrorService from "../Services/ErrorService";

export default class OuterPageBase extends React.Component {
  static defaultProps = {
    cardElement: null,
  };

  static getDerivedStateFromError(error) {
    ErrorService.LogUIError(error);
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error(error);
    this.setState({
      hasError: true,
      error: error.message,
      info: info.componentStack,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ hasError: false });
    }
  }

  render() {
    return (
      <div
        className="outerpage-container"
        style={{
          backgroundImage: "url(/img/login_image.png)",
        }}
      >
        <MessageComponent />
        <LanguageDropdown className={"outer-language"} />
        {this.props.cardElement}
      </div>
    );
  }
}
