import React from "react";
import ClientLinkboxWrapperComponent from "../Elements/ClientLinkbox/ClientLinkboxWrapperComponent";

export default class HomePage extends React.Component {
  render() {
    return (
      <div>
        <ClientLinkboxWrapperComponent />
      </div>
    );
  }
}
