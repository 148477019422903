import SimpleWizzardPage from "../../Elements/Wizzards/SimpleWizzardPage";
import InputTypesEnum from "../../Enums/InputTypesEnum";
import ValidityHelper from "../../Helpers/ValidityHelper";
import LocationInvoicingDataService from "../../Services/LocationInvoicingDataService";
import UpdateMultipleLocationInvoicingDataItem from "../Communication/UpdateMultipleLocationInvoicingDataItem";
import LocationSearchParams from "../SearchParams/LocationSearchParams";

export default class SetInvoicingDataForMultipleLocationsWindowDescription {
  static titleForCreate = "Set invoicing data for multiple locations";
  static itemType = UpdateMultipleLocationInvoicingDataItem;
  static serviceFunction =
    LocationInvoicingDataService.SetInvoicingDataForMultipleLocations;
  static searchParamsType = LocationSearchParams;
  static wizzardPageType = SimpleWizzardPage;

  static pagesInGeneralEditWizzard = [
    {
      label: "Contact Information",
      fields: [
        {
          label: "Company Name",
          type: InputTypesEnum.Text,
          data: "CompanyName",
          validityChecker: ValidityHelper.forRequiredTextField,
        },
        {
          label: "First Name",
          type: InputTypesEnum.Text,
          data: "FirstName",
          validityChecker: ValidityHelper.forRequiredTextField,
        },
        {
          label: "Last Name",
          type: InputTypesEnum.Text,
          data: "LastName",
          validityChecker: ValidityHelper.forRequiredTextField,
        },
        {
          label: "VAT Number",
          type: InputTypesEnum.Text,
          data: "VatNumber",
          validityChecker: ValidityHelper.forRequiredTextField,
        },
        {
          label: "Invoicing Email",
          type: InputTypesEnum.Text,
          data: "InvoicingEmail",
          validityChecker: ValidityHelper.forEmailRequired,
        },
        {
          label: "InternalReference",
          type: InputTypesEnum.Text,
          data: "InternalReference",
          validityChecker: ValidityHelper.forRequiredTextField,
        },
        {
          label: "PO Number",
          type: InputTypesEnum.Text,
          data: "Ponumber",
          validityChecker: ValidityHelper.forRequiredTextField,
        },
        {
          label: "PO Contact Name",
          type: InputTypesEnum.Text,
          data: "PocontactName",
          validityChecker: ValidityHelper.forRequiredTextField,
        },
        {
          label: "PO Contact Email",
          type: InputTypesEnum.Text,
          data: "PocontactEmail",
          validityChecker: ValidityHelper.forEmailRequired,
        },
      ],
    },
    {
      label: "Address",
      fields: [
        {
          label: "Street",
          type: InputTypesEnum.Text,
          data: "Street",
          validityChecker: ValidityHelper.forRequiredTextField,
        },
        {
          label: "City",
          type: InputTypesEnum.Text,
          data: "City",
          validityChecker: ValidityHelper.forRequiredTextField,
        },
        {
          label: "Zip Code",
          type: InputTypesEnum.Text,
          data: "ZipCode",
          validityChecker: ValidityHelper.forRequiredTextField,
        },
        {
          label: "State",
          type: InputTypesEnum.Text,
          data: "State",
        },
        {
          label: "Country",
          type: InputTypesEnum.CountrySelect,
          data: "CountryId",
          validityChecker: ValidityHelper.forRequiredTextField,
        },
      ],
    },
  ];
}
